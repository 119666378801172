module.exports = `fragment quick_stats on Contentstack_quick_stats {
    quick_stats_heading
    quick_stats_stats_group {
      quick_stats_icon {
        url
        title
      }
      quick_stats_description
      quick_stats_number
    }
  }`;