module.exports = `fragment store_address on Contentstack_store_address {
    city
    google_map {
      href
      title
    }
    google_places_id
    latitude
    longitude
    state
    street_address_1
    street_address_2
    timezone
    zip_code
  }`;