// the iframe's origin
const JANE_ORIGIN = (module.exports.JANE_ORIGIN = "https://www.iheartjane.com");
const EMBEDDED_SRC = (module.exports.EMBEDDED_SRC = "https://api.iheartjane.com/v1/headless/embed.js");

const JANE_EMBED_ID = (module.exports.JANE_EMBED_ID = "jane-menu");
const JANE_CONTAINER_ID = (module.exports.JANE_CONTAINER_ID = "menu-div");

const JANE_RESIZED_MESSAGE_DELAY = (module.exports.JANE_RESIZED_MESSAGE_DELAY = 1000);

// events from iframe postMessage
const LOADING_EVENT = (module.exports.LOADING_EVENT = "loadingEvent");
const ANALYTICS_EVENT = (module.exports.ANALYTICS_EVENT = "analyticsEvent");
const GET_JANE_DEVICE_ID = (module.exports.GET_JANE_DEVICE_ID =
  "getJaneDeviceId");
const RESPOND_GET_JANE_DEVICE_ID = (module.exports.RESPOND_GET_JANE_DEVICE_ID =
  "respondGetJaneDeviceId");
const SET_MARGIN_TO_IFRAME_TOP = (module.exports.SET_MARGIN_TO_IFRAME_TOP =
  "setMarginToIframeTop");
const MOBILE_MODAL = (module.exports.MOBILE_MODAL = "openMobileModal");
const CLOSE_MOBILE_MODAL = (module.exports.CLOSE_MOBILE_MODAL =
  "closeMobileModal");
const OPEN_DESKTOP_FILTERS = (module.exports.OPEN_DESKTOP_FILTERS =
  "openDesktopFilters");
const SHRINK_NEXT_RESIZE = (module.exports.SHRINK_NEXT_RESIZE =
  "shrinkNextResize");
const GET_IS_DEEP_LINK = (module.exports.GET_IS_DEEP_LINK = "getIsDeepLink");
