module.exports = {
  query: `contentstackSiteConfig {
      title
      site_title
      social_media {
        ...social_media
      }
      loyalty {
        ios_link {
          href
          title
        }
        android_link {
          href
          title
        }
      }
      modular_blocks {
        site_config_interstitial {
          ...interstitial
        }
        site_config_markup {
          ...markup
        }
        site_config_hero {
          ...hero_banner
        }
        site_config_site_element {
          ...site_element
        }
        product_panel {
          ...product_panel
        }
      }
      seo {
        meta_title
        meta_robots
        meta_description
      }
      features {
        jane {
          configuration {
            theme_color
          }
          discounts {
            enable_discounts_modal_on_product_tile_link
          }
        }
        maintenance_mode
        authentication {
          status
        }
        dynamic_pages {
          page {
            url
          }
        }
      }
    }`,
  fragments: [
    "social_media",
    "interstitial",
    "markup",
    "hero_banner",
    "site_element",
    "product_panel",
  ],
};
