module.exports = {
  query: `allContentstackPage {
    edges {
      node {
        url
        title
        modular_blocks {
          Page_Cards {
            ...cards
          }
          page_quote {
            ...quote
          }
          page_hero_banner {
            ...hero_banner
          }
          page_full_width_media {
            ...full_width_media
          }
          page_logo_wall {
            ...logo_wall
          }
          Page_Text {
            ...text
          }
          Page_Icon_Links {
            ...icon_links
          }
          Page_Statement {
            ...statement
          }
          Page_Testimonial {
            ...testimonial
          }
          Page_Split_Screen_Standard {
            ...split_screen_standard
          }
          Page_Split_Screen_Links {
            ...split_screen_links
          }
          Page_Split_Screen_Icons {
            ...split_screen_icons
          }
          Page_Split_Screen_Contained {
            ...split_screen_contained
          }
          Page_Split_Screen_Compact {
            ...split_screen_compact
          }
          Page_News_Banner {
            ...news_banner
          }
          page_markup {
            ...markup
          }
          page_locations_map {
            ...locations_map
          }
          page_all_news {
            ...all_news
          }
          page_hubspot_form {
            ...hubspot_form
          }
          page_iframe {
            ...inline_iframe
          }
          page_site_element {
            ...site_element
          }
          page_campaign_banner {
            ...campaign_banner
          }
        }
        seo {
          ...seo
        }
        sub_site_variant {
          ...sub_site_variant
        }
        sub_site_exclude {
          ...sub_site_exclude
        }
      }
    }
  }`,
  fragments: [
    "cards",
    "quote",
    "hero_banner",
    "full_width_media",
    "logo_wall",
    "text",
    "icon_links",
    "statement",
    "testimonial",
    "split_screen_standard",
    "split_screen_links",
    "split_screen_icons",
    "split_screen_contained",
    "split_screen_compact",
    "news_banner",
    "markup",
    "locations_map",
    "all_news",
    "hubspot_form",
    "inline_iframe",
    "site_element",
    "seo",
    "sub_site_variant",
    "sub_site_exclude",
  ],
};
