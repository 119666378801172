import clsx from "clsx";
import React, { useEffect, useState, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { navigate, Link } from "gatsby";
import logoIcon from "./images/tgs-logo.png";
// import CartIcon from "./images/svg/cart.svg";
// import AccountIcon from "./images/svg/account.svg";
import LeftArrow from "../MenuBar/assets/LeftArrow";
import FacebookIcon from "@col-care/common/elements/icons/FacebookIcon";
import InstagramIcon from "@col-care/common/elements/icons/InstagramIcon";
import TwitterIcon from "@col-care/common/elements/icons/TwitterIcon";
import LinkedinIcon from "@col-care/common/elements/icons/LinkedinIcon";

import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";

import sidebarNav from "./sidebar-navigation.json";
import * as styles from "./Sidebar.module.css";
import "./transitions.css";
// import { GhubLink } from "@col-care/common/common";

function is_external(url) {
  return url.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) != null;
}

const Cross = ({ onToggle }) => {
  return (
    <div
      className={styles.close_me}
      onClick={() => {
        onToggle();
      }}
    >
      <div className={styles.crossedLine}></div>
      <div className={styles.crossedLine}></div>
    </div>
  );
};

const Item = ({ onMove, storeData, close, posthog, ...item }) => {
  const { value, items, isSubNavItem } = item;

  const handleItemClick = () => {
    if (!isSubNavItem) {
      // Logic for non-submenu items
      if (!items?.length) {
        close();
        if (!is_external(`${item.href}`)) {
          let thisHref;
          if (item.href === "/shop") {
            thisHref = storeData ? `${item.href}/${storeData}` : `/locations`;
          } else {
            thisHref = `${item.href}`;
          }
          capturePosthogLink({
            eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
            title: item?.value,
            to: thisHref,
            posthog,
          });

          navigate(`${thisHref}`);
        } else {
          window.open(`${item.href}`);
          capturePosthogLink({
            eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
            title: item?.value,
            to: item.href,
            posthog,
          });
        }
      }
    } else {
      // Logic for submenu items
      if (storeData) {
        const navLink = `/shop/${storeData}?category%5B0%5D=${item.href}`;
        navigate(navLink);
        capturePosthogLink({
          eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
          title: item?.value,
          to: navLink,
          posthog,
        });
        close();
      } else {
        navigate("/locations");
        capturePosthogLink({
          eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
          title: item?.value,
          to: "/locations",
          posthog,
        });
        close();
      }
    }
  };

  return (
    <div
      className={
        isSubNavItem ? styles.subNavItemWrapper : styles.navItemWrapper
      }
    >
      <button onClick={handleItemClick}>
        <div
          className={styles.navItem}
          onClick={() => {
            if (items?.length) onMove(item);
          }}
        >
          <div>{value}</div>
        </div>
      </button>
    </div>
  );
};

function useStack(initialValue = null) {
  const [stack, setStack] = React.useState([initialValue]);
  const top = stack.slice(-1)[0];

  function push(newItem) {
    setStack((prev) => [...prev, newItem]);
  }

  function pop() {
    if (stack.length > 1) setStack((prev) => prev.slice(0, -1));
  }

  function reset() {
    setStack([initialValue]);
  }

  return { stack, push, pop, top, reset };
}

function useSubmenu(items) {
  const {
    push: pushItems,
    pop: popItems,
    top: currentItems,
    reset: resetItems,
  } = useStack(items);
  const {
    push: pushParent,
    pop: popParent,
    top: currentParent,
    reset: resetParent,
  } = useStack(null);

  function moveToNext(sourceItem) {
    pushItems(sourceItem.items);
    pushParent(sourceItem);
  }

  function moveBack() {
    popItems();
    popParent();
  }

  function reset() {
    resetItems();
    resetParent();
  }

  const isCurrentlyInSubmenu = currentParent !== null;

  return {
    moveToNext,
    isCurrentlyInSubmenu,
    currentItems,
    currentParent,
    moveBack,
    reset,
  };
}

const Menu = (props) => {
  const {
    moveToNext,
    isCurrentlyInSubmenu,
    currentItems,
    currentParent,
    moveBack,
    close,
    storeData,
    posthog,
  } = props;

  return (
    <TransitionGroup>
      <CSSTransition
        classNames={isCurrentlyInSubmenu ? "submenu" : "menu"}
        timeout={400}
        key={currentParent?.value}
      >
        <div
          className={clsx(
            styles.navItems,
            isCurrentlyInSubmenu && styles.subnavItems
          )}
        >
          {isCurrentlyInSubmenu && (
            <div
              onClick={() => {
                moveBack();
              }}
              className={styles.backButton}
            >
              <LeftArrow />
              {currentParent?.value}
            </div>
          )}
          {currentItems?.length &&
            currentItems.map((item, index) => {
              return (
                <Item
                  key={index}
                  {...item}
                  onMove={(item) => {
                    moveToNext(item);
                  }}
                  close={close}
                  isSubNavItem={!!currentParent}
                  storeData={props.storeData}
                  posthog={posthog}
                />
              );
            })}
          {!isCurrentlyInSubmenu && (
            <div className={styles.sidebar_footer}>
              <ul className={styles.sidebar_li}>
                {sidebarNav?.sidebarMenu?.map((item) => {
                  return (
                    <li key={item.title}>
                      {item.external ? (
                        <a
                          href={item.link}
                          target={item.external ? "_blank" : "_self"}
                          onClick={() => {
                            close();
                            capturePosthogLink({
                              eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
                              title: item.title,
                              to: item.link,
                              posthog,
                            });
                          }}
                        >
                          {item.title}
                        </a>
                      ) : (
                        <Link
                          to={item.link}
                          onClick={() => {
                            close();
                            capturePosthogLink({
                              eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
                              title: item.title,
                              to: item.link,
                              posthog,
                            });
                          }}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>

              <div className={styles.icon_container}>
                {sidebarNav?.socialNav?.map((item) => (
                  <a
                    key={item.title}
                    href={item.link}
                    target={item.external ? "_blank" : "_self"}
                    rel="noreferrer"
                    aria-label={item.title}
                    onClick={() =>
                      capturePosthogLink({
                        eventName: posthogEvents.HEADER_MOBILE_NAVIGATION,
                        title: "Social navigation" + item.title,
                        to: item.link,
                        posthog,
                      })
                    }
                  >
                    {item.title === "Instagram" && (
                      <InstagramIcon
                        color={item.iconColor}
                        className={styles.social_icon}
                      />
                    )}
                    {item.title === "Facebook" && (
                      <FacebookIcon
                        color={item.iconColor}
                        className={styles.social_icon}
                      />
                    )}
                    {item.title === "Twitter" && (
                      <TwitterIcon
                        color={item.iconColor}
                        className={styles.social_icon}
                      />
                    )}
                    {item.title === "LinkedIn" && (
                      <LinkedinIcon
                        color={item.iconColor}
                        className={styles.social_icon}
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Sidebar = ({ isOpen, onClose, data, storeData, posthog }) => {
  const menu = useSubmenu(data);
  const [open, setOpen] = useState(false);
  const catMenu = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
  };
  const ref = useRef();

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };
  useOutsideClick(ref, () => {
    setOpen(!open);
  });
  function closeMe() {
    setOpen();
  }

  function close() {
    onClose();
    // using a timeout so that the reset animation isn't shown while closing
    setTimeout(() => {
      menu.reset();
    }, 800);
  }

  return (
    <div
      id="mobile-sidebar"
      className={clsx(styles.sidebar, isOpen && styles.open)}
    >
      <div className={styles.header}>
        <LazyLoadingImage
          alt="Columbia Care"
          src={logoIcon}
          className={styles.logoIcon}
        />
        <div className={styles.cross}>
          {/*<div className={styles.member_dropdown_wrapper}>
            <AccountIcon className={styles.member_svg} onClick={handleOpen} />
            {open ? (
              <div className={styles.member_dropdown} ref={ref}>
                <ul>
                  <li
                    onClick={() => {
                      closeMe();
                      close();
                    }}
                  >
                    <a href="/about-us">
                      <AccountSmallIcon /> My Account
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      closeMe();
                      close();
                    }}
                  >
                    <a href="https://columbia.care/">
                      <LogOffIcon /> Logout
                    </a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
                  <CartIcon className={styles.member_svg} />*/}
          <Cross
            onToggle={() => {
              close();
            }}
          />
        </div>
      </div>
      {/*<div className={styles.member_location}>
        <LocationIconSmall />
        <span>
          <span className={styles.text_info}>You're Shopping Adult Use at</span>
          <span className={styles.text_location}>Malley Dr @ Northglenn</span>
        </span>
        <RightArrow className={styles.right_arrow} />
          </div>*/}
      <Menu {...menu} close={close} storeData={storeData} posthog={posthog} />
    </div>
  );
};

export default Sidebar;
