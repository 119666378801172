module.exports = `fragment hero_banner on Contentstack_hero_banner {
    display_on_mobile_only_hero_banner
    global_id
    hero_banner_alignment
    hero_banner_calls_to_action {
      hero_banner_cta_1 {
        href
        title
      }
      hero_banner_cta_2 {
        title
        href
      }
    }
    hero_banner_color_styles
    hero_banner_enabled
    hero_banner_heading
    hero_banner_height
    hero_banner_layout_styles
    hero_banner_subheading
    hero_banner_variant
    set_aria_role_to_main
    mobile_background_image_hero_banner {
      url
      title
      # description
    }
    hero_banner_video_group {
      hero_banner_video_poster {
        url
        title
        # description
      }
      hero_banner_video {
        url
        title
        # description
      }
    }
    hero_banner_image {
      url
      # description
      title
    }
  }`;