import { string, object, bool, number } from "yup";

import {
  map as _map,
  filter as _filter,
  reduce as _reduce,
  some as _some,
} from "lodash";

import { transformHeroData } from "./hero";
import { transformQuoteData } from "./quote";
import { transformFullWidthMediaData } from "./full-width-media";
import { transformCardsData } from "./cards";
// split screen
// import { transformSplitScreenData } from "./split-screen";
import { transformIconLinksData } from "./icon-links";
import { transformQuickStatsData } from "./quick-stats";
import { transformLogoWallData } from "./logo-wall";
import { transformSplitScreenCompactData } from "./split-screen/split-screen-compact";
import { transformSplitScreenStandardData } from "./split-screen/split-screen-standard";
import { transformSplitScreenIconsData } from "./split-screen/split-screen-icons";
import { transformSplitScreenLinksData } from "./split-screen/split-screen-links";
import { transformSplitScreenContainedData } from "./split-screen/split-screen-contained";
import { transformTestimonialData } from "./testimonial";
import { transformTextData } from "./text";
import { transformMarkupData } from "./markup";
import { transformMapData } from "./map";
import { transformStatementData } from "./statement";
import { transformInterstitialData } from "./interstitial";
import { transformLatestNewsData } from "./latest-news";
import { transformAllNewsData } from "./all-news";
import { transformScriptEmbedData } from "./script-embed";
import { transformIframeData } from "./iframe";
import { transformHubspotFormData } from "./hubspot-form";
import { transformSiteElementData } from "./site-element";
import { transformSuperSplitScreenData } from "./super-split-screen";
import { transformProductsPanelData } from "./product_panel";
import { transformCampaignBannerData } from "./campaign-banner";

import { stripTrailingSlash } from "../../../helpers";

export const layoutModel = {
  title: null,
  url: null,
  blocks: null,
  context: null,
};

export let layoutSchema = object().shape({
  title: string().required(),
  url: string().required(),
  blocks: object().nullable(),
  context: object().nullable(),
});

export const transformLayoutData = (page) => {
  try {
    const modularBlocks =
      page && page.modular_blocks ? page.modular_blocks : [];

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal(layoutModel);

    if (page) {
      const url = page.url;
      hydratedModel.title = page.title;
      hydratedModel.url = url === "/" ? url : stripTrailingSlash(url);
      hydratedModel.context = _some(page.context) ? page.context : null;
      hydratedModel.blocks = modularBlocks.reduce((acc, modularBlock) => {
        for (const blockKey of Object.keys(modularBlock)) {
          const block = modularBlock[blockKey];
          if (block) {
            const transform = mapGlobalFieldToBlockTransform(blockKey);
            if (transform) {
              acc.push({
                component_name: transform.component_name,
                component_data: transform.fn(block),
              });
            }
            break;
          }
        }
        return acc;
      }, []);

      try {
        layoutSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const siteConfigModel = {
  title: null,
  siteTitle: null,
  socialMedia: {
    youtube: null,
    twitter: null,
    instagram: null,
    facebook: null,
    linkedin: null,
  },
  loyalty: {
    iosLink: {
      title: null,
      href: null,
    },
    androidLink: {
      title: null,
      href: null,
    },
  },
  env: null,
  blocks: null,
  locationsMap: {
    heading: null,
    subheading: null,
  },
  seo: {
    metaTitle: null,
    metaRobots: null,
    metaDescription: null,
  },
  features: {
    maintenanceMode: null,
    authentication: {
      status: null,
    },
  },
};

export let siteConfigSchema = object().shape({
  title: string().nullable(),
  siteTitle: string().nullable(),
  blocks: object().shape({
    youtube: string().nullable(),
    twitter: string().nullable(),
    instagram: string().nullable(),
    facebook: string().nullable(),
    linkedin: string().nullable(),
  }),
  locationsMap: object().shape({
    heading: string().nullable(),
    subheading: string().nullable(),
  }),
  env: object().nullable(),
  seo: object().shape({
    metaTitle: string().nullable(),
    metaRobots: string().nullable(),
    metaDescription: string().nullable(),
  }),
  features: object().shape({
    maintenanceMode: bool().nullable(),
    authentication: object().shape({
      status: string().nullable(),
    }),
  }),
  loyalty: object().shape({
    iosLink: object().shape({
      href: string().nullable(),
      title: string().nullable(),
    }),
    androidLink: object().shape({
      href: string().nullable(),
      title: string().nullable(),
    }),
  }),
});

export const transformSiteConfigData = (config) => {
  // console.log('transformSiteConfigData', config)
  try {
    const modularBlocks =
      config && config.modular_blocks ? config.modular_blocks : [];

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal(siteConfigModel);

    if (config) {
      hydratedModel.title = config.title;
      hydratedModel.siteTitle = config.site_title;
      hydratedModel.socialMedia = {
        youtube: config.social_media ? config.social_media.youtube : null,
        twitter: config.social_media ? config.social_media.twitter : null,
        instagram: config.social_media ? config.social_media.instagram : null,
        facebook: config.social_media ? config.social_media.facebook : null,
        linkedin: config.social_media ? config.social_media.linkedin : null,
      };
      hydratedModel.blocks = modularBlocks.reduce((acc, modularBlock) => {
        for (const blockKey of Object.keys(modularBlock)) {
          const block = modularBlock[blockKey];
          if (block) {
            const transform = mapGlobalFieldToBlockTransform(blockKey);
            // @todo: bail if no global_id
            if (transform) {
              // @todo: variant needs to be a required property of any modular block
              const variant = block.variant || "default";
              // since we introduced nested megablocks, we can now nest global ids
              // `block.global_id || block.variant` doesn't scale but nice quick hack for now
              acc[block.global_id || block.variant] = {
                component_name: transform.component_name,
                component_data: transform.fn(block),
              };
            }
            break;
          }
        }
        return acc;
      }, {});
      hydratedModel.seo = {
        metaTitle: config.seo.meta_title,
        metaRobots: config.seo.meta_robots,
        metaDescription: config.seo.meta_description,
      };
      hydratedModel.features = {
        maintenanceMode: config.features.maintenance_mode,
        authentication: {
          status: config.features.authentication.status,
        },
        jane: {
          configuration: {
            theme_color: config.features.jane.configuration.theme_color,
          },
          discounts: {
            enable_discounts_modal_on_product_tile_link:
              config.features.jane.discounts
                .enable_discounts_modal_on_product_tile_link,
          },
        },
      };
      hydratedModel.env = config.env;
      hydratedModel.loyalty = {
        iosLink: {
          title: config.loyalty.ios_link ? config.loyalty.ios_link.title : null,
          href: config.loyalty.ios_link ? config.loyalty.ios_link.href : null,
        },
        androidLink: {
          title: config.loyalty.android_link
            ? config.loyalty.android_link.title
            : null,
          href: config.loyalty.android_link
            ? config.loyalty.android_link.href
            : null,
        },
      };

      try {
        siteConfigSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No site config found in `transformSiteConfigData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};

const mapGlobalFieldToBlockTransform = (key) => {
  const map = {
    site_page_locations_map: {
      component_name: "Map",
      fn: transformMapData,
    },
    site_page_super_split_screen: {
      component_name: "SuperSplitScreen",
      fn: transformSuperSplitScreenData,
    },
    site_page_iframe: {
      component_name: "Iframe",
      fn: transformIframeData,
    },
    site_page_markup: {
      component_name: "Markup",
      fn: transformMarkupData,
    },
    site_page_site_element: {
      component_name: "SiteElement",
      fn: transformSiteElementData,
    },
    // site_page_store_element: {
    //   component_name: "StoreElement",
    //   fn: transformStoreElementData,
    // },
    site_page_hero_banner: {
      component_name: "Markup",
      fn: transformMarkupData,
    },
    site_page_campaign_banner: {
      component_name: "CampaignBanner",
      fn: transformCampaignBannerData,
    },
    site_page_quote: {
      component_name: "Quote",
      fn: transformQuoteData,
    },
    site_page_full_width_media: {
      component_name: "FullWidthMedia",
      fn: transformFullWidthMediaData,
    },
    site_page_hero: {
      component_name: "Hero",
      fn: transformHeroData,
    },
    site_page_icon_links: {
      component_name: "IconLinks",
      fn: transformIconLinksData,
    },
    site_page_quick_stats: {
      component_name: "QuickStats",
      fn: transformQuickStatsData,
    },
    site_page_statement: {
      component_name: "Statement",
      fn: transformStatementData,
    },
    site_page_cards: {
      component_name: "Card",
      fn: transformCardsData,
    },
    site_page_testimonial: {
      component_name: "Testimonial",
      fn: transformTestimonialData,
    },
    site_page_text: {
      component_name: "Text",
      fn: transformTextData,
    },
    site_page_logo_wall: {
      component_name: "LogoWall",
      fn: transformLogoWallData,
    },
    site_page_hubspot_form: {
      component_name: "HubspotForm",
      fn: transformHubspotFormData,
    },
    site_page_latest_news: {
      component_name: "LatestNews",
      fn: transformLatestNewsData,
    },
    site_page_all_news: {
      component_name: "AllNews",
      fn: transformAllNewsData,
    },
    page_hero_banner: {
      component_name: "Hero",
      fn: transformHeroData,
    },
    page_quote: {
      component_name: "Quote",
      fn: transformQuoteData,
    },
    page_full_width_media: {
      component_name: "FullWidthMedia",
      fn: transformFullWidthMediaData,
    },
    page_markup: {
      component_name: "Markup",
      fn: transformMarkupData,
    },
    Page_Split_Screen_Compact: {
      component_name: "SplitScreenCompact",
      fn: transformSplitScreenCompactData,
    },
    Page_Split_Screen_Standard: {
      component_name: "SplitScreenStandard",
      fn: transformSplitScreenStandardData,
    },
    Page_Split_Screen_Links: {
      component_name: "SplitScreenLinks",
      fn: transformSplitScreenLinksData,
    },
    Page_Split_Screen_Icons: {
      component_name: "SplitScreenIcons",
      fn: transformSplitScreenIconsData,
    },
    Page_Split_Screen_Contained: {
      component_name: "SplitScreenContained",
      fn: transformSplitScreenContainedData,
    },
    Page_Icon_Links: {
      component_name: "IconLinks",
      fn: transformIconLinksData,
    },
    Page_Quick_Stats: {
      component_name: "QuickStats",
      fn: transformQuickStatsData,
    },
    Page_Statement: {
      component_name: "Statement",
      fn: transformStatementData,
    },
    Page_Cards: {
      component_name: "Card",
      fn: transformCardsData,
    },
    Page_Testimonial: {
      component_name: "Testimonial",
      fn: transformTestimonialData,
    },
    Page_Text: {
      component_name: "Text",
      fn: transformTextData,
    },
    page_locations_map: {
      component_name: "Map",
      fn: transformMapData,
    },
    page_logo_wall: {
      component_name: "LogoWall",
      fn: transformLogoWallData,
    },
    site_config_interstitial: {
      component_name: "Interstitial",
      fn: transformInterstitialData,
    },
    site_config_markup: {
      component_name: "Markup",
      fn: transformMarkupData,
    },
    site_config_hero: {
      component_name: "Hero",
      fn: transformHeroData,
    },
    site_config_site_element: {
      component_name: "SiteElement",
      fn: transformSiteElementData,
    },
    page_latest_news: {
      component_name: "LatestNews",
      fn: transformLatestNewsData,
    },
    page_all_news: {
      component_name: "AllNews",
      fn: transformAllNewsData,
    },
    page_hubspot_form: {
      component_name: "HubspotForm",
      fn: transformHubspotFormData,
    },
    page_iframe: {
      component_name: "Iframe",
      fn: transformIframeData,
    },
    page_site_element: {
      component_name: "SiteElement",
      fn: transformSiteElementData,
    },
    product_panel: {
      component_name: "ProductsPanel",
      fn: transformProductsPanelData,
    },
  };

  if (!map[key]) {
    // console.warn('Did not find a block component for a global field:', key);
    return null;
  }

  return map[key];
};

// location
export const locationModel = {
  title: null,
  url: null,
  location: null,
};

export let locationSchema = object().shape({
  title: string().required(),
  url: string().required(),
  location: object().required(),
});

export const transformLocationData = (location) => {
  try {
    // console.log('transformLocationData',location)
    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal(locationModel);

    if (location) {
      hydratedModel.title = location.title;
      hydratedModel.url = location.url;
      hydratedModel.location = location;

      // console.log("hydrated location model", hydratedModel);

      try {
        locationSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No location found in `transformLocationData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};

// post
export const postModel = {
  title: null,
  url: null,
  post: null,
};

export let postSchema = object().shape({
  title: string().required(),
  url: string().required(),
  post: object().required(),
});

export const transformPostData = (post) => {
  try {
    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal(postModel);

    if (post) {
      hydratedModel.title = post.title;
      hydratedModel.url = post.url;
      hydratedModel.post = post;

      // console.log("hydrated post model", hydratedModel);

      try {
        postSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No post found in `transformPostData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};

// build info
export const buildInfoModel = {
  description: null,
  name: null,
  timestamp: null,
  version: null,
};

export let buildInfoSchema = object().shape({
  description: string().required(),
  name: string().required(),
  timestamp: number().required(),
  version: string().required(),
});

export const transformBuildInfoData = (buildInfo) => {
  try {
    // console.log('transformBuildInfoData', buildInfo)
    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal(buildInfoModel);

    if (buildInfo) {
      hydratedModel.description = buildInfo.description;
      hydratedModel.name = buildInfo.name;
      hydratedModel.timestamp = buildInfo.timestamp;
      hydratedModel.version = buildInfo.version;

      try {
        buildInfoSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No build info found in `transformBuildInfoData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};

// databag
export const databagModel = {
  locations: null,
  sitemap: null,
  news: null,
  campaigns: null,
  instaFeed: null,
  instaPosts: null,
  specials: null,
};

export let databagSchema = object().shape({
  locations: object().nullable(),
  sitemap: object().nullable(),
  news: object().nullable(),
  campaigns: object().nullable(),
  instaFeed: object().nullable(),
  instaPosts: object().nullable(),
  specials: object().nullable(),
});

export const transformDatabagData = (databag) => {
  try {
    // console.log("transformDatabagData", databag);
    let hydratedModel = Object.seal(databagModel);

    if (databag) {
      if (databag.locations !== undefined) {
        hydratedModel.locations = databag.locations;
      }
      if (databag.sitemap !== undefined) {
        hydratedModel.sitemap = databag.sitemap;
      }
      if (databag.news !== undefined) {
        hydratedModel.news = databag.news;
      }
      if (databag.campaigns !== undefined) {
        hydratedModel.campaigns = databag.campaigns;
      }

      if (databag.instaFeed !== undefined) {
        hydratedModel.instaFeed = databag.instaFeed;
      }

      if (databag.instaPosts !== undefined) {
        hydratedModel.instaPosts = [];
        for (const postsKey of Object.keys(databag.instaPosts.data)) {
          if (
            databag.instaPosts.data[postsKey] &&
            databag.instaPosts.data[postsKey].edges
          ) {
            for (const pageRes of databag.instaPosts.data[postsKey].edges) {
              const page = pageRes.node;
              hydratedModel.instaPosts.push(page);
            }
          }
        }
      }

      if (databag.specials !== undefined) {
        hydratedModel.specials = databag.specials;
      }

      try {
        databagSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No data found in `transformDatabagData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};

// api data
export const apiModel = {
  apiData: null,
};

export let apiSchema = object().shape({
  apiData: object().nullable(),
});

export const transformApiData = (data) => {
  try {
    // console.log('transformApiData', data)
    let hydratedModel = Object.seal(apiModel);

    if (data) {
      if (data.apiData !== undefined) {
        hydratedModel.apiData = data.apiData;
      }

      try {
        apiSchema.validateSync(hydratedModel);
        return hydratedModel;
      } catch (err) {
        console.warn(err);
        return null;
      }
    } else {
      // console.log("No data found in `transformApiData`");
      return {};
    }
  } catch (err) {
    console.error(err);
  }
};
