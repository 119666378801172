// Spinner middleware that provides loading indicators for async actions.
// This will probably need decoupling from 3rd party actions at some point.
import { AuthActions } from "@col-care/common/providers/cognito/constants";

// let's see how dealing with unbound actions works out
import { setSpinnerActive } from "@col-care/common/redux/ui/actions";

export const spinnerMiddleware = (props) => (store) => (next) => (action) => {
  const state = store.getState();

  switch (action.type) {
    case AuthActions.LOGIN_SUCCESS:
      state.ui.spinnerActive === true &&
        store.dispatch(setSpinnerActive(false));
      break;
    case AuthActions.AUTH_USER_FOUND:
      state.ui.spinnerActive === true &&
        store.dispatch(setSpinnerActive(false));
      break;
    case AuthActions.AUTH_NO_USER:
      state.ui.spinnerActive === true &&
        store.dispatch(setSpinnerActive(false));
      break;
    case AuthActions.CONFIRM_SIGNUP_SUCCESS:
      store.dispatch(setSpinnerActive(true));
      break;
    default:
      break;
  }

  return next(action);
};
