module.exports = `fragment campaign_banner on Contentstack_campaign_banner {
    type
    heading
    body
    campaign_id
    section_heading
    image {    
        url
        title
    }
    calls_to_action {
        cta_1 {
        href
        title
        }
    }
    test_variant
}`;
