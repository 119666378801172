import React from "react";
import { useToaster } from "react-hot-toast/headless";
import * as styles from "./notifcations.module.css";

const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el) => {
          if (el && typeof toast.height !== "number") {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        return (
          <div
            key={toast.id}
            ref={ref}
            style={{
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
              position: "fixed",
              minWidth: "275px",
              padding: "17px 25px",
              fontSize: "18px",
              fontWeight: "700",
              textAlign: "center",
              background: "#fff",
              color: "#375542",
              left: "50%",
              top: "12.5%",
              borderRadius: "100px",
              transform: "translate(-50%, -50%)",
              transition: "all 0.5s ease-out",
              opacity: toast.visible ? 1 : 0,
              zIndex: 1000000,
            }}
            {...toast.ariaProps}
          >
            <div className={styles.wrapper}>
              <div className={styles.circle}>
                <div className={styles.checkMark}></div>
              </div>
              <div> {toast.message}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Notifications;
