import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let splitScreenBlockSchema = object().shape({
  heading: string().nullable(),
  sub_heading: string().nullable(),
  text: string().nullable(),
  cta_href: string().nullable(),
  cta_title: string().nullable(),
  image_url: string().nullable(),
  image_title: string().nullable(),
  image_description: string().nullable(),
  logo_url: string().nullable(),
  logo_title: string().nullable(),
  logo_description: string().nullable(),
  links_href: string().nullable(),
  links_title: string().nullable(),
  // compact_text: string().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenBlockData = (data, legacy = true) => {
  // console.log('transformSplitScreenBlock', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      sub_heading: null,
      text: null,
      cta_href: null,
      cta_title: null,
      image_url: null,
      image_title: null,
      image_description: null,
      logo_url: null,
      logo_title: null,
      logo_description: null,
      links_href: null,
      links_title: null,
      compact_text: null,
      __digest: null,
    });
    
    // map the field names here
    if (legacy) {
      hydratedModel.heading = data.split_screen_heading;
      hydratedModel.sub_heading = data.split_screen_sub_heading;
      // hydratedModel.compact_text = data.split_screen_compact_text;
      hydratedModel.text = data.split_screen_text;
      hydratedModel.cta_href = data.split_screen_cta ? data.split_screen_cta.href : null;
      hydratedModel.cta_title = data.split_screen_cta ? data.split_screen_cta.title : null;
      hydratedModel.image_url = data.split_screen_image ? data.split_screen_image.url : null;
      hydratedModel.image_title = data.split_screen_image ? data.split_screen_image.title : null;
      hydratedModel.image_description = data.split_screen_image ? data.split_screen_image.description : null;
      hydratedModel.logo_url = data.split_screen_logo
        ? data.split_screen_logo.url
        : null;
      hydratedModel.logo_title = data.split_screen_logo
        ? data.split_screen_logo.title
        : null;
      hydratedModel.logo_description = data.split_screen_logo
        ? data.split_screen_logo.description
        : null;
      hydratedModel.links_href = data.split_screen_links
        ? data.split_screen_links.href
        : null;
      hydratedModel.links_title = data.split_screen_links
        ? data.split_screen_links.title
        : null;
    }
    else {
      hydratedModel.heading = data.super_split_screen_split_screen_contained_heading;
      hydratedModel.sub_heading = data.super_split_screen_split_screen_contained_sub_heading;
      // hydratedModel.compact_text = data.super_split_screen_split_screen_contained_compact_text;
      hydratedModel.text = data.super_split_screen_split_screen_contained_text;
      hydratedModel.cta_href = data.super_split_screen_split_screen_contained_cta ? data.super_split_screen_split_screen_contained_cta.href : null;
      hydratedModel.cta_title = data.super_split_screen_split_screen_contained_cta ? data.super_split_screen_split_screen_contained_cta.title : null;
      hydratedModel.image_url = data.super_split_screen_split_screen_contained_image ? data.super_split_screen_split_screen_contained_image.url : null;
      hydratedModel.image_title = data.super_split_screen_split_screen_contained_image ? data.super_split_screen_split_screen_contained_image.title : null;
      hydratedModel.image_description = data.super_split_screen_split_screen_contained_image ? data.super_split_screen_split_screen_contained_image.description : null;
      hydratedModel.logo_url = data.super_split_screen_split_screen_contained_logo
        ? data.super_split_screen_split_screen_contained_logo.url
        : null;
      hydratedModel.logo_title = data.super_split_screen_split_screen_contained_logo
        ? data.super_split_screen_split_screen_contained_logo.title
        : null;
      hydratedModel.logo_description = data.super_split_screen_split_screen_contained_logo
        ? data.super_split_screen_split_screen_contained_logo.description
        : null;
      hydratedModel.links_href = data.super_split_screen_split_screen_contained_links
        ? data.super_split_screen_split_screen_contained_links.href
        : null;
      hydratedModel.links_title = data.super_split_screen_split_screen_contained_links
        ? data.super_split_screen_split_screen_contained_links.title
        : null;
    }

    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenBlockSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};