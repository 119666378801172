import React, { useState } from "react";
import parse from "html-react-parser";
import { getHtmlParseOptions } from "@col-care/common/common";

import Button from "../../button/Button";
import RightArrowIcon from "@col-care/common/elements/icons/RightArrowIcon";
import { getSlug } from "@col-care/common/helpers";
import LightLogo from "../../navbar/Navbar/images/tgs-logo.png";
import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";
import * as modalStyles from "../modal.module.css";
// import CCLogoWhite from "../../../images/col-care-logo-white.png";

const AgeGate = (props) => {
  const [agree, setAgree] = useState(false);
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const parseOptions = getHtmlParseOptions();

  const buttonOpts = props.buttonOpts || ["tgssmall", "tgsButton"];

  const children = (
    <div className={modalStyles.AgeGateInner}>
      {/* <img className={modalStyles.AgeGateLogo} src={LightLogo} alt="TGS logo" /> */}
      <LazyLoadingImage
        className={modalStyles.AgeGateLogo}
        src={LightLogo}
        alt="TGS logo"
      />
      <div className={modalStyles.AgeGateTextWrapper}>
        <h2 id="modal-title">{props.modalTitle || "Alert"}</h2>

        {/*
        {props.modalBodyText ? parse(props.modalBodyText, parseOptions) : ""}
 

        <label className={modalStyles.AgreeCheckbox}>
          <input type="checkbox" onChange={checkboxHandler} />I Agree
        </label> */}

        <Button
          theme={"age_gate_button"}
          key={getSlug(props.confirmButtonText)}
          id={"age-gate-confirm-button"}
          label={
            <>
              {props.confirmButtonText}
              <RightArrowIcon className={modalStyles.ArrowIcon} />
            </>
          }
          onClick={(e) => {
            props.confirmButtonCallback(Date.now());
            props.dismissModal();
          }}
        />
        <div className={modalStyles.ExitPageText}>
          <a href="https://www.disney.com/" rel="noreferrer">
            no, click here to exit the site
          </a>
        </div>
      </div>
      <div className={modalStyles.TermsAndConditionText}>
        {props.modalBodyText ? parse(props.modalBodyText, parseOptions) : ""}
      </div>
    </div>
  );

  return children;
};

AgeGate.displayName = "AgeGate";

export default AgeGate;
