import smoothscroll from "smoothscroll-polyfill";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "whatwg-fetch";

import reduxWrapper from "./src/state/reduxWrapper";

export const wrapPageElement = ({ props, element }) =>
  reduxWrapper(props, element);

// other polyfills
smoothscroll.polyfill();

// polyfills for Jane
if (!window.ResizeObserver) {
  window.ResizeObserver = require("resize-observer-polyfill");
}

if (!window.IntersectionObserver) {
  window.IntersectionObserver = require("intersection-observer-polyfill");
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash) {
    const scrollTo = document.getElementById(location.hash);
    window.scroll({ top: scrollTo, behavior: "smooth" });
  } else {
    const savedPosition = getSavedScrollPosition(location);

    window.setTimeout(
      () =>
        window.scrollTo({
          left: savedPosition[0],
          top: savedPosition[1],
          behavior: "smooth",
        }),
      100
    );
  }
};
