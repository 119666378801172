import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let cardSchema = object().shape({
  cards_card_title: string().nullable(),
  cards_text: string().nullable(),
  cards_image: string().nullable(),
  cards_icon: string().nullable(),
  cta_title: string().nullable(),
  cta_href: string().nullable(),
  __digest: string().required(),
});

export const transformCardData = (data) => {
  // console.log("transformCard", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      cards_card_title: null,
      cards_text: null,
      cards_image: null,
      cards_icon: null,
      cta_title: null,
      cta_href: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.cards_card_title = data.cards_card_title;
    hydratedModel.cards_text = data.cards_text;
    hydratedModel.cards_image = data.cards_image.url;
    hydratedModel.cards_icon = data.cards_icon?.url;
    if (data.cards_cta) {
      hydratedModel.cta_title = data.cards_cta.title;
      hydratedModel.cta_href = data.cards_cta.href;
    }

    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      cardSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

// Page_Cards {
//   cards_heading
//   cards_enabled
//   cards_cta {
//     href
//     title
//   }
//   cards_cards {
//     cards_text
//     cards_card_title
//     cards_icon {
//       description
//       url
//       title
//     }
//     cards_image {
//       description
//       url
//       title
//     }
//   }
//   cards_style_options {
//     cards_background
//     cards_style
//   }
// }
