import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Hub } from "aws-amplify";

import actions from "./actions";

export const useHub = () => {
  const dispatch = useDispatch();

  // not sure we want to use this for dispatching actions but the logs are nice
  useEffect(() => {
    const hubListenerHandle = Hub.listen("auth", (capsule) => {
      switch (capsule.payload.event) {
        case "configured":
          // console.info('the Auth module is configured', capsule);
          // dispatch(actions.setAuthStatus(capsule.payload.data))
          break;
        case "signIn":
          // console.info('user signed in', capsule);
          dispatch(actions.loginSuccess(capsule.payload.data));
          break;
        case "signIn_failure":
          console.error("user sign in failed", capsule);
          // dispatch(actions.setAuthError(capsule))
          break;
        case "signUp":
          console.info("user signed up", capsule);
          dispatch(actions.signUpSuccess(capsule.payload));
          break;
        case "signUp_failure":
          console.error("user sign up failed");
          break;
        case "confirmSignUp":
          console.info("user confirmation successful", capsule);
          dispatch(actions.confirmSignUpSuccess(capsule.payload));
          break;
        case "completeNewPassword_failure":
          console.error("user did not complete new password flow");
          break;
        case "autoSignIn":
          console.info("auto sign in successful", capsule);
          // dispatch(actions.login(capsule.payload))
          break;
        case "autoSignIn_failure":
          console.error("auto sign in failed");
          break;
        case "forgotPassword":
          console.info("password recovery initiated");
          break;
        case "forgotPassword_failure":
          console.error("password recovery failed");
          break;
        case "forgotPasswordSubmit":
          console.info("password confirmation successful");
          dispatch(actions.forgotPasswordSuccess(capsule.payload));
          break;
        case "forgotPasswordSubmit_failure":
          console.error("password confirmation failed");
          break;
        case "verify":
          console.info("TOTP token verification successful");
          break;
        case "tokenRefresh":
          console.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.error("token refresh failed");
          break;
        case "cognitoHostedUI":
          console.info("Cognito Hosted UI sign in successful");
          break;
        case "cognitoHostedUI_failure":
          console.error("Cognito Hosted UI sign in failed");
          break;
        case "customOAuthState":
          console.info("custom state returned from CognitoHosted UI");
          break;
        case "customState_failure":
          console.error("custom state failure");
          break;
        case "parsingCallbackUrl":
          // console.info('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case "userDeleted":
          console.info("user deletion successful");
          break;
        case "updateUserAttributes":
          console.info("user attributes update successful");
          dispatch(actions.profileEditSuccess());
          dispatch(actions.doCurrentAuthenticatedUser());
          break;
        case "updateUserAttributes_failure":
          console.info("user attributes update failed");
          break;
        case "signOut":
          console.info("user signed out");
          dispatch(actions.logoutSuccess());
          break;
        default:
          console.warn(`Unexpected auth event [${capsule.payload.event}].`);
      }
    });

    return () => {
      hubListenerHandle();
    };
  }, []);
};
