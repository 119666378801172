import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

import { transformSplitScreenBlockData } from "../block";

export let splitScreenContainedSchema = object().shape({
  heading: string().nullable(),
  enabled: bool().nullable(),
  blocks: array().nullable(),
  styles: object().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenContainedData = (data, legacy = true) => {
  // console.log("transformSplitScreenContained", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      enabled: null,
      blocks: null,
      styles: null,
      __digest: null,
    });

    // map the field names here
    if (legacy) {
      // ugh, this is a mess
      const styles = {
        image_alignment: data.split_screen_styles.split_screen_image_alignment,
      }
      hydratedModel.styles = styles;
      hydratedModel.heading = data.split_screen_heading;
      hydratedModel.enabled = data.split_screen_contained_enabled;
      hydratedModel.blocks = data.split_screen_blocks.reduce((acc, block) => {
        acc.push(transformSplitScreenBlockData(block));
        return acc;
      }, []);
    }
    else {
      hydratedModel.styles =
        data.super_split_screen_split_screen_contained_styles;
      hydratedModel.heading = data.super_split_screen_split_screen_contained_contained_heading;
      hydratedModel.enabled = data.super_split_screen_split_screen_contained_enabled;
      hydratedModel.blocks = data.super_split_screen_split_screen_contained_blocks
        .reduce((acc, block) => {
          acc.push(transformSplitScreenBlockData(block, false));
          return acc;
        }, []);
    }

    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenContainedSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
