module.exports = `fragment logo_wall on Contentstack_logo_wall {
    logo_wall_style_options
    logo_wall_enabled
    image_and_links {
      reference {
        title
        category
      }
      logo_wall_url {
        title
        href
      }
      logo_wall_image {
        url
        title
        # description
      }
    }
  }`