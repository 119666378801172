import { string, object, array } from "yup";
import md5 from "md5";

export let productPanelSchema = object().shape({
  json: array().nullable(),
  name: string().nullable(),
  panel_id: string().nullable(),
  global_id: string().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformProductsPanelData = (data) => {
  // console.log("transformProductsPanelData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      json: null,
      name: null,
      panel_id: null,
      global_id: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.json = data.json;
    hydratedModel.name = data.name;
    hydratedModel.panel_id = data.panel_id;
    hydratedModel.global_id = data.global_id;
    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      productPanelSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
