// cart boilerplate
export const SET_SPECIALS_DATA = "@@col-care/session/SET_SPECIALS_DATA";
export const SET_CART_DATA = "@@col-care/session/SET_CART_DATA";
export const ADD_TO_CART = "@@col-care/session/ADD_TO_CART";
export const INCREMENT_QUANTITY = "@@col-care/session/INCREMENT_QUANTITY";
export const DECREMENT_QUANTITY = "@@col-care/session/DECREMENT_QUANTITY";
export const REMOVE_ITEM = "@@col-care/session/REMOVE_ITEM";

export const doSetSpecialsData = (value) => (dispatch, getState) => {
  dispatch(setSpecialsData(value));
};

const setSpecialsData = (value) => ({
  type: SET_SPECIALS_DATA,
  payload: value,
});

export const doSetCartData = (value) => (dispatch, getState) => {
  dispatch(setCartData(value));
};
const setCartData = (value) => ({
  type: SET_CART_DATA,
  payload: value,
});

export const doAddToCart = (value) => (dispatch, getState) => {
  const cartItem = Object.assign({ timestamp: Date.now() }, value);
  dispatch(addToCart(cartItem));
};
const addToCart = (value) => ({
  type: ADD_TO_CART,
  payload: value,
});

export const doIncrementQuantity = (value) => (dispatch, getState) => {
  dispatch(incrementQuantity(value));
};
const incrementQuantity = (value) => ({
  type: INCREMENT_QUANTITY,
  payload: value,
});

export const doDecrementQuantity = (value) => (dispatch, getState) => {
  dispatch(decrementQuantity(value));
};
const decrementQuantity = (value) => ({
  type: DECREMENT_QUANTITY,
  payload: value,
});

export const doRemoveItem = (value) => (dispatch, getState) => {
  dispatch(removeItem(value));
};
const removeItem = (value) => ({
  type: REMOVE_ITEM,
  payload: value,
});
export default {
  SET_SPECIALS_DATA: SET_SPECIALS_DATA,
  SET_CART_DATA: SET_CART_DATA,
  ADD_TO_CART: ADD_TO_CART,
  INCREMENT_QUANTITY: INCREMENT_QUANTITY,
  DECREMENT_QUANTITY: DECREMENT_QUANTITY,
  REMOVE_ITEM: REMOVE_ITEM,
  doSetSpecialsData: doSetSpecialsData,
  doSetCartData: doSetCartData,
  doAddToCart: doAddToCart,
  doIncrementQuantity: doIncrementQuantity,
  doDecrementQuantity: doDecrementQuantity,
  doRemoveItem: doRemoveItem,
};
