module.exports = `fragment split_screen_icons on Contentstack_split_screen_icons {
    split_screen_icons_styles {
      split_screen_icons_color_options
      split_screen_icons_image_alignment
    }
    split_screen_icons_heading
    split_screen_icons_enabled
    split_screen_icons_blocks {
      split_screen_icons_text
      split_screen_icons_row_heading
      split_screen_icons_image {
        url
        title
        description
      }
      split_screen_icons_cta {
        href
        title
      }
      split_screen_icons_icon {
        url
        title
        description
      }
    }
  }`;