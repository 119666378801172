import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import Cookies from "universal-cookie";
import clsx from "clsx";
import { GhubLink } from "@col-care/common/common";
import Spinner from "@col-care/common/elements/spinner/Spinner";
import MenuBar from "../MenuBar";
// import Button from "../Button";
import Banner from "../../banner/Banner";
//import useWindowDimensions from "./hooks/use-window-dimensions";
import LightLogo from "./images/tgs-logo.png";
import ArrowIcon from "./images/svg/arrow.svg";
import AccountIcon from "./images/svg/account.svg";
import LocationIconSmall from "./images/svg/location.svg";
import RightArrow from "./images/svg/right-arrow.svg";
import LogOffIcon from "./images/svg/power.svg";
// import OrderIcon from "./images/svg/order.svg";
import AccountSmallIcon from "./images/svg/account-small.svg";
import CartIcon from "./images/svg/cart.svg";
import {
  capturePosthogLink,
  capturePosthogEvent,
} from "@col-care/common/utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";
// import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";
import * as styles from "./Navbar.module.scss";

import menu from "./data";
// import LocationIcon from "./LocationIcon";
import "./styles/menu.scss";

const Navbar = (props) => {
  const _persist = useSelector((state) => state._persist);
  const stores = useSelector((state) => state.hydrate.databagData?.locations);
  const user = useSelector((state) => state.auth.currentUser);
  const storeId = useSelector((state) => state.session.cartData?.store?.id);
  // const location = useSelector((state) => state.router.location);
  const session = useSelector((state) => state.session);
  const siteConfigData = useSelector((state) => state.hydrate?.siteConfigData);

  const posthog = props?.posthog;

  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};
  const bannerRef = useRef(null);
  // const [show, setShow] = useState(true);
  const navigationMenu = [...menu];
  const [isHome, setIsHome] = useState(false);
  const [onTop, setOnTop] = useState(true);
  const [currentlyVisible, setCurrentlyVisible] = useState();
  // const dimensions = useWindowDimensions();
  const isScroll = isHome ? !onTop : true;
  const iconColor = isScroll ? "black" : "white";
  const [image, setImage] = useState(
    "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blta836bbd3037a42a3/63d2d52e9d7bcb542235064f/flower.jpg"
  );
  const bannerEnabled =
    props.bannerData && props.bannerData.news_banner_config
      ? props.bannerData.news_banner_config.enabled
      : false;
  const [showBanner, setShowBanner] = useState(bannerEnabled);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [calculatedPosition, setCalculatedPosition] = useState(0);

  const [open, setOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  // Start Post hog test code

  // End Posthog Test Code

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleGhubLinkClick = () => {
    setShowDropdown(false); // Close the dropdown menu
  };

  // const catMenu = useRef(null);
  // const [openSlide, setopenSlide] = useState("");

  // console.log("cart data", state.session.cartData);

  const handleOpen = () => {
    setOpen(!open);
  };
  const ref = useRef();
  const refDropdown = useRef();

  const getStoreSelector = () => {
    return (
      <div
        onClick={() => {
          navigate("/locations");
          capturePosthogLink({
            eventName: posthogEvents.STORE_SELECTOR_NAVIGATION,
            title: "Navigate to locations page from store selector",
            to: "/locations",
            posthog,
          });
        }}
        className={styles.member_location}
      >
        <LocationIconSmall />
        <span className={!_persist.rehydrated ? styles.loader : ""}>
          {!_persist.rehydrated ? <Spinner /> : getCurrentStore()}
        </span>
        {_persist.rehydrated && <RightArrow className={styles.right_arrow} />}
      </div>
    );
  };
  const getStoreSelectorTest = () => {
    if (typeof storeId === "string" && storeId.trim() !== "") {
      // Render the dropdown menu
      return (
        <div
          className={clsx(styles.member_location, open && styles.dropdownOpen)}
          onClick={() => {
            handleDropdownToggle(); // Toggle dropdown menu visibility
            capturePosthogEvent({
              eventName: posthogEvents.TOGGLE_STORE_SELECTOR,
              title: "Toggle store selector",
              posthog,
              databag: {
                cartData: session.cartData,
              },
            });
          }}
        >
          <LocationIconSmall />
          <span className={!_persist.rehydrated ? styles.loader : ""}>
            {!_persist.rehydrated ? <Spinner /> : getCurrentStore()}
          </span>
          {_persist.rehydrated && (
            <RightArrow
              className={`${styles.right_arrow} ${
                showDropdown ? styles.rotate : ""
              }`}
            />
          )}
        </div>
      );
    } else {
      // Render the navigation link to /locations
      return (
        <div
          onClick={() => {
            navigate("/locations");
          }}
          className={styles.member_location}
        >
          <LocationIconSmall />
          <span className={!_persist.rehydrated ? styles.loader : ""}>
            {!_persist.rehydrated ? <Spinner /> : getCurrentStore()}
          </span>
          {_persist.rehydrated && <RightArrow className={styles.right_arrow} />}
        </div>
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Close the dropdown if it's open
      setShowDropdown(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getCurrentStore = () => {
    const currentStoreId = session.cartData.store.id;
    const currentStore = stores
      ? stores.find((store) => store.content.id === currentStoreId)
      : null;

    if (!currentStoreId || !currentStore) {
      return (
        <span>
          <span className={styles.text_info}>
            {`You're not shopping at a store`}
          </span>
          <span className={styles.text_location}>
            {`Click here to pick one`}
          </span>
        </span>
      );
    }
    return (
      <>
        <span className={styles.text_info}>
          {`You're Shopping ${getStoreType()} at`}
        </span>
        <span className={styles.text_location}>
          {currentStore.content?.nickname}
        </span>
      </>
    );
  };

  const getStoreType = () => {
    return `Adult Use`;
  };

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };
  const useOutsideClickDropdown = (refDropdown, callback) => {
    const handleClick = (e) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);
  };
  useOutsideClickDropdown(refDropdown, () => {
    setShowDropdown(false);
  });
  useOutsideClick(ref, () => {
    setOpen(!open);
  });

  const cookyKey =
    props.bannerData && props.bannerData.news_banner_config
      ? "cc_banner::" + props.bannerData.news_banner_config.campaign_id
      : null;
  const actuallyShowBanner =
    props.bannerData &&
    props.bannerData.news_banner_config &&
    cookies.cookies &&
    cookies.cookies[cookyKey] === undefined &&
    props.bannerData.news_banner_config.enabled &&
    showBanner;

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setOnTop(winScroll <= 120);
  };

  function handleMouseEnter(imagePath) {
    return () => {
      setImage(imagePath);
    };
  }

  const handleClickMenu = (item) => {
    // Replace with router.push
    alert(item.value);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", listenToScroll);
      setIsHome(true);

      const cb = () => {
        const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
        setBannerHeight(height);
        const _calculatedPosition =
          height - window.scrollY >= 0 ? height - window.scrollY : 0;
        setCalculatedPosition(_calculatedPosition);
      };
      window.addEventListener("scroll", cb);

      return () => {
        window.removeEventListener("scroll", listenToScroll);
        window.removeEventListener("scroll", cb);
      };
    }
  }, []);

  useEffect(() => {
    const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
    setBannerHeight(height);
    const _calculatedPosition =
      height - window.scrollY >= 0 ? height - window.scrollY : 0;
    setCalculatedPosition(_calculatedPosition);
  }, []);

  useEffect(() => {
    const cb = () => {
      const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
      setBannerHeight(height);
      const _calculatedPosition =
        height - window.scrollY >= 0 ? height - window.scrollY : 0;
      setCalculatedPosition(_calculatedPosition);
    };

    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
    };
  }, [bannerRef.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (bannerRef.current ? bannerRef.current.offsetHeight : 0) !==
        bannerHeight
      ) {
        const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
        setBannerHeight(height);
        const _calculatedPosition =
          height - window.scrollY >= 0 ? height - window.scrollY : 0;
        setCalculatedPosition(_calculatedPosition);
      }
    }, 25);
    return () => clearInterval(interval);
  }, []);

  function setCurrent(value) {
    setCurrentlyVisible(value);
  }

  function clearCurrent() {
    setCurrentlyVisible();
  }
  function setCurrent(value) {
    setCurrentlyVisible(value);
  }
  function closeMe() {
    setOpen();
  }
  return (
    <>
      <div ref={bannerRef} className={styles.banner}>
        <Banner
          bannerData={props.bannerData}
          showBanner={showBanner}
          setShowBanner={setShowBanner}
          actuallyShowBanner={actuallyShowBanner}
          cookyKey={cookyKey}
          posthog={posthog}
        />
      </div>
      <header
        style={actuallyShowBanner ? { top: calculatedPosition + "px" } : {}}
        className={clsx(styles.wrapper, isScroll && styles.dark)}
      >
        <div className={styles.container}>
          <div className={styles.logo}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              onKeyDown={() => navigate("/")}
              src={LightLogo}
              alt="Home"
              aria-label="Home"
              // tabIndex={1}
              role="button"
            />
          </div>
          <nav className={clsx(styles.navbar, isScroll && styles.dark)}>
            {menu?.map((item) => (
              <React.Fragment key={item?.value}>
                {item.items.length > 0 ? (
                  <div
                    className={clsx(styles.dropdown)}
                    onMouseEnter={() => {
                      setCurrent(item.value);
                    }}
                    onKeyDown={() => {
                      setCurrent(item.value);
                    }}
                    onMouseLeave={() => {
                      clearCurrent();
                    }}
                  >
                    <button
                      id="nav-arrow-button"
                      to={item.href || "#"}
                      className={styles.btn}
                    >
                      {item?.value}
                      <ArrowIcon />
                    </button>
                    <div
                      onMouseLeave={() => {
                        clearCurrent();
                      }}
                      className={clsx(
                        styles.content,
                        currentlyVisible === item.value && styles.dropdownOpen
                      )}
                    >
                      <div>
                        <h3>{item?.value}</h3>
                        <div className={styles.gridWrapper}>
                          <div role="button">
                            <img
                              src={image}
                              alt="thumbnail values"
                              width="100%"
                            />
                          </div>
                          <div className={styles.grid}>
                            {item.items?.map((sub) => (
                              <div
                                key={sub?.value}
                                tabIndex="0"
                                onMouseEnter={handleMouseEnter(sub?.thumbnail)}
                                onClick={() => {
                                  clearCurrent();
                                  if (storeId) {
                                    // Update the navigation based on the condition
                                    const link = `/shop/${storeId}?category%5B0%5D=${sub.href}`;
                                    navigate(link);
                                    capturePosthogLink({
                                      eventName:
                                        posthogEvents.HEADER_NAVIGATION,
                                      title: sub?.value,
                                      to: link,
                                      posthog,
                                    });
                                  } else {
                                    // Navigate to a default location if store.id is false
                                    navigate("/locations");
                                    capturePosthogLink({
                                      eventName:
                                        posthogEvents.HEADER_NAVIGATION,
                                      title:
                                        sub?.value +
                                        " Navigate to a default location",
                                      to: "/locations",
                                      posthog,
                                    });
                                  }
                                }}
                                onKeyDown={() => {
                                  clearCurrent();
                                  const link = storeId
                                    ? `/shop/${storeId}?category%5B0%5D=${sub.href}`
                                    : "/locations";
                                  navigate(link);

                                  capturePosthogLink({
                                    eventName:
                                      posthogEvents.HEADER_NAVIGATION + !storeId
                                        ? " " +
                                          posthogEvents.LOCATION_LINK_CLICKED
                                        : "",
                                    title: sub?.value + " " + sub?.content,
                                    to: link,
                                    posthog,
                                  });
                                }}
                              >
                                <h4>
                                  <ArrowIcon />
                                  {sub?.value}
                                </h4>
                                <p>{sub?.content}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <GhubLink
                    label={item.value}
                    destination={(() => {
                      // @todo: look into creating navigation cancelation in GhubLink
                      if (item.href === "/shop") {
                        switch (true) {
                          case typeof storeId === "string":
                            return `/shop/${storeId}`;
                          default:
                            return "/locations";
                        }
                      } else {
                        return item.href;
                      }
                    })()}
                    attributes={{
                      onMouseEnter: () => {
                        setCurrent(item.value);
                      },
                      onKeyDown: () => {
                        setCurrent(item.value);
                      },
                      onClick: () => {
                        let itemLink = item.href;
                        if (item.href === "/shop") {
                          if (typeof storeId === "string") {
                            itemLink = `/shop/${storeId}`;
                          } else {
                            itemLink = "/locations";
                          }
                        }

                        capturePosthogLink({
                          eventName: posthogEvents.HEADER_NAVIGATION,
                          title: item.value,
                          to: itemLink,
                          posthog,
                        });
                      },
                    }}
                    children={item.value}
                  />
                )}
              </React.Fragment>
            ))}
          </nav>
          <div className={styles.member_wrapper}>
            <div className={styles.store_selector}>{getStoreSelector()}</div>
            <div className={styles.member_dropdown_wrapper}>
              {siteConfigData.features?.authentication?.status ===
                "enabled" && (
                <AccountIcon
                  className={styles.member_svg}
                  onClick={() => {
                    if (!user) {
                      navigate("/user");
                      capturePosthogLink({
                        eventName: posthogEvents.HEADER_NAVIGATION,
                        title: "My account",
                        to: "/user",
                        posthog,
                      });
                    } else {
                      {
                        handleOpen();
                      }
                    }
                  }}
                />
              )}
              {open ? (
                <div className={styles.member_dropdown} ref={ref}>
                  {user?.attributes && (
                    <ul>
                      <li
                        onClick={() => {
                          closeMe();
                        }}
                      >
                        <Link
                          to="/user"
                          onClick={() => {
                            capturePosthogLink({
                              eventName: posthogEvents.HEADER_NAVIGATION,
                              title: "My account",
                              to: "/user",
                              posthog,
                            });
                          }}
                        >
                          <AccountSmallIcon /> My Account
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          closeMe();
                          capturePosthogLink({
                            eventName: posthogEvents.HEADER_NAVIGATION,
                            title: "Logout",
                            to: "/user/logout",
                            posthog,
                          });
                        }}
                      >
                        <Link to="/user/logout">
                          <LogOffIcon /> Logout
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              ) : null}
            </div>
            <div
              className={styles.cart_container}
              onClick={() => {
                if (
                  user ||
                  siteConfigData.features.authentication.status === "disabled"
                ) {
                  navigate("/checkout");
                  capturePosthogLink({
                    eventName: posthogEvents.HEADER_NAVIGATION,
                    title: "Checkout",
                    to: "/checkout",
                    posthog,
                  });
                } else {
                  navigate("/user/account");
                  capturePosthogLink({
                    eventName: posthogEvents.HEADER_NAVIGATION,
                    title: "Navigated to user account",
                    to: "/user/account",
                    posthog,
                  });
                }
              }}
            >
              <CartIcon className={styles.member_svg} />
              {!!session?.cartData.productsCount && (
                <div id={styles.item_amount}>
                  {session.cartData.productsCount <= 99
                    ? session.cartData.productsCount
                    : `:)`}
                </div>
              )}
            </div>
            <div className={styles.show_mobile}>
              <MenuBar
                animation={["slideIn", "slideOut"]}
                iconColor={iconColor}
                textColor={isScroll ? "rgba(0, 0, 0, 0.4)" : "white"}
                onClick={handleClickMenu}
                onKeyDown={handleClickMenu}
                data={menu}
                storeData={session.cartData.store.id}
                posthog={posthog}
              />
            </div>
          </div>
        </div>

        {/* {!variant === "test" && !ctaClick ? (
          <div className={styles.post_hog_test} id="shop_now_button">
            <div className={styles.member_location_mobile}>
              <div>{getStoreSelector()}</div>
            </div>
            <div className={styles.shop_now_text_section}>
              <GhubLink
                destination={(() => {
                  if (typeof storeId === "string" && storeId.trim() !== "") {
                    return `/shop/${storeId}`;
                  } else {
                    return "/locations";
                  }
                })()}
                attributes={{
                  onClick: () => {
                    capturePosthogLink({
                      eventName: posthogEvents.SHOP_NOW_BUTTON_CLICK,
                      title: "Shop Now",
                      to: `/shop/${storeId}`,
                      posthog, // Pass the posthog instance
                    });
                  },
                }}
              >
                Shop Now
              </GhubLink>
            </div>
          </div>
    
              */}
        <div className={styles.post_hog_test}>
          <div className={styles.member_location_mobile}>
            <div className={styles.dropdown} ref={refDropdown}>
              <div>{getStoreSelectorTest()}</div>
            </div>

            <div
              className={`${styles.dropdownMenu} ${
                showDropdown ? styles.show : ""
              }`}
            >
              <div className={styles.shoping_at_section}>
                {!_persist.rehydrated ? <Spinner /> : getCurrentStore()}
              </div>
              <div className={styles.shop_now_button}>
                <GhubLink
                  destination={
                    typeof storeId === "string" && storeId.trim() !== ""
                      ? `/shop/${storeId}`
                      : `/locations`
                  }
                  className={styles.shop_now}
                  attributes={{
                    onClick: () => {
                      capturePosthogLink({
                        eventName:
                          posthogEvents.STORE_NAVIGATION +
                          " " +
                          posthogEvents.HEADER_MOBILE_NAVIGATION,
                        title: "Shop Now",
                        to:
                          typeof storeId === "string" && storeId.trim() !== ""
                            ? `/shop/${storeId}`
                            : `/locations`,
                        posthog,
                      });
                      handleGhubLinkClick();
                    },
                  }}
                >
                  Shop Now
                </GhubLink>
              </div>
              <div className={styles.shop_buttons}>
                <GhubLink
                  attributes={{
                    onClick: () => {
                      handleGhubLinkClick();
                      capturePosthogLink({
                        eventName:
                          posthogEvents.STORE_NAVIGATION +
                          " " +
                          posthogEvents.HEADER_MOBILE_NAVIGATION,
                        title: "View Store Details",
                        to: `/locations/${storeId}`,
                        posthog,
                      });
                    },
                  }}
                  destination={`/locations/${storeId}`}
                >
                  View Store Details
                </GhubLink>

                <GhubLink
                  attributes={{
                    onClick: () => {
                      handleGhubLinkClick();
                      capturePosthogLink({
                        eventName:
                          posthogEvents.LOCATION_LINK_CLICKED +
                          " " +
                          posthogEvents.HEADER_MOBILE_NAVIGATION,
                        title: "Change Location",
                        to: "/locations",
                        posthog,
                      });
                    },
                  }}
                  destination="/locations"
                >
                  Change Location
                </GhubLink>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Navbar;
