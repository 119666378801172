import React from "react"; // eslint-disable-line
import PropTypes from "prop-types";

const HamBurgerMenu = ({
  style,
  showItemsHandler,
  color,
  lineColor,
  showMenuItems,
}) => {
  {
    /*if (showMenuItems) {
    return (
      <div
        className={`burger-menu ${style}`}
        onClick={showItemsHandler}
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="line crossed-line"
          style={{ backgroundColor: lineColor }}
        />
        <div
          className="line crossed-line"
          style={{ backgroundColor: lineColor }}
        />
      </div>
    );
  }
*/
  }

  return (
    <div className={`burger-menu ${style}`} onClick={showItemsHandler}>
      <div className="line" style={{ backgroundColor: "white" }} />
      <div className="line" style={{ backgroundColor: "white" }} />
    </div>
  );
};

HamBurgerMenu.propTypes = {
  showItemsHandler: PropTypes.func.isRequired,
  // color: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.string.isRequired,
  lineColor: PropTypes.string.isRequired,
  showMenuItems: PropTypes.bool.isRequired,
};

export default HamBurgerMenu;
