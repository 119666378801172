import { 
  AuthActions, 
  AmplifyAuthStatus 
} from "./constants.js";

export const authInitialState = {
  error: null,
  currentUser: null,
  loggedIn: false,
  checkingAuth: false,
  authStatus: null,
  lastUpdated: null
};

export const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        loggedIn: true,
        error: null,
        authStatus: AmplifyAuthStatus.signedIn,
        lastUpdated: new Date()
      };
    case AuthActions.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        loggedIn: false,
        error: null,
        authStatus: null,
        lastUpdated: new Date()
      };
    case AuthActions.SIGNUP_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        authStatus: AmplifyAuthStatus.signUp,
        lastUpdated: new Date()
      };
    case AuthActions.CONFIRM_SIGNUP_SUCCESS:
      return {
        ...state,
        error: null,
        authStatus: AmplifyAuthStatus.confirmSignUp,
        lastUpdated: new Date()
      };
    case AuthActions.UPDATE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
        error: null,
        authStatus: AmplifyAuthStatus.profileUpdate,
        lastUpdated: new Date()
      };
    case AuthActions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        loggedIn: true,
        error: null,
        lastUpdated: new Date()
      };
    case AuthActions.SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        lastUpdated: new Date()
      };
    case AuthActions.RESET_AUTH_STATUS:
      return {
        ...state,
        error: null,
        authStatus: null,
        lastUpdated: new Date()
      };
    case AuthActions.AUTH_USER_FOUND:
      return {
        ...state,
        currentUser: action.payload,
        loggedIn: true,
        error: null,
        authStatus: AmplifyAuthStatus.signedIn,
        lastUpdated: new Date()
      };
    case AuthActions.AUTH_NO_USER:
      return {
        ...state,
        currentUser: null,
        loggedIn: false,
        error: null,
        authStatus: 'noUser',
        lastUpdated: new Date()
      };
    case AuthActions.AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        lastUpdated: new Date()
      };      
    default:
      return state;
  }
};
