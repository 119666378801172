import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let hubspotFormSchema = object().shape({
  heading: string().nullable(),
  type: string().nullable(),
  page_name: string().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformHubspotFormData = (data) => {
  // console.log("transformHubspotFormData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      type: null,
      page_name: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.heading;
    hydratedModel.type = data.type;
    hydratedModel.page_name = data.page_name;
    
    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      hubspotFormSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
