module.exports = `fragment markup on Contentstack_markup {
    body_one
    body_two
    global_id
    heading
    section_class
    section_id
    variant
    wrapper_class_one
    wrapper_class_two
    wrapper_id_one
    wrapper_id_two
  }`;