module.exports = {
  query:`allContentstackPressRelease(sort: {order: DESC, fields: [published_on]}) {
    edges {
      node {
        url
        uid
        title
        body
        publish_date
        published_on
        seo {
            ...seo
        }
        updated_by
        updated_at
        created_by
        created_at
        post_id
      }
    }
  }`,
  fragments: ["seo"]
}