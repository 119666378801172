module.exports = `fragment split_screen_compact on Contentstack_split_screen_compact {
    split_screen_compact_styles {
      split_screen_compact_image_alignment
    }
    split_screen_compact_heading
    split_screen_compact_enabled
    split_screen_compact_blocks {
      split_screen_compact_text
      split_screen_compact_image {
        url
        title
        description
      }
    }
  }`;