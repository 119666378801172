module.exports = `fragment store_information on Contentstack_store_information {
    website
    virtual_care_store_information {
      title
      href
    }
    usage
    telephone
    store_information_stash_cash_participant
    store_image {
      url
      id
      internal {
        content
        description
        type
      }
    }
    store_email_address
    status
    share_well_care_well_policy
    pre_registration_store_information {
      title
      href
    }
    nickname_location
    instagram {
      href
      title
    }
    forage_store_information
    facebook {
      href
      title
    }
    directions
    delivery_store_information {
      href
      title
    }
    cnc_card_participant
    about
    future_sunnyside
  }`;