module.exports = `fragment testimonial on Contentstack_testimonial {
    testimonial_enabled
    testimonial_heading
    testimonial_subheading
    testimonial_testimonials {
      testimonial_title
      testimonial_name
      testimonial_message
    }
    testimonial_style_options {
      testimonial_color_options
      testimonial_layout_type
    }
    testimonial_icon {
      # description
      url
      title
    }
  }`;