import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let interstitialSchema = object().shape({
  global_id: string().nullable(),
  title: string().nullable(),
  body: string().nullable(),
  variant: string().nullable(),
  news_banner_config: object().nullable(),
  image: object().nullable(),
  background_color: string().nullable(),
  __digest: string().required(),
});

export const transformInterstitialData = (data) => {
  // console.log('transformInterstitial', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      global_id: null,
      title: null,
      body: null,
      variant: null,
      news_banner_config: null,
      image: null,
      background_color: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.global_id = data.global_id;
    hydratedModel.title = data.title;
    hydratedModel.body = data.body;
    hydratedModel.variant = data.variant;
    hydratedModel.news_banner_config = data.news_banner_config;
    hydratedModel.image = data.image
    hydratedModel.background_color = data.background_color
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      interstitialSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};