module.exports = {
  query: `allContentstackSitePage {
  edges {
    node {
      url
      title
      page_meta {
        ...page_meta
      }
      modular_blocks {
        site_page_site_element {
          ...site_element
        }
        site_page_super_split_screen {
          ...super_split_screen
        }
        site_page_markup {
          ...markup
        }
        site_page_news_banner {
          ...news_banner
        }
        site_page_testimonial {
          ...testimonial
        }
        site_page_statement {
          ...statement
        }
        site_page_icon_links {
          ...icon_links
        }
        site_page_quick_stats {
          ...quick_stats
        }
        site_page_text {
          ...text
        }
        site_page_logo_wall {
          ...logo_wall
        }
        site_page_full_width_media {
          ...full_width_media
        }
        site_page_cards {
          ...cards
        }
        site_page_quote {
          ...quote
        }
        site_page_locations_map {
          ...locations_map
        }
        site_page_all_news {
          ...all_news
        }
        site_page_hubspot_form {
          ...hubspot_form
        }
        site_page_iframe {
          ...inline_iframe
        }
        site_page_latest_news {
          ...latest_news
        }
        site_page_news_banner {
          ...news_banner
        }
        site_page_hero {
          ...hero_banner
        }
        site_page_campaign_banner {
          ...campaign_banner
        }
      }
    }
  }
}`,
  fragments: [
    "page_meta",
    "site_element",
    "super_split_screen",
    "markup",
    "news_banner",
    "testimonial",
    "statement",
    "icon_links",
    "quick_stats",
    "text",
    "logo_wall",
    "full_width_media",
    "cards",
    "quote",
    "locations_map",
    "all_news",
    "hubspot_form",
    "inline_iframe",
    "latest_news",
    "news_banner",
    "hero_banner",
    "seo",
    "campaign_banner",
  ],
};
