import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let allNewsSchema = object().shape({
  global_id: string().nullable(),
  heading: string().nullable(),
  enabled: bool().nullable(),
  variant: string().nullable(),
  inject_data: string().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformAllNewsData = (data) => {
  // console.log("transformAllNewsData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      global_id: null,
      heading: null,
      enabled: null,
      variant: null,
      inject_data: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.global_id = data.global_id;
    hydratedModel.heading = data.heading;
    hydratedModel.variant = data.variant;
    hydratedModel.enabled = data.enabled;
    hydratedModel.inject_data = data.inject_data;

    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      allNewsSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
