module.exports = `fragment interstitial on Contentstack_interstitial {
    news_banner_config {
      enabled
      custom_link_path
      link_to_referenced_content
      image {
        url
        title
        # description
      }
      background_color
      button_background_color
      button_outline_color
      base_64_encoded_image
      news_reference {
        ... on Contentstack_press_release {
          id
          url
          title
        }
        ... on Contentstack_media_coverage {
          id
          url
          title
          source_link {
            href
            title
          }
        }
      }
      campaign_id
      button_label
    }
    title
    variant
    json_config
    global_id
    body
  }`;