const slug = require("slug");

const constants = require("./constants.js");

/**
 * Parse the Node process.env object for environmental variables with a given
 * prefix.
 */
module.exports.getEnvVarsWithPrefix = (prefix, env) => {
  const envVars = Object.keys(process.env)
    .filter((v) => v.indexOf("GHUB_") > -1)
    .reduce((a, v, k) => {
      a.push(v);
      // console.log(v, process.env[v]);
      return a;
    }, []);

  return envVars;
};

/**
 * Slugify a string.
 */
module.exports.getSlug = (string, opts = null) => {
  try {
    if (typeof string !== "string") {
      console.warn(constants.GHUB_TYPE_ERROR_EXPECT_STRING);
      // throw new Error(constants.GHUB_TYPE_ERROR_EXPECT_STRING);
    }
    return slug(string, opts);
  } catch (err) {
    console.warn(err);
  }
};

/**
 * Strip a single slash from the end of a string.
 */
module.exports.stripTrailingSlash = (string = "") => {
  try {
    if (typeof string !== "string") {
      console.warn(constants.GHUB_TYPE_ERROR_EXPECT_STRING);
      // throw new Error(constants.GHUB_TYPE_ERROR_EXPECT_STRING);
    }
    return string?.endsWith("/") ? string.slice(0, -1) : string ?? "";
  } catch (err) {
    console.warn(err);
  }
};

/**
 * Lookies in a set of cookies for Iubenda token and checks validity.
 */
module.exports.checkUserTrackingConsent = (cookies) => {
  let iubCsId;
  const iubCsKeys = cookies ? Object.keys(cookies) : null;

  // @todo: quick memoize will help this
  if (iubCsKeys) {
    iubCsKeys.map((val, key) => {
      if (val && val.indexOf("_iub_cs") === 0) {
        try {
          const cookie = JSON.parse(cookies[val]);
          if (cookie.consent === true) {
            // console.info('Iubenda token valid; user gave tracking consent.');
            return (iubCsId = val);
          }
        } catch (e) {
          console.error("Iubenda cookie not parsed correctly.");
        }
        return null;
      }
    });
  }
  return iubCsId;
};

/**
 * Wrapper for Gatsby loadPage to make it testable
 */
module.exports.loadPage = async () => {
  if (typeof window === "object" && window.___loader) {
    return window.___loader.loadPage;
  }
  return require("../test/fixtures/zip-to-coordinates_802_page-data.json");
};

/**
 * A helper that reads an environmental variable defining the Contentstack
 * environment and returns a sub-site variant.
 */
module.exports.getContentstackEnvironment = () => {
  const contentstackEnv = process.env.GHUB_ENVIRONMENT || "dev";

  if (!contentstackEnv) {
    console.warn(
      "There was an issue parsing the Contentstack environment variable.",
      contentstackEnv
    );
    return null;
  }
  try {
    return contentstackEnv.split("-").slice(1, -1).join("-");
  } catch (err) {
    console.error(err);
  }
};

/**
 * Parse a date.
 */
module.exports.parseDate = (dateString) => {
  const parts = dateString.split("-");
  // note: months are 0-indexed in JavaScript
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

/**
 * Fancy logs.
 */
module.exports.ghubLogger = (() => {
  const isProduction = process.env.GHUB_ENVIRONMENT === "production";
  const style = [
    "background: linear-gradient(#D33106, #571402)",
    "border: 1px solid #3E0E02",
    "color: white",
    "display: block",
    "text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)",
    "box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2)",
    "padding: 5px 10px",
    "line-height: 26px",
    "font-weight: bold",
  ].join(";");

  return (...args) => {
    if (isProduction) {
      return;
    }

    console.log("%c%s", style, "🔍 ", ...args);
  };
})();

/**
 * Get the current hostname. Not for use in SSR environments.
 */
module.exports.getHostname = () => {
  return window.location.hostname === "localhost"
    ? `http://localhost:${window.location.port}`
    : `${window.location.origin}`;
};
