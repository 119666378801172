const stripTrailingSlash = require("../../../helpers").stripTrailingSlash;
const legacyPage = require("./page/index").legacyPage;
const { parse } = require("graphql");
const { print } = require("graphql/language/printer");
const graphqlQuery = require("./graphql/index");
const graphqlFragments = require("./fragments/index");

// i'm not in love with any sort of switch statement like this but eh
const lookupContentType = (path) => {
  let contentType;

  switch (true) {
    case stripTrailingSlash(path).indexOf("/locations/") === 0:
      contentType = "partner_location";
      break;
    case path.indexOf("/news/media-coverage") === 0:
      contentType = "media_coverage";
      break;
    default:
      contentType = legacyPage(process.env.GHUB_CONTENTSTACK_ENVIRONMENT);
      break;
  }

  return contentType;
};

const createGraphqlString = (contentType) => {
  let query = "";
  let fragments = [];
  contentType &&
    contentType.forEach((e) => {
      query += graphqlQuery[e].query || "";
      fragments = [...new Set(fragments.concat(graphqlQuery[e].fragments))];
    });
  return { query, fragments };
};

const createFragments = (fragments) => {
  let fragmentQuery = "";
  fragments &&
    fragments.forEach((e) => {
      fragmentQuery += graphqlFragments[e] || "";
    });
  return fragmentQuery;
};

const fetchAllPagesQuery = (contentType = []) => {
  const { query, fragments } = createGraphqlString(contentType);
  const fragmentsQuery = createFragments(fragments);

  return `query AllPages {
    ${query}
  }
  ${fragmentsQuery}`;
};

const fetchSiteConfigQuery = () => {
  const { query, fragments } = createGraphqlString(["contentstackSiteConfig"]);
  const fragmentsQuery = createFragments(fragments);

  return `query SiteConfig {
    ${query}
  }
  ${fragmentsQuery}`;
};

module.exports = {
  fetchAllPagesQuery,
  fetchSiteConfigQuery,
  lookupContentType,
};
