import React from "react"; // eslint-disable-line

const LeftArrow = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke={color}
    strokeWidth={2}
    role="presentation"
    style={{ height: 23, width: 23 }}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
  </svg>
);

export default LeftArrow;
