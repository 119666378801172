import React, { useState, useEffect, useRef } from "react"; // eslint-disable-line
import PropTypes from "prop-types";
import HamburgerMenu from "./hamburgerMenu";
import "./Style.scss";
import Sidebar from "../Navbar/Sidebar";

const MenuBar = ({
  data,
  backgroundColor,
  textColor,
  iconColor,
  hamBurgerClassName,
  className,
  animation,
  menuContainerWidth,
  onClick,
  storeData,
  posthog,
}) => {
  const [showMenuItems, changeShowMenuItems] = useState(false);
  const menubarRef = useRef(null);

  function handleClickOutside(event, ref) {
    if (ref.current && !ref.current.contains(event.target)) {
      changeShowMenuItems(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event, menubarRef)
    );
    return () => {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, menubarRef)
      );
    };
  });

  const showItemsHandler = (event) => {
    event.stopPropagation();
    changeShowMenuItems(!showMenuItems);
  };
  return (
    <div
      className={`menu ${className}`}
      ref={menubarRef}
      style={{ display: "inline-block" }}
    >
      <HamburgerMenu
        showItemsHandler={showItemsHandler}
        // color={backgroundColor}
        style={hamBurgerClassName}
        lineColor={iconColor}
        showMenuItems={showMenuItems}
      />
      <Sidebar
        isOpen={showMenuItems}
        storeData={storeData}
        data={data}
        onClose={() => {
          changeShowMenuItems(false);
        }}
        posthog={posthog}
      />
    </div>
  );
};

MenuBar.defaultProps = {
  data: [
    {
      value: "No data found",
    },
  ],
  animation: ["slideIn", "slideOut"],
  backgroundColor: "#4dccc4",
  textColor: "white",
  iconColor: "white",
  menuContainerWidth: 300,
  className: "",
  hamBurgerClassName: "",
  onClick: null,
};

MenuBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  animation: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  menuContainerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hamBurgerClassName: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuBar;
