module.exports = `fragment full_width_media on Contentstack_full_width_media {
    full_width_media_video_poster {
      url
      title
      # description
    }
    full_width_media_video {
      url
      # description
      title
    }
    full_width_media_title
    full_width_media_enabled
    full_width_media_image {
      url
      title
      # description
    }
  }`;