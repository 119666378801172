const allContentstackPartnerLocation = require("./allContentstackPartnerLocation");
const allContentstackPressRelease = require("./allContentstackPressRelease");
const allContentstackMediaCoverage = require("./allContentstackMediaCoverage");
const allContentstackPage = require("./allContentstackPage");
const allContentstackSitePage = require("./allContentstackSitePage");
const allContentstackCampaign = require("./allContentstackCampaign");
const contentstackSiteConfig = require("./contentstackSiteConfig");

module.exports = {
  allContentstackPartnerLocation,
  allContentstackPressRelease,
  allContentstackMediaCoverage,
  allContentstackPage,
  allContentstackSitePage,
  allContentstackCampaign,
  contentstackSiteConfig,
};
