// layout boilerplate
export const SET_LAYOUT_DATA = "@@col-care/hydrate/SET_LAYOUT_DATA";
export const doSetLayoutData = (value) => {
  return (dispatch, getState) => {
    dispatch(setLayoutData(value));
  };
};
const setLayoutData = (value) => {
  return {
    type: SET_LAYOUT_DATA,
    payload: value,
  };
};

// location boilerplate
export const SET_LOCATION_DATA = "@@col-care/hydrate/SET_LOCATION_DATA";
export const doSetLocationData = (value) => {
  return (dispatch, getState) => {
    dispatch(setLocationData(value));
  };
};
const setLocationData = (value) => {
  return {
    type: SET_LOCATION_DATA,
    payload: value,
  };
};

// post boilerplate
export const SET_POST_DATA = "@@col-care/hydrate/SET_POST_DATA";
export const doSetPostData = (value) => {
  return (dispatch, getState) => {
    dispatch(setPostData(value));
  };
};
const setPostData = (value) => {
  return {
    type: SET_POST_DATA,
    payload: value,
  };
};

// site config boilerplate
export const SET_SITE_CONFIG_DATA = "@@col-care/hydrate/SET_SITE_CONFIG_DATA";
export const doSetSiteConfigData = (value) => {
  return (dispatch, getState) => {
    dispatch(setSiteConfigData(value));
  };
};
const setSiteConfigData = (value) => {
  return {
    type: SET_SITE_CONFIG_DATA,
    payload: value,
  };
};

// build info boilerplate
export const SET_BUILD_INFO_DATA = "@@col-care/hydrate/SET_BUILD_INFO_DATA";
export const doSetBuildInfoData = (value) => {
  return (dispatch, getState) => {
    dispatch(setBuildInfoData(value));
  };
};
const setBuildInfoData = (value) => {
  return {
    type: SET_BUILD_INFO_DATA,
    payload: value,
  };
};

// databag boilerplate
export const SET_DATABAG_DATA = "@@col-care/hydrate/SET_DATABAG_DATA";
export const doSetDatabagData = (value) => {
  return (dispatch, getState) => {
    dispatch(setDatabagData(value));
  };
};
const setDatabagData = (value) => {
  return {
    type: SET_DATABAG_DATA,
    payload: value,
  };
};

export default {
  SET_LAYOUT_DATA: SET_LAYOUT_DATA,
  SET_LOCATION_DATA: SET_LOCATION_DATA,
  SET_POST_DATA: SET_POST_DATA,
  SET_SITE_CONFIG_DATA: SET_SITE_CONFIG_DATA,
  SET_BUILD_INFO_DATA: SET_BUILD_INFO_DATA,
  SET_DATABAG_DATA: SET_DATABAG_DATA,
  doSetLayoutData: doSetLayoutData,
  doSetLocationData: doSetLocationData,
  doSetPostData: doSetPostData,
  doSetSiteConfigData: doSetSiteConfigData,
  doSetBuildInfoData: doSetBuildInfoData,
  doSetDatabagData: doSetDatabagData,
};
