import { kebabCase } from "lodash";
import { stockProductImages } from "../providers/iheartjane/jane-embed/index";
import queryString from "query-string";
import moment from "moment";

// moment.tz.setDefault("America/Denver");

export const isWindow = typeof window === "object";

export const janeFallBackImage = (hit) => {
  // console.log("janeFallBackImage", hit);
  const {
    image_urls,
    type,
    brand,
    name,
    photo,
    custom_product_type,
    category,
  } = hit || {};

  const image = image_urls?.[0] || photo;

  if (!image) {
    console.warn("Image missing for product", name);
    try {
      const subtype =
        stockProductImages?.[kebabCase(type)] &&
        Object.keys(stockProductImages[kebabCase(type)])?.[0];

      if (typeof stockProductImages[kebabCase(type)] === "string") {
        return stockProductImages[kebabCase(type)];
      }

      const typeObj =
        stockProductImages?.[kebabCase(type)] ??
        stockProductImages?.[kebabCase(custom_product_type)];

      if (typeObj?.[kebabCase(brand)]) {
        return typeObj?.[kebabCase(brand)];
      } else if (typeObj?.[subtype]) {
        return typeObj?.[subtype];
      } else if (typeObj?.[kebabCase(category)]) {
        return typeObj?.[kebabCase(category)];
      } else if (type) {
        console.warn("brandType fallback image is missing", {
          image,
          type,
          brand,
          name,
        });
        return;
      }
    } catch (err) {
      console.warn("type and brandType fallback image are missing", {
        image,
        type,
        brand,
        name,
      });
    }
  }

  return image;
};

export const mappedDiscountProductIds = (currentHit, specialsData) => {
  if (
    currentHit?.applicable_special_types &&
    currentHit.applicable_special_types[0]
  ) {
    const types = currentHit?.applicable_special_types[0];

    return (
      mappedDiscountProductIdsMethods[types](currentHit, specialsData) || []
    );
  }
};

const mappedDiscountProductIdsMethods = {
  product: (currentHit, specialsData) => {
    let discountProducts = [];
    specialsData.data.rec.map((v) => {
      // if (currentHit?.applicable_special_types[0]) {
      if (
        currentHit?.applicable_special_ids &&
        currentHit.applicable_special_ids.includes(v.id) &&
        isActiveDateRange(v.start_date, v.end_date)
      ) {
        if (v?.conditions?.product?.included_product_ids) {
          discountProducts = discountProducts.concat(
            v?.conditions?.product?.included_product_ids || []
          );
        }
      }
      // }
    });

    return discountProducts;
  },
  bundle: (currentHit, specialsData) => {
    let discountProducts = [];
    specialsData.data.rec.map((v) => {
      if (
        currentHit?.applicable_bundle_special_ids?.discount &&
        currentHit.applicable_bundle_special_ids.discount.includes(v.id) &&
        isActiveDateRange(v.start_date, v.end_date)
      ) {
        discountProducts = discountProducts.concat(
          v?.conditions?.bundle?.dependent?.included_product_ids || []
        );
      }
    });

    return discountProducts;
  },
};

// @todo: actually client load should stop breaking things and become a feature
export const determineAutoLoad = (
  cookies,
  location,
  disableClientFetch,
  allowedPages
) => {
  const isDev = process.env.GHUB_CONTENTSTACK_ENVIRONMENT === "tgs_dev";

  if (!isDev) {
    // console.log("Client fetch disabled (rule 1)");
    return false;
  }

  const parsed = queryString.parse(location.search);
  if (
    disableClientFetch ||
    cookies.cookies?.dcf == "true" ||
    parsed.disableClientFetch === "true"
  ) {
    // isDev && console.log('Client fetch disabled (rule 3)');
    return false;
  }
  if (
    allowedPages &&
    allowedPages.filter((e) => e.url === location.pathname).length
  ) {
    // isDev && console.log('Client fetch enabled (rule 2)');
    return true;
  }

  // return true;
};

/**
 * Time Zone - US Mountain Time (GMT-7)
 start_date = null , end_date=null => Visible
 start_date = present , end_date=null => Check start date less than current date 
 start_date = less , end_date=present => Check start date less than current date AND end_date is greater than equal to current date 
 * */

export const isActiveDateRange = (start_date = null, end_date = null) => {
  moment.updateLocale("en", {
    // Use a fixed offset of -7 hours for Mountain Time (GMT-7)
    // You may need to adjust this offset based on DST (Daylight Saving Time)
    // This is a simplified approach and may not accurately handle all cases
    // For more accurate timezone handling, consider using a dedicated library like moment-timezone
    utcOffset: -7 * 60,
  });

  if (start_date == null && end_date == null) return true;

  const startDate = moment(start_date);
  const endDate = moment(end_date);
  const currentDate = moment();

  const startDateIsValid =
    startDate.isSame(currentDate, "day") ||
    startDate.isBefore(currentDate, "day") ||
    start_date == null;

  const endDateIsValid =
    endDate.isSame(currentDate, "day") ||
    !startDate.isBefore(currentDate, "day") ||
    end_date == null;

  return startDateIsValid && endDateIsValid;
};

export const isSpecialsBrandsOrProductsAvailable = (
  specialsConditions,
  specialType
) => {
  const getTypeData = specialsConditions?.[specialType];

  const productIds =
    specialType === "bundle"
      ? getTypeData?.dependent?.included_product_ids
      : getTypeData.included_product_ids;

  const hasSpecialBrandOrProduct =
    !!productIds?.length || !!specialsConditions?.brands?.length;

  return (
    !!specialsConditions?.product?.brands?.length ||
    !!specialsConditions?.product?.included_product_ids?.length ||
    hasSpecialBrandOrProduct
  );
};

export const capturePosthogLink = ({
  eventName,
  title,
  to,
  from = window.location.pathname,
  posthog,
  databag,
  details,
  ...other
}) => {
  if (posthog?.capture) {
    const databagObj =
      databag || details ? { databag: { ...databag, ...other } } : null;
    console.info(
      `Posthog event -> ${eventName}`,
      title ? "\nTitle: " + title : "",
      `${to ? `\nTo: ${to}` : ""}`,
      `${from ? `\nFrom: ${from}` : ""}`,
      `${
        !!databagObj && !!Object.keys(databagObj)?.length
          ? "\n" + JSON.stringify({ ...databagObj })
          : ""
      }`
    );
    posthog?.capture(eventName, {
      $EventDetails: {
        type: title,
        to: to,
        from: from,
        databagObj,
      },
    });
  }
};

export const capturePosthogEvent = ({
  eventName,
  title = "",
  details = null,
  databag = null,
  posthog,
}) => {
  if (posthog?.capture) {
    const databagObj =
      databag || details ? { databag: { ...databag, ...details } } : null;

    console.info(
      `Posthog event -> ${eventName}`,
      title ? "\nTitle: " + title : "",
      !!databagObj ? "\nDatabag:\n" + JSON.stringify({ ...databagObj }) : ""
    );

    posthog?.capture(eventName, {
      $EventDetails: {
        type: title,
        databagObj,
      },
    });
  }
};

export const getCurrentCampaigns = (locationId, campaigns) => {
  const now = new Date();
  const segments = locationId?.split("-");
  const state = segments?.[0];
  if (!campaigns) {
    return [];
  }
  return campaigns?.reduce((acc, campaign) => {
    const startDate = new Date(campaign.start_date);
    const endDate = new Date(campaign.end_date);
    if (now >= startDate && now <= endDate) {
      const matchingLocations = campaign.locations.filter((location) =>
        location.reference.some((ref) => ref.location_id === locationId)
      );
      if (matchingLocations.length > 0) {
        const modifiedCampaign = { ...campaign, locations: matchingLocations };
        acc.push(modifiedCampaign);
      }
      const matchingMarkets = campaign.markets.filter((market) => {
        return market.reference.some((ref) => ref.code === state);
      });
      if (matchingMarkets.length > 0) {
        const modifiedCampaign = { ...campaign, markets: matchingMarkets };
        acc.push(modifiedCampaign);
      }
    }
    return acc;
  }, []);
};
