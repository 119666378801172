export const specialPriceKeys = [
  "special_price_gram",
  "special_price_two_gram",
  "special_price_eighth_ounce",
  "special_price_quarter_ounce",
  "special_price_half_ounce",
  "special_price_ounce",
  "special_price_half_gram",
  "special_price_each",
];

export const posthogEvents = {
  STORE_NAVIGATION: "Store navigation",
  EXTERNAL_NAVIGATION: "External navigation",
  NAVIGATION: "Navigation",
  HERO_BLOCK_ENGAGEMENT: "Hero block engagement",
  SPECIALS_ACCORDION_OPENED: "Specials accordion opened",
  SPECIALS_ACCORDION_CLOSED: "Specials accordion closed",
  CART_UPDATED: "Cart updated",
  PRODUCT_TILE_DRAWER_OPENED: "Product tile drawer opened",
  PRODUCT_TILE_DRAWER_CLOSED: "Product tile drawer closed",
  ITEM_QUANTITY_INCREASED: "Item quantity increased",
  ITEM_QUANTITY_DECREASED: "Item quantity decreased",
  ITEM_ADDED_TO_CART: "Item added to cart",
  ITEM_REMOVED_FROM_CART: "Item removed from cart",
  SPECIALS_LINK_CLICKED: "Specials link clicked",
  LOCATION_LINK_CLICKED: "Location link clicked",
  PRODUCT_DETAILS_LINK_CLICKED: "Product details link clicked",
  HEADER_NAVIGATION: "Header navigation",
  HEADER_MOBILE_NAVIGATION: "Header mobile navigation",
  FOOTER_NAVIGATION: "Footer navigation",
  // FOOTER_MOBILE_NAVIGATION: "footer mobile navigation",
  ADD_TO_CART_MODAL_OPENED: "Add-to-cart modal opened",
  ADD_TO_CART_MODAL_CLOSED: "Add-to-cart modal closed",
  CARD_BLOCK_ENGAGEMENT: "Card block engagement",
  SPLIT_SCREEN_BLOCK_ENGAGEMENT: "Split screen block engagement",
  SPLIT_SCREEN_TGS_BLOCK_ENGAGEMENT: "Split screen TGS block engagement",
  JANE_CHECKOUT_SUCCESS: "Jane checkout success",
  JANE_CHECKOUT_ITEM_REMOVED: "Jane checkout item removed",
  JANE_CHECKOUT_ITEM_ADDED: "Jane checkout item added",
  JANE_CHECKOUT_ITEM_UPDATED: "Jane checkout item updated",
  INSTANTSEARCH_INTERACTION: "Instantsearch interaction",
  PRODUCT_FINDER: "Product finder",
  PRODUCT_LOCATIONS: "Product locations",
  LOCATION_MAP: "Location map",
  LOCATION_CHANGE_MODAL_OPENED: "Change Locations Modal opened",
  LOCATION_CHANGE_MODAL_CLOSED: "Change Locations Modal closed",
  TOGGLE_STORE_SELECTOR: "Toggle store selector",
  STORE_SELECTOR_NAVIGATION: "Store selector navigation",
  SOCIAL_NAVIGATION: "Social navigation",
  SITEMAP_NAVIGATION: "Sitemap navigation",
  JANE_NAVIGATION: "Jane checkout navigation",
  PRESS_NAVIGATION: "Press release link clicked",
  MEDIA_COVERAGE_NAVIGATION: "Media coverage link clicked",
  CAMPAIGN_BANNER_INTERACTION: "Campaign banner interaction",
};

export const instantSearchSortOptions = {
  "menu-products-production": "A - Z",
  "menu-products-by-price-production": "Price (asc)",
  "menu-products-by-price-desc-production": "Price (desc)",
};

export const statesWithStores = {
  AZ: "Arizona",
  CA: "California",
  CO_MM: "Colorado (Medicine Man)",
  CO_TGS: "Colorado (TGS)",
  DE: "Delaware",
  FL: "Florida",
  IL: "Illinois",
  MD: "Maryland",
  MA: "Massachusetts",
  NJ: "New Jersey",
  NY: "New York",
  OH: "Ohio",
  PA: "Pennsylvania",
  UT: "Utah",
  VA: "Virginia",
  WV: "West Virginia",
  DC: "Washington DC",
};
