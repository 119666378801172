import React from "react"; // eslint-disable-line

const RightArrowIcon = ({ color, className, height }) => (
  <svg
    width="8"
    height="18"
    viewBox="0 0 8 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 16.4791L7 9.09445L1 1.70984"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrowIcon;
