import React from "react";
import { useScript } from "../effects/useScript";

const AppScripts = (props) => {
  // const isDev = process.env.GHUB_CONTENTSTACK_ENVIRONMENT === 'tgs_dev'

  const accessibeJs = `
    (function(){
        var s = document.createElement('script'),
        e = ! document.body ? document.querySelector('head') : document.body;
        s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        s.async = true;
        s.onload = function(){
            acsbJS.init({
                statementLink    : '',
                footerHtml       : '',
                hideMobile       : false,
                hideTrigger      : false,
                language         : 'en',
                position         : 'right',
                leadColor        : '#146FF8',
                triggerColor     : '#146FF8',
                triggerRadius    : '50%',
                triggerPositionX : 'left',
                triggerPositionY : 'bottom',
                triggerIcon      : 'people',
                triggerSize      : 'medium',
                triggerOffsetX   : 20,
                triggerOffsetY   : 20,
                mobile           : {
                    triggerSize      : 'small',
                    triggerPositionX : 'left',
                    triggerPositionY : 'bottom',
                    triggerOffsetX   : 20,
                    triggerOffsetY   : 20,
                    triggerRadius    : '100%'
                }
            });
        };
        e.appendChild(s);
    }());
  `;

  useScript({
    type: "script",
    id: "accessibe-embed",
    innerHTML: accessibeJs,
  });

  const twitterJs = `
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config','ofxar');
  `;

  useScript({
    type: "script",
    id: "twitter-embed",
    innerHTML: twitterJs,
  });

  // @todo: what is prod
  // useScript({
  //   url: "https://t.contentsquare.net/uxa/ccd4b76175667.js",
  //   id: "contentsquare-embed",
  //   setAsync: true,
  // });

  return <></>;
};

export default AppScripts;
