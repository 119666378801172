import React from "react";
import PropTypes from "prop-types";

import * as ButtonStyles from "./button.module.css";

const ButtonElement = ({
  theme,
  onClick,
  disabled,
  style,
  icon,
  label,
  type,
  id,
}) => {
  const buttonStyles = ButtonStyles.default || ButtonStyles;

  return (
    <button
      className={`${buttonStyles.button} ${buttonStyles[theme]}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
      id={id}
      // type={ type || "button" } // @todo: the lack of a default type may be causing some issues
    >
      {icon} {label}
    </button>
  );
};

ButtonElement.propTypes = {
  theme: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

ButtonElement.defaultProps = {
  theme: "transparent",
  onClick: undefined,
  disabled: false,
  icon: null,
  id: "",
};

ButtonElement.displayName = "ButtonElement";

export default ButtonElement;
