import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let scriptEmbedSchema = object().shape({
  type: string().nullable(),
  argument: string().nullable(),
  json_data: object().nullable(),
  __digest: string().required(),
});

export const transformScriptEmbedData = (data) => {
  // console.log('transformScriptEmbed', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      type: null,
      argument: null,
      json_data: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.type = data.type;
    hydratedModel.argument = data.argument;
    hydratedModel.json_data = data.json_data;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      scriptEmbedSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};