import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { janeFallBackImage } from "../../utilities/index";
import LazyLoadingImage from "../lazy-loading-image";

const HitImage = ({ className = "", hit, preload }) => {
  if (!hit) return null;
  const [src, setSrc] = useState(janeFallBackImage(hit));

  const name = hit?.name || "";

  //const handleError = ({ currentTarget }) => {
  const handleError = () => {
    const fallbackImg = janeFallBackImage({
      ...hit,
      image_urls: null,
      photo: null,
    });

    //currentTarget.src = fallbackImg;
    currentTarget.src = fallbackImg;
  };

  const image = hit?.image_urls?.[0] || hit?.photo;

  return (
    // <img className={className} alt={name} src={image} onError={handleError} />
    <>
      {preload === true && (
        <Helmet>
          <link rel="preload" as="image" href={src}></link>
        </Helmet>
      )}
      <LazyLoadingImage
        alt={name}
        src={src}
        width={"100%"}
        onError={handleError}
        className={className}
      />
    </>
  );
};

export default HitImage;
