import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let markupSchema = object().shape({
  global_id: string().nullable(),
  heading: string().nullable(),
  variant: string(),
  section_class: string(),
  section_id: string(),
  body_one: string(),
  body_two: string(),
  wrapper_class_one: string().nullable(),
  wrapper_id_one: string().nullable(),
  wrapper_class_two: string().nullable(),
  wrapper_id_two: string().nullable(),
  __digest: string().required(),
});

export const transformMarkupData = (data) => {
  // console.log('transformMarkup', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      global_id: null,
      heading: null,
      variant: null,
      body_one: null,
      body_two: null,
      section_class: null,
      section_id: null,
      wrapper_class_one: null,
      wrapper_id_one: null,
      wrapper_class_two: null,
      wrapper_id_two: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.global_id = data.global_id;
    hydratedModel.heading = data.heading;
    hydratedModel.variant = data.variant;
    hydratedModel.section_class = data.section_class;
    hydratedModel.section_id = data.section_id;
    hydratedModel.body_one = data.body_one;
    hydratedModel.body_two = data.body_two;
    hydratedModel.wrapper_class_one = data.wrapper_class_one;
    hydratedModel.wrapper_id_one = data.wrapper_id_one;
    hydratedModel.wrapper_class_two = data.wrapper_class_two;
    hydratedModel.wrapper_id_two = data.wrapper_id_two;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      markupSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};