module.exports = `fragment icon_links on Contentstack_icon_links {
    icon_links_links {
      icon_links_icon {
        title
        url
      }
      icon_links_link {
        href
        title
      }
    }
  }`;