import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let siteElementSchema = object().shape({
  variant: string().required(),
  careers_widget: object().shape({ 
    global_id: string().nullable()
  }),
  iframe: object().shape({ 
    global_id: string().nullable(),
    height: string().nullable(),
    src: string().nullable()
  }),
  product_finder: object().shape({ 
    global_id: string().nullable(),
    mount_point: string().nullable(),
    heading: string().nullable(),
    subheading: string().nullable(),
    product_brands: object().nullable(),
    map_settings: object().nullable(),
  }),
  tabs: object().shape({ 
    global_id: string().nullable(),
    variant: string().nullable(),
  }),
  __digest: string().required(),
});

export const transformSiteElementData = (data) => {
  // console.log('transformSiteElement', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      variant: null,
      careers_widget: {
        global_id: null
      },
      iframe: {
        global_id: null,
        height: null,
        src: null,
      },
      product_finder: {
        subheading: null,
        mount_point: null,
        heading: null,
        global_id: null,
        product_brands: {
          title: null,
          link: {
            title: null,
            href: null,
          },
          category: null,
        },
        map_settings: {
          zoom: null,
          lat: null,
          lng: null
        }
      },
      tabs: {
        global_id: null,
        variant: null
      },
      __digest: null,
    });

    // map the field names here
    hydratedModel.variant = data.variant;
    
    hydratedModel.careers_widget.global_id = data.careers_widget.global_id;

    hydratedModel.iframe.global_id = data.iframe.global_id;
    hydratedModel.iframe.height = data.iframe.height;
    hydratedModel.iframe.src = data.iframe.src;

    if (data.product_finder) {
      hydratedModel.product_finder.global_id = data.product_finder.global_id;
      hydratedModel.product_finder.mount_point = data.product_finder.mount_point;
      hydratedModel.product_finder.inject_data = 'locations';
      hydratedModel.product_finder.heading = data.product_finder.heading;
      hydratedModel.product_finder.subheading = data.product_finder.subheading;
      hydratedModel.product_finder.product_brands = data.product_finder.product_brands;
      hydratedModel.product_finder.map_settings= data.product_finder.map_settings
    }

    hydratedModel.tabs.global_id = data.tabs.global_id;
    hydratedModel.tabs.variant = data.tabs.variant;

    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      siteElementSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
