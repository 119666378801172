module.exports = `fragment split_screen_links on Contentstack_split_screen_links {
    split_screen_links_heading
    split_screen_links_enabled
    split_screen_links_styles {
      split_screen_links_color_options
      split_screen_links_alignment
    }
    split_screen_links_blocks {
      split_screen_links_row_heading
      split_screen_links_links {
        href
        title
      }
      split_screen_links_image {
        url
        title
        description
      }
      split_screen_text
    }
  }`;