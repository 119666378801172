import React from "react"; // eslint-disable-line
import PropTypes from "prop-types";

const GreenCheck = ({ color, className, height }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    className={className}
  >
    <rect width="33.9004" height="33.9004" rx="16.9502" fill="#7EBD5A" />
    <path
      d="M10.1504 17.5254L14.2104 22.262"
      stroke="white"
      strokeWidth="1.18949"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.21 22.2627L23.6833 12.7894"
      stroke="white"
      strokeWidth="1.18949"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GreenCheck;

GreenCheck.defaultProps = {
  className: "",
};

GreenCheck.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
};
