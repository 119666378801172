export const AuthActions = {
  SET_AUTH_STATUS: '@@cognito/AUTH/SET_AUTH_STATUS',
  LOGIN_SUCCESS: '@@cognito/AUTH/LOGIN_SUCCESS',
  LOGOUT_SUCCESS: '@@cognito/AUTH/LOGOUT_SUCCESS',
  SIGNUP_SUCCESS: '@@cognito/AUTH/SIGNUP_SUCCESS',
  CONFIRM_SIGNUP_SUCCESS: '@@cognito/AUTH/CONFIRM_SIGNUP_SUCCESS',
  UPDATE_ATTRIBUTES_SUCCESS: '@@cognito/AUTH/UPDATE_ATTRIBUTES_SUCCESS',
  FORGOT_PASSWORD_SUCCESS: '@@cognito/AUTH/FORGOT_PASSWORD_SUCCESS',
  SET_CURRENT_USER: '@@cognito/AUTH/SET_CURRENT_USER',
  AUTH_NO_USER: '@@cognito/AUTH/AUTH_NO_USER',
  AUTH_USER_FOUND: '@@cognito/AUTH/AUTH_USER_FOUND',
  SET_AUTH_ERROR: '@@cognito/AUTH/SET_AUTH_ERROR',
  RESET_AUTH_STATUS: '@@cognito/AUTH/RESET_AUTH_STATUS',
  LOGIN_REQUEST: '@@cognito/AUTH/LOGIN_REQUEST',
  LOGOUT_REQUEST: '@@cognito/AUTH/LOGOUT_REQUEST',
  SIGNUP_REQUEST: '@@cognito/AUTH/SIGNUP_REQUEST',
  CONFIRM_SIGNUP_REQUEST: '@@cognito/AUTH/CONFIRM_SIGNUP_REQUEST',
  UPDATE_ATTRIBUTES_REQUEST: '@@cognito/AUTH/UPDATE_ATTRIBUTES_REQUEST',
  FORGOT_PASSWORD_REQUEST: '@@cognito/AUTH/FORGOT_PASSWORD_REQUEST',
  RESET_PASSWORD_REQUEST: '@@cognito/AUTH/RESET_PASSWORD_REQUEST',
  REMEMBER_DEVICE_REQUEST: '@@cognito/AUTH/REMEMBER_DEVICE_REQUEST',
  FORGET_DEVICE_REQUEST: '@@cognito/AUTH/FORGET_DEVICE_REQUEST',
  RESEND_VERIFICATION_REQUEST: '@@cognito/AUTH/RESEND_VERIFICATION_REQUEST'
};

export const AuthState = {
  error: 'error',
  userError: 'userError',
  currentUser: 'currentUser',
  cognitoUser: 'cognitoUser',
  loggedIn: 'loggedIn',
  checkingAuth: 'checkingAuth',
  authStatus: 'authStatus'
};

export const AmplifyAuthStatus = {
  signIn: 'signIn',
  signUp: 'signUp',
  confirmSignIn: 'confirmSignIn',
  confirmSignUp: 'confirmSignUp',
  forgotPassword: 'forgotPassword',
  verifyContact: 'verifyContact',
  signedIn: 'signedIn',
  resetPassword: 'resetPassword',
  requireNewPassword: 'requireNewPassword',
  mfaRequired: 'requireNewPassword',
  profileUpdate: 'profileUpdate'
};

export const AmplifyAuthExceptions = {
  UsernameExistsException: 'UsernameExistsException',
  NotAuthorizedException: 'NotAuthorizedException',
  CodeMismatchException: 'CodeMismatchException',
  UserNotFoundException: 'UserNotFoundException',
  InvalidPasswordException: 'InvalidPasswordException',
  InvalidParameterException: 'InvalidParameterException',
  ExpiredCodeException: 'ExpiredCodeException',
  TooManyRequestsException: 'TooManyRequestsException',
  LimitExceededException: 'LimitExceededException',
  UserNotConfirmedException: 'UserNotConfirmedException',
  PasswordResetRequiredException: 'PasswordResetRequiredException',
  MFAMethodNotFoundException: 'MFAMethodNotFoundException'
};