const legacyPage = module.exports.legacyPage = (site_env) => {
  switch (true) {
    case process.env.GHUB_CONTENTSTACK_ENVIRONMENT.indexOf('comms_') > -1:
      return 'Page'
    case process.env.GHUB_CONTENTSTACK_ENVIRONMENT.indexOf('hedy_') > -1:
      return 'Page'
    case process.env.GHUB_CONTENTSTACK_ENVIRONMENT.indexOf('press_') > -1:
      return 'Page'
    default: 
      return 'site_page'
  }
}