const all_news = require("./all_news");
const brands = require("./brands");
const campaign_banner = require("./campaign_banner");
const cards = require("./cards");
const full_width_media = require("./full_width_media");
const hero_banner = require("./hero_banner");
const hubspot_form = require("./hubspot_form");
const icon_links = require("./icon_links");
const inline_iframe = require("./inline_iframe");
const interstitial = require("./interstitial");
const latest_news = require("./latest_news");
const location_brand = require("./location_brand");
const locations_map = require("./locations_map");
const logo_wall = require("./logo_wall");
const markup = require("./markup");
const news_banner = require("./news_banner");
const page_meta = require("./page_meta");
const product_panel = require("./product_panel");
const quick_stats = require("./quick_stats");
const quote = require("./quote");
const site_element = require("./site_element");
const social_media = require("./social_media");
const split_screen_compact = require("./split_screen_compact");
const split_screen_contained = require("./split_screen_contained");
const split_screen_icons = require("./split_screen_icons");
const split_screen_links = require("./split_screen_links");
const split_screen_standard = require("./split_screen_standard");
const statement = require("./statement");
const store_address = require("./store_address");
const store_hours = require("./store_hours");
const store_information = require("./store_information");
const sub_site_exclude = require("./sub_site_exclude");
const sub_site_variant = require("./sub_site_variant");
const super_split_screen = require("./super_split_screen");
const testimonial = require("./testimonial");
const text = require("./text");
const vendor_integrations = require("./vendor_integrations");
const seo = require("./seo");

module.exports = {
  all_news,
  brands,
  campaign_banner,
  cards,
  full_width_media,
  hero_banner,
  hubspot_form,
  icon_links,
  inline_iframe,
  interstitial,
  latest_news,
  location_brand,
  locations_map,
  logo_wall,
  markup,
  news_banner,
  page_meta,
  product_panel,
  quick_stats,
  quote,
  site_element,
  social_media,
  split_screen_compact,
  split_screen_contained,
  split_screen_icons,
  split_screen_links,
  split_screen_standard,
  statement,
  store_address,
  store_hours,
  store_information,
  sub_site_exclude,
  sub_site_variant,
  super_split_screen,
  testimonial,
  text,
  vendor_integrations,
  seo,
};
