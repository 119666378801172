import { string, object, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";
import { transformTestimonialBlockData } from "./block";

export let testimonialSchema = object().shape({
  title: string(),
  text: string().nullable(),
  layout: string().nullable(),
  heading: string().nullable(),
  subheading: string().nullable(),
  icon: string().nullable(),
  color: string().nullable(),
  block: array().nullable(),
  __digest: string().required(),
});

export const transformTestimonialData = (data) => {
  // console.log("transformTestimonialData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      title: null,
      text: null,
      icon: null,
      heading: null,
      subheading: null,
      color: null,
      layout: null,
      block: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.icon = data.testimonial_icon
      ? data.testimonial_icon.url
      : null;
    hydratedModel.subheading = data.testimonial_subheading;
    hydratedModel.heading = data.testimonial_heading;
    hydratedModel.title = data.testimonial_title;
    hydratedModel.layout =
      data.testimonial_style_options.testimonial_layout_type;
    hydratedModel.color =
      data.testimonial_style_options.testimonial_color_options;
    hydratedModel.__digest = md5(JSON.stringify(data));
    hydratedModel.block = data.testimonial_testimonials.reduce((acc, block) => {
      acc.push(transformTestimonialBlockData(block));
      return acc;
    }, []);
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      testimonialSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

// Page_Testimonial {
//   testimonial_testimonials {
//     testimonial_message
//     testimonial_name
//     testimonial_title
//   }
//   testimonial_subheading
//   testimonial_style_options {
//     testimonial_layout_type
//     testimonial_color_options
//   }
//   testimonial_icon {
//     url
//     title
//     description
//   }
//   testimonial_heading
//   testimonial_enabled
// }
