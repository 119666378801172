import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let iconLinksSchema = object().shape({
  links_icon: array().nullable(),
  enabled: bool().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformIconLinksData = (data) => {
  // console.log("transformIconLinksData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      links_icon: null,
      enabled: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.links_icon = data.icon_links_links
      ? data.icon_links_links
      : null;
    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      iconLinksSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
