import actions from "./actions.js";

export const hydrateInitialState = {
  layoutData: null,
  locationData: null,
  postData: null,
  siteConfigData: null,
  buildInfoData: null,
  databagData: null,
};

export const hydrateReducer = (state = hydrateInitialState, action) => {
  switch (action.type) {
    case actions.SET_LAYOUT_DATA:
      state.layoutData = action.payload;
      return state;
    case actions.SET_LOCATION_DATA:
      state.locationData = Object.assign(state.locationData, action.payload);
      return state;
    case actions.SET_POST_DATA:
      state.postData = action.payload;
      return state;
    case actions.SET_SITE_CONFIG_DATA:
      state.siteConfigData = action.payload;
      return state;
    case actions.SET_BUILD_INFO_DATA:
      state.buildInfoData = action.payload;
      return state;      
    case actions.SET_DATABAG_DATA:
      state.databagData = Object.assign(state.databagData, action.payload);
      return state;           
    default:
      return state;
  }
};
