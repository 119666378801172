import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let splitScreenLinksBlockSchema = object().shape({
  image: string().nullable(),
  heading: string().nullable(),
  links: array().nullable(),
  text: string().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenLinksBlockData = (data, legacy = true) => {
  // console.log('transformSplitScreenLinksBlock', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      image: null,
      heading: null,
      links: null,
      text: null,
      __digest: null,
    });

    // map the field names here
    if (legacy) {
      hydratedModel.heading = data.split_screen_links_row_heading;
      hydratedModel.text = data.split_screen_text;
      hydratedModel.image = data.split_screen_links_image ? data.split_screen_links_image.url : null;
      hydratedModel.links = data.split_screen_links_links;
    }
    else {
      hydratedModel.heading = data.super_split_screen_split_screen_links_row_heading;
      hydratedModel.text = data.super_split_screen_split_screen_text;
      hydratedModel.image = data.super_split_screen_split_screen_links_image ? data.super_split_screen_split_screen_links_image.url : null;
      hydratedModel.links = data.super_split_screen_split_screen_links_links;
    }
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenLinksBlockSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
