import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let textSchema = object().shape({
  heading: string(),
  body: string(),
  icon: string().nullable(),
  color_options: string(),
  wrapper_class: string().nullable(),
  wrapper_id: string().nullable(),
  __digest: string().required(),
});

export const transformTextData = (data) => {
  // console.log('transformText', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      body: null,
      icon: null,
      color_options: null,
      wrapper_class: null,
      wrapper_id: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.text_title || data.text_heading;
    hydratedModel.body = data.text_body;
    hydratedModel.color_options = data.text_color_options;
    hydratedModel.icon = data.icon_text ? data.icon_text.url : null;
    hydratedModel.wrapper_class = data.text_wrapper_class;
    hydratedModel.wrapper_id = data.text_wrapper_id;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      textSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};