import React from "react";

import Notifications from "../notifications/Notifications";
import Navbar from "../navbar/Navbar/Navbar";
import posthog from "posthog-js";

const Header = (props) => {
  return [
    props &&
      props.storybookArgs &&
      React.createElement(
        "style",
        { key: "style", type: "text/css" },
        props.storybookArgs.styles
      ),
    <Navbar
      location={props.location}
      bannerData={props.bannerData}
      key="navbar"
      posthog={posthog}
    />,
  ];
};

Header.displayName = "Header";

export default Header;
