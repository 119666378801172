module.exports = `fragment split_screen_contained on Contentstack_split_screen {
    split_screen_styles {
      split_screen_image_alignment
    }
    split_screen_heading
    split_screen_contained_enabled
    split_screen_blocks {
      split_screen_cta {
        href
        title
      }
      split_screen_heading
      split_screen_sub_heading
      split_screen_text
      split_screen_logo {
        url
        title
        description
      }
      split_screen_links {
        href
        title
      }
      split_screen_image {
        url
        title
        description
      }
    }
  }`;