import { JaneEmbedConstants, JaneEventsActions, JaneEmbedActions } from "./constants.js";
import { 
    buildJaneOptions, 
    handleAnalyticsEvent, 
    handleLoadingEvent, 
    handleScrollToTop, 
    handleScrollToBucketTop, 
    handleGetJaneDeviceId, 
    handleResizeIframe, 
    handleSaveScrollPosition, 
    handleRestoreScrollPosition, 
    handleGetIsDeepLink 
} from "./index.js"

const setMounted = () => ({
    type: JaneEmbedActions.MOUNTED,
})

const setJaneInitialState = () => ({
    type: JaneEmbedActions.INITIAL_STATE
}) 

const setEmbedConfig = (janeEmbedConfigData) => ({
    type: JaneEmbedActions.EMBED_CONFIG,
    payload: janeEmbedConfigData,
})

const setJaneCheckoutCompleted = (data) => ({
    type: JaneEmbedActions.CHECKOUT_COMPLETED,
    payload: data,
})

const setJaneCheckoutUpdatedCart = (data) => ({
    type: JaneEmbedActions.CHECKOUT_UPDATED_CART,
    payload: data,
})

const setJaneOptions = (data) => ({
    type: JaneEventsActions.INIT_EMBEDDED_OPTIONS,
    payload: buildJaneOptions(data.embedConfig.action, {
        cartData: data.embedConfig.cartData || null,
        storeId: data.embedConfig.storeId || null,
        themeColor: data.embedConfig.themeColor,
        navigationColor: data.embedConfig.navigationColor,
        ctaTextColor: data.embedConfig.ctaTextColor,
        buttonBorderRadius: data.embedConfig.buttonBorderRadius || '0.5rem',
        cognitoToken: data.embedConfig.cognitoToken,
        userData: data.embedConfig.userData
    }),
})

const doAnalyticsEvent = (message, uicontext, props) => (dispatch) => {
    handleAnalyticsEvent(message, uicontext, props)
}

const doLoadingEvent = (message, uicontext, props) => (dispatch) => {
    handleLoadingEvent(message, uicontext, props)
}

const setJaneEmbedLoaded = (data) => ({
    type: JaneEmbedActions.EMBED_LOADED,
    payload: data
})

const doScrollToTop = (message, uicontext, props) => (dispatch) => {
    handleScrollToTop(message, uicontext, props);
}

const doScrollToBucketTop = (message, uicontext, props) => (dispatch) => {
    handleScrollToBucketTop(message, uicontext, props);
}

const getJaneDeviceId = (message, uicontext, props) => (dispatch) => {
    handleGetJaneDeviceId(message, uicontext.janeEmbedRef);
}

const setJaneDeviceId = (data) => ({
    type: JaneEmbedActions.JANE_DEVICE_ID,
    payload: data
})

const setJaneDistanceToTopOfWindow = (data) => ({
    type: JaneEmbedActions.DISTANCE_TO_TOP_OF_WINDOW,
    payload: data
})

const doResizeIFrame = (message, uicontext, props) => (dispatch) => {
    handleResizeIframe(message, uicontext, props);
}

const saveScrollPosition = (message, uicontext, props) => (dispatch) => {
    handleSaveScrollPosition(props);
}

const restoreScrollPosition = (message, uicontext, props) => (dispatch) => {
    handleRestoreScrollPosition(message, uicontext, props)
}

const getIsDeepLink = (message, uicontext, props) => (dispatch) => {
    handleGetIsDeepLink(message);
}

const setJaneOriginalScrollPosition = (data) => ({
    type: JaneEmbedActions.ORIGINAL_SCROLL_POSITION,
    payload: data
})

const setCartItemAdded = (data) => ({
    type: JaneEventsActions.CART_ITEM_ADD,
    payload: data
})

const setCartPageLoadData = (data) => ({
    type: JaneEventsActions.CART_PAGE_LOAD,
    payload: data,
})

export default {
    setMounted,
    setEmbedConfig,
    setJaneCheckoutCompleted,
    setJaneCheckoutUpdatedCart,
    setJaneOptions,
    doAnalyticsEvent,
    doLoadingEvent,
    setJaneEmbedLoaded,
    doScrollToTop,
    doScrollToBucketTop,
    getJaneDeviceId,
    setJaneDeviceId,
    setJaneDistanceToTopOfWindow,
    doResizeIFrame,
    saveScrollPosition,
    restoreScrollPosition,
    getIsDeepLink,
    setJaneOriginalScrollPosition,
    setCartItemAdded,
    setJaneInitialState,
    setCartPageLoadData
};
