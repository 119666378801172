const { seo } = require("../fragments/index");

module.exports = {
  query: `allContentstackMediaCoverage(sort: {order: DESC, fields: [published_on]}) {
    edges {
      node {
        url
        uid
        title
        published_on
        publish_date
        seo {
            ...seo
        }
        source_link {
          title
          href
        }
        updated_by
        updated_at
        post_date
        post_id
        legacy_author
        image {
          url
          title
          description
        }
        body
        created_by
        created_at
      }
    }
  }`,
  fragments: ["seo"],
};
