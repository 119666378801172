import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

import { transformSplitScreenCompactBlockData } from "./block";

export let splitScreenCompactSchema = object().shape({
  heading: string().nullable(),
  color_options: string().nullable(),
  enabled: bool().nullable(),
  blocks: array().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenCompactData = (data, legacy = true) => {
  // console.log("transformSplitScreenCompactData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      enabled: null,
      blocks: null,
      color_options: null,
      __digest: null,
    });
    // map the field names here
    if (legacy) {
      hydratedModel.heading = data.split_screen_compact_heading;
      hydratedModel.color_options = data.split_screen_compact_styles.split_screen_compact_color_options;
      hydratedModel.enabled = data.split_screen_compact_enabled;
      hydratedModel.blocks = data.split_screen_compact_blocks.reduce(
        (acc, block) => {
          acc.push(transformSplitScreenCompactBlockData(block, legacy));
          return acc;
        },
        []
      );
    }
    else {
      hydratedModel.heading = data.super_split_screen_split_screen_compact_heading;
      hydratedModel.color_options = data.super_split_screen_split_screen_compact_styles.split_screen_compact_color_options;
      hydratedModel.enabled = data.super_split_screen_split_screen_compact_enabled;
      hydratedModel.blocks = data.super_split_screen_split_screen_compact_blocks.reduce(
        (acc, block) => {
          acc.push(transformSplitScreenCompactBlockData(block, legacy));
          return acc;
        },
        []
      );
    }
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenCompactSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};