import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

const LazyLoadingImage = ({
  id = undefined,
  disableLazyLoading = false,
  className = "",
  src,
  alt = "",
  // width = "100%",
  effect = "opacity",
  // onError,
  ...rest
}) => {
  if (!src) return null;

  return disableLazyLoading ? (
    <img id={id} alt={alt} src={src} className={className} {...rest} />
  ) : (
    <LazyLoadImage
      id={id}
      alt={alt}
      src={src}
      // width={width}
      effect={effect}
      className={className}
      {...rest}
    />
  );
};

export default LazyLoadingImage;
