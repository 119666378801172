import React from "react";

import AgeGate from "./AgeGate";
import Cart from "./Cart";
import LocationUpdate from "./LocationUpdate";
import ProductSpecials from "./ProductSpecials";

export const getModalVariant = (props) => {
  if (props.variant === "age_gate") {
    const styles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.75)",
        zIndex: 100,
      },
      content: {
        inset: "unset",
        width: "90%",
        maxWidth: "1040px",
        padding: "80px",
        background: "#345543",
        position: "relative",
        top: "50%",
        left: "50%",
        bottom: "auto",
        right: "auto",
        WebkitTransform: "translate(-50%,-50%)",
        MsTransform: "translate(-50%,-50%)",
        transform: "translate(-50%,-50%)",
        border: "0",
        outline: "0",
        color: "white",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    };
    const children = <AgeGate {...props} />;

    return [children, styles];
  }

  if (props.variant === "cart") {
    const styles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.75)",
        zIndex: 100,
      },
      content: {
        inset: "unset",
        width: "30%", // Adjust the width to 30% of the screen
        maxWidth: "400px", // Set a maximum width if needed
        padding: "20px 20px",
        background: "#F4EFE9",
        position: "fixed",
        top: "0%",
        left: "100%", // Start off-screen to the right
        bottom: "auto",
        right: "auto",
        border: "0",
        outline: "0",
        color: "#345543",
        borderRadius: "0px",
        overflow: "scroll",
        height: "100%",
      },
    };
    const children = <Cart {...props} />;

    return [children, styles];
  }

  if (props.variant === "location_update") {
    const styles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.75)",
        zIndex: 100,
      },
      content: {
        inset: "unset",
        width: "90%",
        maxWidth: "600px",
        padding: "0px",
        position: "relative",
        top: "50%",
        left: "50%",
        bottom: "auto",
        right: "auto",
        WebkitTransform: "translate(-50%,-50%)",
        MsTransform: "translate(-50%,-50%)",
        transform: "translate(-50%,-50%)",
        border: "0",
        outline: "0",
        color: "#345543",
        borderRadius: "0px",
        overflow: "scroll",
      },
    };
    const children = <LocationUpdate {...props} />;

    return [children, styles];
  }

  if (props.variant === "product_specials") {
    const styles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.75)",
        zIndex: 100,
      },
      content: {
        inset: "unset",
        width: "1040px",
        maxWidth: "80%",
        padding: "80px",
        // background: "#345543",
        background: "#fff",
        position: "relative",
        top: "50%",
        left: "50%",
        bottom: "auto",
        right: "auto",
        WebkitTransform: "translate(-50%,-50%)",
        MsTransform: "translate(-50%,-50%)",
        transform: "translate(-50%,-50%)",
        border: "0",
        outline: "0",
        color: "white",
        borderRadius: "20px",
      },
    };
    const children = <ProductSpecials {...props} />;

    return [children, styles];
  }

  return null;
};
