import React from "react";
import PropTypes from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";

const Spinner = (props) => {
  const state = useSelector((state) => state);

  return (
    <div
      className="spinner"
      style={{
        width: "57px",
        position: "absolute",
        top: "50%",
        left: "calc(50% - 28.5px)",
      }}
    >
      <BeatLoader
        size={15}
        color="rgba(55, 85, 66, 1)"
        loading={props.spinnerActive}
      />
    </div>
  );
};

Spinner.displayName = "Spinner";

Spinner.propTypes = {
  spinnerActive: PropTypes.bool,
};

export default Spinner;
