// Auth middleware that defines GHUB-specific actions that shouldn't be in the Cognito middleware.
import { navigate } from "gatsby";
import queryString from "query-string";

import { setPasswordResetIsFirstPart } from "../../ui/actions";

import {
  AuthActions,
  AmplifyAuthExceptions,
} from "@col-care/common/providers/cognito/constants";

export const authMiddleware = (props) => (store) => (next) => (action) => {
  const state = store.getState();
  const pathname = state.router.location.pathname;

  switch (action.type) {
    // these are getting pretty opinionated; consider decoupling
    case AuthActions.SET_AUTH_ERROR:
      switch (action.payload.code) {
        case AmplifyAuthExceptions.CodeMismatchException:
          pathname.indexOf("/user/reset-password") > -1 &&
            store.dispatch(setPasswordResetIsFirstPart(true));
          break;
        case AmplifyAuthExceptions.UserNotFoundException:
          if (
            pathname.indexOf("/user/reset-password") > -1 &&
            !state.ui.passwordResetIsFirstPart
          ) {
            store.dispatch(setPasswordResetIsFirstPart(true));
            navigate("/user/forgot-password");
          }
          break;
        default:
          break;
      }
      break;
    case AuthActions.FORGOT_PASSWORD_REQUEST:
      store.dispatch(setPasswordResetIsFirstPart(true));
      navigate(
        "/user/reset-password?email=" + encodeURIComponent(action.payload.email)
      );
      break;
    case AuthActions.FORGOT_PASSWORD_SUCCESS:
      navigate("/user");
      break;
    case AuthActions.RESEND_VERIFICATION_REQUEST:
      if (action.payload.email) {
        navigate(
          "/user/confirm?email=" + encodeURIComponent(action.payload.email)
        );
      } else {
        navigate("/user/confirm");
      }
      break;
    case AuthActions.UPDATE_ATTRIBUTES_SUCCESS:
      navigate("/user");
      break;
    case AuthActions.LOGIN_SUCCESS:
      const parsed = queryString.parse(state.router.location.search);
      if (pathname === "/user" && parsed.redirect) {
        console.info("redirecting to /" + parsed.redirect);
        navigate("/" + parsed.redirect);
      }
      break;
    case AuthActions.LOGOUT_SUCCESS:
      setTimeout(() => {
        navigate("/");
      }, 1250);
      break;
    default:
      break;
  }

  return next(action);
};
