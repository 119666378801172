module.exports = `fragment cards on Contentstack_cards {
    cards_heading_icon {
      url
      title
    }
    cards_heading
    cards_enabled
    cards_cards {
      cards_card_title
      cards_text
      cards_icon {
        url
        title
        # description
      }
      cards_image {
        # description
        url
        title
      }
      cards_cta {
        href
        title
      }
    }
    cards_style_options {
      cards_background
      cards_style
    }
    cards_heading_icon {
      url
      title
      # description
    }
  }`;