module.exports = {
  query: `  
  allContentstackCampaign {
    edges {
      node {
        end_date
        feature_flag
        id
        start_date
        title
        uid
        unique_id
        elements {
          campaign_banner {
            ...campaign_banner
          }
        }
        markets {
          file {
            url
            title
            description
          }
          reference {
            ... on Contentstack_market {
              code
              title
            }
          }
        }
        locations {
          file {
            url
            title
            description
          }
          reference {
            ... on Contentstack_partner_location {
              id
              location_id
              title
            }
          }
        }
      }
    }
  }`,
};
