import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let splitScreenIconsBlockSchema = object().shape({
  text: string().nullable(),
  image: string().nullable(),
  heading: string().nullable(),
  icon: string().nullable(),
  cta_text: string().nullable(),
  cta_href: string().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenIconsBlockData = (data, legacy = true) => {
  // console.log('transformSplitScreenIconsBlock', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      text: null,
      image: null,
      heading: null,
      cta_text: null,
      cta_href: null,
      icon: null,
      __digest: null,
    });

    // map the field names here
    if (legacy) {
      hydratedModel.text = data.split_screen_icons_text;
      hydratedModel.heading = data.split_screen_icons_row_heading;
      hydratedModel.cta_text = data.split_screen_icons_cta
        ? data.split_screen_icons_cta.title
        : null;
      hydratedModel.cta_href = data.split_screen_icons_cta
        ? data.split_screen_icons_cta.href
        : null;
      hydratedModel.image = data.split_screen_icons_image
        ? data.split_screen_icons_image.url
        : null;
      hydratedModel.icon = data.split_screen_icons_icon
        ? data.split_screen_icons_icon.url
        : null;
    }
    else {
      hydratedModel.text = data.super_split_screen_split_screen_icons_text;
      hydratedModel.heading = data.super_split_screen_split_screen_icons_row_heading;
      hydratedModel.cta_text = data.super_split_screen_split_screen_icons_cta
        ? data.super_split_screen_split_screen_icons_cta.title
        : null;
      hydratedModel.cta_href = data.super_split_screen_split_screen_icons_cta
        ? data.super_split_screen_split_screen_icons_cta.href
        : null;
      hydratedModel.image = data.super_split_screen_split_screen_icons_image
        ? data.super_split_screen_split_screen_icons_image.url
        : null;
      hydratedModel.icon = data.super_split_screen_split_screen_icons_icon
        ? data.super_split_screen_split_screen_icons_icon.url
        : null;
    }

    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenIconsBlockSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
