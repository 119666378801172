import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

import { transformSplitScreenContainedData } from "../split-screen/split-screen-contained";
import { transformSplitScreenCompactData } from "../split-screen/split-screen-compact";
import { transformSplitScreenIconsData } from "../split-screen/split-screen-icons";
import { transformSplitScreenLinksData } from "../split-screen/split-screen-links";
import { transformSplitScreenStandardData } from "../split-screen/split-screen-standard";
// import { transformSplitScreenTgsData } from "../split-screen/split-screen-tgs";
// import { transformSplitScreenBlockData } from "../split-screen/block";

export let superSplitScreenSchema = object().shape({
  super_split_screen_variant: object().nullable(),
  variant: string().nullable(),
  __digest: string().required(),
});

export const transformSuperSplitScreenData = (data) => {
  // console.log("transformSuperSplitScreen", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      super_split_screen_variant: null,
      variant: null,
      __digest: null,
    });

    let content;
    switch (data.variant) {
      case "contained":
        content = transformSplitScreenContainedData(
          data.super_split_screen_variants["super_split_screen_contained"],
          false
        );
        break;
      case "compact":
        content = transformSplitScreenCompactData(
          data.super_split_screen_variants["super_split_screen_compact"],
          false
        );
        break;
      case "icons":
        content = transformSplitScreenIconsData(
          data.super_split_screen_variants["super_split_screen_icons"],
          false
        );
        break;
      case "links":
        content = transformSplitScreenLinksData(
          data.super_split_screen_variants["super_split_screen_links"],
          false
        );
        break;
      case "standard":
        content = transformSplitScreenStandardData(
          data.super_split_screen_variants["super_split_screen_standard"],
          false
        );
        break;
      // case "tgs":
      //   content = transformSplitScreenContainedData(data.super_split_screen_variants['super_split_screen_tgs'], false);
      //   break;
      default:
        content = data.super_split_screen_variants;
    }

    // map the field names here
    hydratedModel.super_split_screen_variant = content;
    hydratedModel.variant = data.variant;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      superSplitScreenSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
