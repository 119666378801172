import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let quickStatsSchema = object().shape({
  stats: array().nullable(),
  heading: string().nullable(),
  enabled: bool().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformQuickStatsData = (data) => {
  // console.log("transformQuickStatsData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      stats: null,
      heading: null,
      enabled: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.quick_stats_heading;
    hydratedModel.stats = data.quick_stats_stats_group
      ? data.quick_stats_stats_group
      : null;
    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      quickStatsSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
