import React from "react";
import { navigate } from "gatsby";
import queryString from "query-string";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import Button from "../../button/Button";

import * as modalStyles from "../modal.module.css";

const LocationUpdate = ({
  location,
  actions,
  modalTitle,
  dismissModal,
  query,
  posthog,
}) => {
  const parsed = queryString.parse(query);
  return (
    <div className={modalStyles.LocationUpdateInner}>
      <div className={modalStyles.LocationUpdateTextWrapper}>
        <h2 id="modal-title">{modalTitle || "Alert"}</h2>
        <h3>Your cart for your previous location will be cleared.</h3>
        <div className={modalStyles.LocationUpdateBtnContainer}>
          <Button
            theme="tgsButtonHero"
            key={`proceed`}
            id="location-update-proceed"
            label={`Yes, Change Location`}
            onClick={(e) => {
              dismissModal();
              const cartData = {
                store: {
                  id: null,
                  janeId: null,
                  name: null,
                },
                products: [],
                productsCount: null,
              };

              cartData.store.id = location.location_id || location.content.id;
              cartData.store.name = location.title;
              cartData.store.janeId =
                location?.vendor_integrations?.jane_rec_id ||
                location?.content?.vendor_integrations?.jane_rec_id;
              cartData.products = [];
              cartData.productsCount = 0;
              actions.session.doSetCartData(cartData);
              location.cb && location.cb();
              if (parsed.redirect) {
                navigate(parsed.redirect);
                capturePosthogLink({
                  eventName: `${posthogEvents.PRODUCT_FINDER}`,
                  title: "Yes, Change location",
                  to: parsed.redirect,
                  posthog,
                });
              } else {
                const link =
                  "/shop/" + (location?.location_id || location.content.id);
                navigate(link);
                capturePosthogLink({
                  eventName: `${posthogEvents.PRODUCT_FINDER} : Navigate to pre-order`,
                  title: "Yes, Change location",
                  posthog,
                  to: link,
                });
              }
            }}
          />
          <Button
            theme="tgsTransparentGreen"
            key={`cancel`}
            label={`No, Keep Location`}
            onClick={(e) => {
              dismissModal();
            }}
            id="location-update-cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default LocationUpdate;
