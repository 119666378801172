// GHUB-specific routing middleware
// sorry if this redundant but the routing libraries are very opaque
// it's a bit messy mixing auth states, routes, and UI but it's precise
import { navigate } from "gatsby";
import queryString from "query-string";

import { setSpinnerActive } from "@col-care/common/redux/ui/actions";
import { doCurrentAuthenticatedUser } from "@col-care/common/providers/cognito/actions";

export const routingMiddleware = (props) => (store) => (next) => (action) => {
  const state = store.getState();
  const pathname = state.router.location.pathname;
  const parsed = queryString.parse(state.router.location.search);
  let cartData, rehydrated;
  if (pathname === "/user" && parsed.redirect) {
    console.info("redirecting to /" + parsed.redirect);
    navigate("/" + parsed.redirect);
  }

  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      switch (action.payload.location.pathname) {
        case "/user":
          if (state.auth.loggedIn === true) {
            state.ui.spinnerActive === true &&
              store.dispatch(setSpinnerActive(true));
            store.dispatch(doCurrentAuthenticatedUser());
          }
          if (parsed.redirect) {
            console.info("redirecting to /" + parsed.redirect);
            navigate("/" + parsed.redirect);
          }
          break;
        case "/user/account":
          if (state.auth.loggedIn === true) {
            navigate("/user");
          }
          break;
        case "/user/create":
          if (state.auth.loggedIn === true) {
            navigate("/user");
          }
          break;
        case "/shop":
        case "/shop/":
          if (cartData && typeof cartData?.store?.id === "string") {
            navigate(`/shop/${cartData.store.id}`);
          } else {
            navigate(`/locations`);
          }
      }
      break;
    // handle redirects here
    // components should NEVER try to redirect on load as it subtly breaks routing
    // it's okay to redirect from a component so long as local mutations/actions do invocation
    case "persist/REHYDRATE":
      cartData = action.payload?.session?.cartData;
      rehydrated = action.payload?._persist?.rehydrated;
      if (pathname === "/shop" || pathname === "/shop/") {
        if (rehydrated === true) {
          if (cartData && typeof cartData?.store?.id === "string") {
            navigate(`/shop/${cartData.store.id}`);
          } else {
            navigate(`/locations`);
          }
        }
        break;
      } else if (pathname === "/checkout") {
        if (rehydrated && !cartData?.store?.janeId) {
          typeof window === "object" && navigate("/locations");
          return null;
        }
      }
    default:
      break;
  }

  return next(action);
};
