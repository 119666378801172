module.exports = `fragment split_screen_standard on Contentstack_split_screen_standard {
    split_screen_standard_heading
    split_screen_standard_blocks {
      split_screen_standard_row_heading
      split_screen_standard_text
      split_screen_standard_image {
        url
        title
        description
      }
      split_screen_standard_cta {
        title
        href
      }
    }
    split_screen_standard_enabled
    split_screen_standard_styles {
      split_screen_standard_image_alignment
      split_screen_standard_color_options
    }
  }`;