module.exports = `fragment quote on Contentstack_quote {
    styles
    quote_statement
    quote_heading
    quote_enabled
    quote_cta {
      href
      title
    }
    quote_icon {
      url
      title
      # description
    }
    quote_background_color
  }`;