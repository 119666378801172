import axios from "axios";
import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import { createLogger } from "redux-logger";
import { handleRequests } from "@redux-requests/core";
import { createDriver } from "@redux-requests/axios";
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";
import { unstable_batchedUpdates } from "react-dom";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import { globalHistory } from "@reach/router";
import { memoize as _memoize, merge as _merge } from "lodash";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import storage from "@col-care/common/redux/persistStorage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// reducers
import { sessionReducer } from "@col-care/common/redux/session/reducer";
import { authReducer } from "@col-care/common/providers/cognito/reducer";
import { janeReducer } from "@col-care/common/providers/iheartjane/jane-embed-v2/reducer";
import { hydrateReducer } from "@col-care/common/redux/hydrate/reducer";
import { uiReducer } from "@col-care/common/redux/ui/reducer";

// transformers
import {
  transformLayoutData,
  transformLocationData,
  transformPostData,
  transformSiteConfigData,
  transformBuildInfoData,
  transformDatabagData,
} from "@col-care/common/providers/contentstack/modular-blocks";

// middlewares
import { notificationsMiddleware } from "@col-care/common/redux/middleware/notifications";
import { authMiddleware } from "@col-care/common/redux/middleware/auth";
import { spinnerMiddleware } from "@col-care/common/redux/middleware/spinner";
import { routingMiddleware } from "@col-care/common/redux/middleware/routing";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["session"],
  timeout: 0,
  // debug: true,
  migrate: (state) => Promise.resolve(state),
  transforms: [
    createTransform(
      (inboundState) => inboundState,
      (outboundState) => outboundState
    ),
  ],
};

const ssr = typeof window === "undefined";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: ssr ? createMemoryHistory() : createBrowserHistory(),
    reachGlobalHistory: globalHistory,
    savePreviousLocations: 5,
    batch: unstable_batchedUpdates,
  });

// @todo: need to memoize
export const configureStore = (data) => {
  // console.log("configureStore", data);
  const middlewares = [];

  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(axios),
  });

  const props = {};
  props.posthog = data?.posthog;

  middlewares.push(thunk);
  middlewares.push(...requestsMiddleware);
  middlewares.push(routerMiddleware);
  middlewares.push(notificationsMiddleware(props));
  middlewares.push(authMiddleware(props));
  middlewares.push(spinnerMiddleware(props));
  middlewares.push(routingMiddleware(props));

  if (typeof window !== "undefined") {
    const loggerMiddleware = createLogger({
      collapsed: true,
    });
    middlewares.push(loggerMiddleware);
  }

  const combinedReducers = combineReducers({
    hydrate: hydrateReducer,
    session: sessionReducer,
    auth: authReducer,
    jane: janeReducer,
    requests: requestsReducer,
    router: routerReducer,
    ui: uiReducer,
  });

  const persistedReducer = persistReducer(persistConfig, combinedReducers);

  return { middlewares, persistedReducer };
};

// only the first argument is used in memoization
const createReduxStore = (cacheKey, pathname, data) => {
  const { middlewares, persistedReducer } = configureStore(data);

  const composeEnhancers =
    (typeof window !== "undefined" &&
      process.env.NODE_ENV !== "production" &&
      composeWithDevTools) ||
    compose;

  const createStoreWithMiddlewares = composeEnhancers(
    applyMiddleware.apply(this, middlewares)
  )(createStore);

  const hydrate = {};

  if (data?.pageContext?.content) {
    hydrate.siteConfigData = transformSiteConfigData(
      data.pageContext.site_config
    );
    hydrate.buildInfoData = transformBuildInfoData(data.pageContext.build_info);
    hydrate.databagData = transformDatabagData(data.pageContext.databag);
    hydrate.layoutData = transformLayoutData(data.pageContext.content);

    if (data.pageContext.__type === "allContentstackPartnerLocation") {
      hydrate.locationData = transformLocationData(data.pageContext.content);
    }

    if (data.pageContext.__type === "allContentstackPressRelease") {
      hydrate.postData = transformPostData(data.pageContext.content);
    }
  }

  // @todo: trying import these schemas causes some state issues that are hard to explain
  // so unless you wanna dive into the fix, just leave this alone
  // it may be something straightforward but it's not been worth the time
  const state = {
    hydrate: hydrate,
    session: {
      cartData: {
        store: {
          id: null,
          name: null,
        },
        products: [],
        productsCount: 0,
      },
    },
    auth: {
      error: null,
      userError: null,
      currentUser: null,
      cognitoUser: null,
      loggedIn: false,
      checkingAuth: false,
      authStatus: null,
    },
    router: {
      location: {
        pathname: pathname,
        search: "",
        hash: "",
        state: null,
        key: "default",
      },
      action: null,
      previousLocations: [],
    },
    jane: {
      mounted: false,
      embedConfig: null,
      janeCheckoutCompleted: null,
      janeCheckoutUpdatedCart: null,
      initPayload: null,
      cartItemAdded: null,
      janeDeviceId: null,
      janeDistanceToTopOfWindow: 0,
      janeEmbedLoaded: 0,
      janePreventAutoScrolling: false,
      janePreventResizing: false,
      janePreventContainerResizing: false,
      janeOriginalScrollPosition: 0,
      cartPageLoadData: null,
    },
  };

  // the warning goes for this too
  const initialReduxState = {
    hydrate: {
      layoutData: {},
      locationData: {},
      postData: {},
      siteConfigData: {},
      databagData: {},
    },
    session: {
      cartData: {
        store: {
          id: null,
          janeId: null,
          name: null,
        },
        products: [],
        specials: [],
        productsCount: null,
      },
      specialsData: {
        storeId: null,
        data: {},
      },
    },
    auth: {
      error: null,
      userError: null,
      currentUser: null,
      cognitoUser: null,
      loggedIn: false,
      checkingAuth: false,
      authStatus: null,
    },
    ui: {
      spinnerActive: false,
      passwordResetIsTransitioning: false,
      passwordResetIsFirstPart: true,
    },
    requests: {
      queries: {},
      mutations: {},
      cache: {},
      downloadProgress: {},
      uploadProgress: {},
      requestsKeys: {},
      normalizedData: {},
      ssr: [],
      watchers: {},
      websocket: {
        pristine: true,
        connected: false,
      },
    },
    router: {
      location: {
        pathname: null,
        search: "",
        hash: "",
        state: null,
        key: "default",
      },
      action: null,
      previousLocations: [],
    },
  };
  const initialState = _merge(initialReduxState, state);
  const store = createStoreWithMiddlewares(persistedReducer, initialState);
  const persistor = persistStore(store);
  return { store, persistor };
};

export const getReduxStore = !ssr
  ? _memoize(createReduxStore)
  : createReduxStore;

export const getHistory = (store) => {
  return createReduxHistory(store.store);
};

export const getBrowserHistory = !ssr ? _memoize(getHistory) : getHistory;
