import React, { useState } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";
import { useSpring, animated } from "react-spring";

import Button from "../button/Button";
import CloseIcon from "../../images/close.png";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";
import * as bannerStyles from "./banner.module.css";

function is_external(url) {
  return url.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) != null;
}

const Banner = (props) => {
  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};

  const posthog = props.posthog;

  const springProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  const data = props.bannerData;
  if (!data) {
    return null;
  }

  const newsReference = data.news_banner_config.news_reference
    ? data.news_banner_config.news_reference[0]
    : null;

  const newsReferenceTitle = newsReference?.title;
  const newsReferenceLink = newsReference?.source_link.href;

  let bannerLink = null;
  if (newsReference && data.news_banner_config.link_to_referenced_content) {
    bannerLink = newsReferenceLink;
  }
  // fall-through intentionally
  if (data.news_banner_config.custom_link_path) {
    bannerLink = data.news_banner_config.custom_link_path;
  }

  let bannerTitle = null;
  if (data.title) {
    bannerTitle = data.title;
  } else if (newsReference) {
    bannerTitle = newsReferenceTitle;
  }

  return (
    <>
      {props.actuallyShowBanner && (
        <div style={springProps} className={bannerStyles.banner}>
          <animated.div className={bannerStyles.bannerSection}>
            <div className={bannerStyles.bannerContainer}>
              <div className={bannerStyles.bannerInfoDescription}>
                {bannerTitle}
              </div>
              <div className={bannerStyles.btnContainer}>
                <Button
                  id="banner-button"
                  label={data.news_banner_config.button_label || `Let's go`}
                  theme="smallGreen"
                  onClick={() => {
                    cookies.set(props.cookyKey, Date.now(), { path: "/" });
                    props.setShowBanner(false);
                    if (bannerLink) {
                      if (is_external(bannerLink)) {
                        window.open(bannerLink);
                        capturePosthogLink({
                          eventName: posthogEvents.EXTERNAL_NAVIGATION,
                          title:
                            data.news_banner_config.button_label || `Let's go`,
                          to: bannerLink,
                          posthog,
                        });
                      } else {
                        navigate(bannerLink);
                        capturePosthogLink({
                          eventName: posthogEvents.NAVIGATION,
                          title:
                            data.news_banner_config.button_label || `Let's go`,
                          to: bannerLink,
                          posthog,
                        });
                      }
                    }
                  }}
                >
                  {data.news_banner_config.button_label || `Let's go`}
                </Button>
              </div>
              <div className={bannerStyles.bannerExitContainer}>
                <LazyLoadingImage
                  onClick={() => {
                    props.setShowBanner(!props.showBanner);
                    cookies.set(props.cookyKey, Date.now(), { path: "/" });
                  }}
                  className={bannerStyles.bannerExit}
                  src={CloseIcon}
                  alt="close"
                />
              </div>
            </div>

            {props.bannerInfoBig && <p>{props.bannerInfoBig}</p>}
            {props.bannerHeader && <h3>{props.bannerHeader}</h3>}

            {/* <img
              onClick={() => {
                props.setShowBanner(!props.showBanner);
                cookies.set(props.cookyKey, Date.now(), { path: "/" });
              }}
              className={bannerStyles.bannerExit}
              src={CloseIcon}
              alt="close"
            /> */}
          </animated.div>
        </div>
      )}
    </>
  );
};

export default Banner;
