module.exports = `fragment page_meta on Contentstack_page_meta {
    json_ld_description
    meta_additionaltype
    meta_description
    meta_sameas
    meta_title
    published
    url {
      href
      title
    }
  }`;