const janeConstants = require("./constants");

const handleWindowMessages = (module.exports.handleWindowMessages = (
  message,
  context
) => {
  if (!message) {
    return false;
  }

  if (message.origin !== "https://www.iheartjane.com") {
    return;
  }

  const messageType = message.data && message.data.messageType;

  if (!messageType) {
    console.warn("Received a Jane message with no type", message);
    return;
  }

  // console.log("handleWindowMessages", messageType, message.data.payload);

  if (messageType) {
    switch (messageType) {
      case "analyticsEvent":
        handleAnalyticsEvent(
          message,
          context.janeCheckoutCompleted,
          context.setJaneCheckoutCompleted,
          context.setCartItemAdded,
          context.cartData,
          context.doNavigate
        );
        break;
      case "loadingEvent":
        handleLoadingEvent(
          message,
          context.janeEmbedLoaded,
          context.setJaneEmbedLoaded,
          context.janeCheckoutUpdatedCart,
          context.setJaneCheckoutUpdatedCart
        );
        break;
      case "scrollToTop":
        handleScrollToTop(
          message,
          context.janePreventAutoScrolling,
          context.distanceToTopOfWindow,
          context.janeOriginalScrollPosition
        );
        break;
      case "scrollToBucketTop":
        handleScrollToBucketTop(message, context.janePreventAutoScrolling);
        break;
      case "getJaneDeviceId":
        handleGetJaneDeviceId(message, context.janeEmbedRef);
        break;
      case "resizeIFrame":
        handleResizeIframe(
          message,
          context.janeBodyRef,
          context.janeEmbedMenuDivRef,
          context.janeEmbedIframeDivRef,
          context.janePreventResizing,
          context.setJanePreventResizing,
          context.janePreventContainerResizing,
          context.setJanePreventContainerResizing
        );
        break;
      case "saveScrollPosition":
        handleSaveScrollPosition(context.setJaneOriginalScrollPosition);
        break;
      case "restoreScrollPosition":
        handleRestoreScrollPosition(
          message,
          context.janeOriginalScrollPosition,
          context.setJaneOriginalScrollPosition,
          context.janeEmbedMenuDivRef,
          context.janeEmbedIframeDivRef
        );
        break;
      // case "enableShrinkEveryResize":
      //   handleEnableShrinkEveryResize(message);
      //   break;
      // case "disableShrinkEveryResize":
      //   handleDisableShrinkEveryResize(message);
      //   break;
      case "getIsDeepLink":
        handleGetIsDeepLink(message);
        break;
      // case "setMarginToIframeTop":
      //   handleSetMarginToIframeTop(message);
      //   break;
      // case "openDesktopFilters":
      //   handleOpenDesktopFilters();
      //   break;
      // case "shrinkNextResize":
      //   handleShrinkNextResize();
      //   break;
      // case "openNewMobileModal":
      //   handleOpenNewMobileModal();
      //   break;
      // case "openMobileModal":
      //   handleOpenMobileModal();
      //   break;
      // case "closeMobileModal":
      //   handleCloseMobileModal()
      // break;
      default:
        break;
    }
  }
});

const handleAnalyticsEvent = (module.exports.handleAnalyticsEvent = (
  message,
  janeCheckoutCompleted,
  setJaneCheckoutCompleted,
  setCartItemAdded,
  cartData,
  doNavigate
) => {
  if (!message) {
    console.warn("Jane analyticsEvent called but got no message content.");
    return false;
  }
  // console.log("Jane analyticsEvent", message);
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  if (payload && payload.name) {
    switch (payload.name) {
      case "checkout":
        if (
          !janeCheckoutCompleted &&
          typeof setJaneCheckoutCompleted === "function"
        ) {
          setJaneCheckoutCompleted(payload);
          localStorage.setItem(
            "tgs-jane-order-" + payload.properties.cartId,
            JSON.stringify(payload.properties)
          );
        }

        console.log("ecommerce:addTransaction", {
          id: payload.properties.cartId, // mandatory  -  Transaction ID
          revenue: payload.properties.estimatedTotal, // mandatory  -  Transaction's total cost
          shipping: payload.properties.deliveryFee, // optional   -  Delivery costs
          tax: payload.properties.salesTax + payload.properties.storeTax, // optional   -  Taxes costs
          currency: "usd", //optional  - Currency value (Numeric or alphanumeric ISO 4217 value (string))
        });

        window._uxa.push([
          "ecommerce:addTransaction",
          {
            id: payload.properties.cartId, // mandatory  -  Transaction ID
            revenue: payload.properties.estimatedTotal, // mandatory  -  Transaction's total cost
            shipping: payload.properties.deliveryFee, // optional   -  Delivery costs
            tax: payload.properties.salesTax + payload.properties.storeTax, // optional   -  Taxes costs
            currency: "usd", //optional  - Currency value (Numeric or alphanumeric ISO 4217 value (string))
          },
        ]);
        window._uxa.push(["ecommerce:send"]);
        break;
      case "cartItemAdd":
        setCartItemAdded &&
          setCartItemAdded({
            ...payload.properties.product,
            productId: payload.properties.productId,
          });
        break;
      case "cartItemClickedInCheckout":
        if (payload.properties.productId) {
          if (cartData) {
            const product = cartData.products.find(
              (item) => item.productId === payload.properties.productId
            );
            if (product && !!product.pathname) {
              // doNavigate(product.pathname);
            }
          }
        }
        break;
    }
  }
});

const handleLoadingEvent = (module.exports.handleLoadingEvent = (
  message,
  embedLoaded,
  setEmbedLoaded,
  janeCheckoutUpdatedCart,
  setJaneCheckoutUpdatedCart
) => {
  if (!message) {
    console.warn("Jane loadingEvent called but got no message content.");
    return false;
  }
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  console.log("Jane loadingEvent:", messageType, payload);

  if (
    payload.name === "embeddedAppLoaded" ||
    payload.name === "headlessAppLoaded"
  ) {
    setEmbedLoaded(true);
  } else if (payload.name === "cartCreateOrUpdate") {
    if (setJaneCheckoutUpdatedCart) {
      setJaneCheckoutUpdatedCart(payload.properties.products);
    }
  } else {
    console.log("Got a Jane loadingEvent that was not handled:", payload.name);
  }
});

const handleGetJaneDeviceId = (module.exports.handleGetJaneDeviceId = (
  message,
  janeEmbed
) => {
  if (!message) {
    console.warn("Jane getJaneDeviceId called but got no message content.");
    return false;
  }
  console.log("Jane getJaneDeviceId:", message, janeEmbed);
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  if (!payload.token) {
    console.warn("Jane getJaneDeviceId called but got no token.");
    return false;
  }

  try {
    // console.log(janeEmbed)
    const messageData = {
      messageType: "respondGetJaneDeviceId",
      payload: {
        token: payload.token,
        value: localStorage.getItem("j_device_id"),
      },
      pathname: window.location.href,
    };
    janeEmbed.contentWindow &&
      janeEmbed.contentWindow.postMessage(messageData, "*");
    typeof window !== "undefined" &&
      localStorage.setItem("j_sent_device_id", Date.now());
    console.log("Jane getJaneDeviceId posted a message:", messageData);
  } catch (err) {
    console.log("jane getJaneDeviceId error:", err);
  }
});

const handleResizeIframe = (module.exports.handleResizeIframe = (
  message,
  bodyRef,
  embedMenuDivRef,
  embedIframeDivRef,
  janePreventResizing,
  setJanePreventResizing,
  janePreventContainerResizing,
  setJanePreventContainerResizing
) => {
  if (!message) {
    console.warn("Jane resizeIframe called but got no message content.");
    return false;
  }
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;
  // console.log('Jane resizeIframe:', payload)
  // window.setTimeout(() => window.scrollTo(0, 0), 100);

  // if (preventContainerResizing) {
  //   return
  // }

  var pathname = message.data.pathname;
  var delimiter = "/menu?";
  pathname =
    pathname.slice(-delimiter.length) === delimiter
      ? pathname.slice(0, -1)
      : pathname;

  // if (shrinkNextResize || shrinkEveryResize){
  // contentHeightMap[pathname] = message.data.payload;
  try {
    var shrunkenHeight = message.data.payload + "px";
    if (embedMenuDivRef.current && embedIframeDivRef.current) {
      embedMenuDivRef.current.style.height = shrunkenHeight;
      embedIframeDivRef.current.style.height = shrunkenHeight;
    } else {
      console.warn("Found no iframe to resize");
      var timeout = setTimeout(() => {
        if (embedMenuDivRef.current && embedIframeDivRef.current) {
          embedMenuDivRef.current.style.height = shrunkenHeight;
          embedIframeDivRef.current.style.height = shrunkenHeight;
        }
      }, 100);
    }
    // contentHeight = shrunkenHeight;
    //   shrinkNextResize = false;
    // return;
  } catch (err) {
    // }
    console.warn(err);
  }

  // contentHeightMap[pathname] = Math.max(parseInt(contentHeightMap[pathname]) || 0, message.data.payload);

  // var height = contentHeightMap[pathname] + 'px';

  // if (!preventResizing) {
  //   menuElement.style.height = height;
  //   iframeContainerElement.style.height = height;
  // }

  // contentHeight = height;

  // var blockScrollToTop =
  //   pathname.includes('/menu') ||
  //   pathname.includes('blockScrollToTop=true') ||
  //   preventAutoScrolling;

  // if (!blockScrollToTop){
  //   window.scrollTo(0, distanceToTopOfWindow);
  // }
  // }
});

const handleSaveScrollPosition = (module.exports.handleSaveScrollPosition = (
  setOriginalScrollPosition
) => {
  console.log("Jane saveScrollPosition:", window.scrollY);

  setOriginalScrollPosition(window.scrollY);
});

const handleRestoreScrollPosition =
  (module.exports.handleRestoreScrollPosition = (
    message,
    originalScrollPosition,
    setOriginalScrollPosition,
    embedMenuDivRef,
    embedIframeDivRef
  ) => {
    if (!message) {
      console.warn(
        "Jane restoreScrollPosition called but got no message content."
      );
      return false;
    }
    console.log("Jane restoreScrollPosition:", originalScrollPosition);
    // window.scrollTo(0, 0);
    const payload = message.data && message.data.payload;
    const messageType = message.data && message.data.messageType;

    // if (originalScrollPosition){
    //   var pathname = message.data.pathname;
    //   // var newHeight = Math.max(parseInt(contentHeightMap[pathname]) || 0, parseInt(contentHeight) || 0) + 'px';
    //   var newHeight = originalScrollPosition

    //   embedMenuDivRef.current.style.height = newHeight;
    //   // iframeContainerElement.style.height = newHeight;
    //   console.log('!!!', originalScrollPosition)
    //   window.scrollTo(0, originalScrollPosition);
    //   originalScrollPosition = 0;
    // }
  });

const handleEnableShrinkEveryResize =
  (module.exports.handleEnableShrinkEveryResize = (message) => {
    if (!message) {
      console.warn(
        "Jane enableShrinkEveryResize called but got no message content."
      );
      return false;
    }
    // console.log('Jane enableShrinkEveryResize:', message)
    const payload = message.data && message.data.payload;
    const messageType = message.data && message.data.messageType;
  });

const handleDisableShrinkEveryResize =
  (module.exports.handleDisableShrinkEveryResize = (message) => {
    if (!message) {
      console.warn(
        "Jane disableShrinkEveryResize called but got no message content."
      );
      return false;
    }
    // console.log('Jane disableShrinkEveryResize:', message)
    const payload = message.data && message.data.payload;
    const messageType = message.data && message.data.messageType;
  });

const handleGetIsDeepLink = (module.exports.handleGetIsDeepLink = (message) => {
  if (!message) {
    console.warn("Jane getIsDeepLink called but got no message content.");
    return false;
  }
  console.log("Jane getIsDeepLink:", message);
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  typeof window !== "undefined" &&
    localStorage.setItem("j_is_deeplink", Date.now());

  try {
    const segs = message.data.pathname
      ? message.data.pathname.split("?")
      : null;
    if (segs && segs[1]) {
      window.setTimeout(() => window.scrollTo(0, 0), 100);
    }
  } catch (err) {
    console.warn(err);
  }
});

const handleSetMarginToIframeTop = (module.exports.handleSetMarginToIframeTop =
  (message) => {
    if (!message) {
      console.warn(
        "Jane setMarginToIframeTop called but got no message content."
      );
      return false;
    }
    // console.log('Jane setMarginToIframeTop:', message)
    const payload = message.data && message.data.payload;
    const messageType = message.data && message.data.messageType;

    // window.setTimeout(() => window.scrollTo(0, 0), 100);
  });

const handleResize = (module.exports.handleResize = (context) => {
  // console.log('Jane resize:', context)
});

const handleScrollToBucketTop = (module.exports.handleScrollToBucketTop = (
  message,
  preventAutoScrolling
) => {
  if (!message) {
    console.warn("Jane scrollToBucketTop called but got no message content.");
    return false;
  }
  // console.log('Jane handleScrollToBucketTop:', message)
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  // if (!preventAutoScrolling && payload) {
  // window.scrollTo(0, payload)
  // }
});

const handleShrinkNextResize = (module.exports.handleShrinkNextResize = (
  message,
  preventAutoScrolling
) => {
  if (!message) {
    console.warn("Jane shrinkNextResize called but got no message content.");
    return false;
  }
  // console.log('Jane handleShrinkNextResize:', message)
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;
});

const handleScrollToTop = (module.exports.handleScrollToTop = (
  message,
  preventAutoScrolling
) => {
  if (!message) {
    console.warn("Jane scrollToTop called but got no message content.");
    return false;
  }
  // console.log('Jane handleScrollToTop:', message)
  const payload = message.data && message.data.payload;
  const messageType = message.data && message.data.messageType;

  // if (!preventAutoScrolling && payload) {
  // window.scrollTo(0, payload)
  // }

  window.setTimeout(() => window.scrollTo(0, 0), 100);
});

const isDeeplink = (module.exports.isDeeplink = (searchQuery) => {
  if (!searchQuery) {
    return false;
  }
});

const setDistanceToTop = (module.exports.setDistanceToTop = (
  embedMenuDiv,
  distanceToTopOfWindow
) => {
  let elem = embedMenuDiv.current;
  console.log("setDistanceToTop", elem, distanceToTopOfWindow);

  // window.setTimeout(() => window.scrollTo(0, 0), 100);

  // do {
  //   distanceToTopOfWindow = (distanceToTopOfWindow + elem.offsetTop)
  //   elem = elem.offsetParent;
  //   console.log(elem.offsetParent)
  // } while (elem);

  distanceToTopOfWindow = distanceToTopOfWindow < 0 ? 0 : distanceToTopOfWindow;
});

const resizeAppleDevices = (module.exports.resizeAppleDevices = (
  userAgent,
  janeEmbedMenuDiv,
  janeEmbedIframeDiv
) => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    janeEmbedMenuDiv.style.height = "100vh";
    janeEmbedIframeDiv.style.height = "100vh";
  }
});

const buildJaneOptions = (module.exports.buildJaneOptions = (
  action,
  opts = {}
) => {
  // "initEmbeddedOptions"

  const options = {
    disableAuth: true, // intentionally left non-configurable
    cartData: null,
    storeId: null,
    userData: null,
    cognitoToken: null,
    // fontFamily: "'Proxima Nova', Helvetica, sans-serif",
    // fontSrc: "",
    themeColor: "#202245",
    navigationColor: "#202245",
    ctaTextColor: "#ffffff",
    buttonBorderRadius: "0.5rem",
  };

  Object.assign(options, opts);

  // console.log(options);

  const payload = {
    messageType: action,
    payload: {
      options: {
        disableLoadingSpinner: false,
        disableWeightSelection: false,
        disableMarketingCheckoutAgreements: false,
        disableAuthFeatures: options.disableAuth,
        theme: {
          themeColor: options.themeColor,
          navigationColor: options.navigationColor,
          ctaTextColor: options.ctaTextColor,
          // font: {
          // fontFamily: fontFamily,
          // url: fontSrc
          // },
          calloutBackground: options.navigationColor,
          buttonBorderRadius: options.buttonBorderRadius,
        },
      },
    },
  };

  if (options.storeId) {
    payload.payload.storeId = options.storeId;
  }

  if (options.cartData) {
    payload.payload.products = options.cartData.products;
    payload.payload.headlessPartnerName = "eMota";
    payload.payload.headlessPartnerId = "a75a6cfe-27ba-4642-8702-07e2ec9c041d";
    // payload.payload.headlessPartnerId = envMode === 'production' || envMode === 'qa' ? "a75a6cfe-27ba-4642-8702-07e2ec9c041d" : "31ceb2db-60bf-4b4d-a178-512f63af326e"
  }

  if (options.userData) {
    payload.payload.user = options.userData;
  }

  if (options.cognitoToken) {
    payload.payload.cognitoToken = options.cognitoToken;
  }

  // console.log(payload);

  return payload;
});

// @todo: build these from state
const janeCategories = (module.exports.janeCategories = [
  "edible",
  "extract",
  "flower",
  "gear",
  "grow",
  "merch",
  "pre-roll",
  "tincture",
  "topical",
  "vape",
  "best_selling",
]);

const janeCategoryLookupBySlug = (module.exports.janeCategoryLookupBySlug = {
  edible: {
    name: "Edible",
  },
  extract: {
    name: "Extract",
  },
  flower: {
    name: "Flower",
  },
  gear: {
    name: "Gear",
  },
  grow: {
    name: "Grow",
  },
  merch: {
    name: "Merch",
  },
  "pre-roll": {
    name: "Pre-roll",
  },
  tincture: {
    name: "Tincture",
  },
  topical: {
    name: "Topical",
  },
  vape: {
    name: "Vape",
  },
  best_selling: {
    name: "Best Selling",
  },
});

const priceIdMapNumeric = (module.exports.priceIdMapNumeric = {
  each: "each",
  "half gram": "0.5g",
  gram: "1g",
  "two gram": "2g",
  "eighth ounce": "3.5g",
  "quarter ounce": "7g",
  "half ounce": "14g",
  ounce: "28g",
});

const idMapNumeric = (module.exports.idMapNumeric = {
  each: "each",
  half_gram: "0.5g",
  gram: "1g",
  two_gram: "2g",
  eighth_ounce: "3.5g",
  quarter_ounce: "7g",
  half_ounce: "14g",
  ounce: "28g",
});

const priceIdMap = (module.exports.priceIdMap = {
  each: "each",
  "half gram": "half_gram",
  gram: "gram",
  "two gram": "two_gram",
  "eighth ounce": "eighth_ounce",
  "quarter ounce": "quarter_ounce",
  "half ounce": "half_ounce",
  ounce: "ounce",
});

const idPriceMap = (module.exports.idPriceMap = {
  each: "each",
  half_gram: "half gram",
  gram: "gram",
  two_gram: "two gram",
  eighth_ounce: "eighth ounce",
  quarter_ounce: "quarter ounce",
  half_ounce: "half ounce",
  ounce: "ounce",
});

// fyi props can be string or object.
// if string, it's the image directly. if object, it's the object itself.
// sometimes Jane categories come in as phenotypes, so we need to check for that.
const stockProductImages = (module.exports.stockProductImages = {
  hybrid:
    "https://product-assets.iheartjane.com/default-photos/flower/hybrid.png",
  indica:
    "https://product-assets.iheartjane.com/default-photos/flower/indica.png",
  sativa:
    "https://product-assets.iheartjane.com/default-photos/flower/sativa.png",
  flower: {
    hybrid:
      "https://product-assets.iheartjane.com/default-photos/flower/hybrid.png",
    indica:
      "https://product-assets.iheartjane.com/default-photos/flower/indica.png",
    sativa:
      "https://product-assets.iheartjane.com/default-photos/flower/sativa.png",
  },
  edible: {
    candies:
      "https://product-assets.iheartjane.com/default-photos/edible/candies.png",
    capsules:
      "https://product-assets.iheartjane.com/default-photos/edible/capsules.png",
    drinks:
      "https://product-assets.iheartjane.com/default-photos/edible/drinks.png",
    chocolates:
      "https://product-assets.iheartjane.com/default-photos/edible/chocolates.png",
    mints:
      "https://product-assets.iheartjane.com/default-photos/edible/mints.png",
    other:
      "https://product-assets.iheartjane.com/default-photos/edible/other.png",
    "drink-mixes":
      "https://product-assets.iheartjane.com/default-photos/edible/drinkmixes.png",
    "baked-goods":
      "https://product-assets.iheartjane.com/default-photos/edible/bakedgoods.png",
  },
  extract: {
    wax: "https://product-assets.iheartjane.com/default-photos/extract/waxes.png",
    hash: "https://product-assets.iheartjane.com/default-photos/extract/hash.png",
    rosin:
      "https://product-assets.iheartjane.com/default-photos/extract/rosins.png",
    "live-resin":
      "https://product-assets.iheartjane.com/default-photos/extract/waxes.png",
    diamonds:
      "https://product-assets.iheartjane.com/default-photos/extract/waxes.png",
    rso: "https://product-assets.iheartjane.com/default-photos/extract/ricksimpsonoilrso.png",
  },
  gear: {
    vaporizer:
      "https://product-assets.iheartjane.com/default-photos/gear/vaporizers.png",
    paraphernalia:
      "https://product-assets.iheartjane.com/default-photos/gear/paraphernalia.png",
    papers:
      "https://product-assets.iheartjane.com/default-photos/gear/papers.png",
  },
  "pre-roll": {
    "pre-roll":
      "https://product-assets.iheartjane.com/default-photos/preroll/prerolls.png",
    "pre-rolls":
      "https://product-assets.iheartjane.com/default-photos/preroll/prerolls.png",
    "pre-roll-packs":
      "https://product-assets.iheartjane.com/default-photos/preroll/prerollpacks.png",
    "infused-packs":
      "https://product-assets.iheartjane.com/default-photos/preroll/infusedpacks.png",
    infused:
      "https://product-assets.iheartjane.com/default-photos/preroll/infused.png",
  },
  tincture: {
    sublinguals:
      "https://product-assets.iheartjane.com/default-photos/tincture/sublinguals.png",
    pets: "https://product-assets.iheartjane.com/default-photos/tincture/pets.png",
    sprays:
      "https://product-assets.iheartjane.com/default-photos/tincture/sprays.png",
  },
  topical: {
    bath: "https://product-assets.iheartjane.com/default-photos/topical/bath.png",
    lotions:
      "https://product-assets.iheartjane.com/default-photos/topical/lotions.png",
    patches:
      "https://product-assets.iheartjane.com/default-photos/topical/patches.png",
    balms:
      "https://product-assets.iheartjane.com/default-photos/topical/balms.png",
    intimates:
      "https://product-assets.iheartjane.com/default-photos/topical/intimates.png",
    "roll-ons":
      "https://product-assets.iheartjane.com/default-photos/topical/rollons.png",
    oils: "https://product-assets.iheartjane.com/default-photos/topical/oils.png",
    gels: "https://product-assets.iheartjane.com/default-photos/topical/gels.png",
  },
  vape: {
    cartridges:
      "https://product-assets.iheartjane.com/default-photos/vape/cartridges.png",
    "specialty-pods":
      "https://product-assets.iheartjane.com/default-photos/vape/specialtypods.png",
    disposibles:
      "https://product-assets.iheartjane.com/default-photos/vape/disposables.png",
  },
  grow: "https://product-assets.iheartjane.com/cdn-cgi/image/width=1200,fit=scale-down,format=auto,metadata=none/default-photos/grow/clones.png",
});
