module.exports = `fragment site_element on Contentstack_site_element {
    variant
    tabs {
      variant
      global_id
    }
    product_finder {
      subheading
      mount_point
      heading
      global_id
      product_brands {
        title
        link {
          title
          href
        }
        category
      }
      map_settings {
        zoom
        lat
        lng
      }
    }
    instagram_tiles {
      global_id
    }
    iframe {
      global_id
      height
      src
    }
    careers_widget {
      global_id
    }
  }`;