module.exports = `fragment store_hours on Contentstack_store_hours{
    store_hours_monday_open
    store_hours_monday_close
    store_hours_tuesday_open
    store_hours_tuesday_close
    store_hours_wednesday_open
    store_hours_wednesday_close
    store_hours_thursday_open
    store_hours_thursday_close
    store_hours_friday_open
    store_hours_friday_close
    store_hours_saturday_open
    store_hours_saturday_close
    store_hours_sunday_open
    store_hours_sunday_close
  }`;