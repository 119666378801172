import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { decode } from "html-entities";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { noop as _noop } from "lodash";
// import queryString from "query-string";
import { memoize as _memoize } from "lodash";
import algoliasearch from "algoliasearch/lite";
import { history } from "instantsearch.js/es/lib/routers";
import { usePostHog } from "posthog-js/react";

import "@reach/skip-nav/styles.css";

import { Helmet } from "react-helmet";

import Header from "./header/Header";
import Footer from "./footer/Footer";
// import Spinner from "./spinner/Spinner";

import "../styles/layout.css";
// import ProximaNovaUrl from "../fonts/proxima_ssv/ProximaNova-Regular.otf";

const newProps = {};
let posthog;

const getAlgoliaSearchHistory = _memoize(() => {
  // console.log("getAlgoliaSearchHistory");
  return history();
});

const getAlgoliaSearchClient = _memoize((applicationId, apiKey) => {
  return new Proxy(algoliasearch(applicationId, apiKey), {
    get(target, prop, receiver) {
      const originalMethod = target[prop];
      if (typeof originalMethod === "function" && prop === "search") {
        return function (...args) {
          const params = args?.[0]?.[0]?.params;
          // hooo boy
          // if (posthog && params) {
          //   console.info(
          //     `Posthog event -> ${posthogEvents.INSTANTSEARCH_INTERACTION}`
          //   );
          //   posthog?.capture(posthogEvents.INSTANTSEARCH_INTERACTION, {
          //     $EventDetails: params,
          //   });
          // }
          return originalMethod.apply(this, args);
        };
      }
      return Reflect.get(target, prop, receiver);
    },
  });
});

const Layout = React.memo((props) => {
  // console.log("layout props", props);
  // console.log("layout path", props.children.props.path);

  const state = useSelector((state) => state);
  const layoutPath = props.children?.props?.path;

  let pageContext = props.children?.props.pageContext;

  posthog = usePostHog();

  const bannerData =
    state.hydrate.siteConfigData?.blocks &&
    state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
      ? state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
          .component_data
      : null;

  // no site config here means we're in Gatsby dev mode error
  if (!pageContext || !pageContext.site_config) {
    return (
      <>
        <SkipNavLink />
        <Header location={props.children?.props.location} bannerData={null} />

        <Helmet htmlAttributes={{ lang: "en" }} encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
        </Helmet>

        <SkipNavContent />

        <div className="margin_help">{props.children}</div>

        <Footer />
      </>
    );
  }

  const title =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_title &&
    pageContext.content.page_meta.meta_title.length > 0
      ? decode(pageContext.content.page_meta.meta_title)
      : decode(pageContext.title);

  const desc =
    pageContext.content &&
    pageContext.content.page_meta &&
    pageContext.content.page_meta.meta_description &&
    pageContext.content.page_meta.meta_description.length > 0
      ? decode(pageContext.content.page_meta.meta_description)
      : decode(pageContext.site_config.seo.meta_description);

  const metaTitle = pageContext.site_config
    ? title + " | " + decode(pageContext.site_config.site_title)
    : "";

  const metaDesc = pageContext.site_config ? desc : null;

  const metaRobots = pageContext.site_config
    ? decode(pageContext.site_config.seo.meta_robots)
    : null;

  const defaultImage =
    "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltd1d084bbdeced635/656a38bddd3986f5b9146477/fdbb3826-cb30-4d22-ab12-40ba8f3cb54a.jpeg";

  const jsonLdLocalBusiness = pageContext.content
    ? getStructuredData(
        "LocalBusiness",
        state,
        pageContext.site_config.seo,
        defaultImage
      )
    : null;

  const layoutData = state.hydrate.layoutData;
  const layoutContext = layoutData?.context;

  // console.log("layout context", layoutContext);

  newProps.posthog = posthog;

  const janeConfig = props.children.props.pageContext?.site_config?.env?.jane;
  if (janeConfig.application_id && janeConfig.algolia_key) {
    if (typeof window === "object") {
      newProps.algoliaSearchClient = getAlgoliaSearchClient(
        janeConfig.application_id,
        janeConfig.algolia_key
      );
      newProps.algoliaSearchHistory = getAlgoliaSearchHistory();
    }
  } else {
    console.info("No Algolia keys found in site_config.env.jane");
  }

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        encodeSpecialCharacters={false}
        title={`${metaTitle}`}
      >
        {jsonLdLocalBusiness && (
          <script type={jsonLdLocalBusiness.type}>
            {jsonLdLocalBusiness.innerHTML}
          </script>
        )}
        <meta charSet="utf-8" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
          name="description"
          content={`${layoutContext ? layoutContext.description : metaDesc}`}
        />
        <meta name="robots" content={`${metaRobots}`} />

        <meta
          property="og:title"
          content={`${layoutContext ? layoutData.title : metaTitle}`}
        />
        <meta
          property="og:description"
          content={`${layoutContext ? layoutContext.description : metaDesc}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={
            layoutContext && layoutContext.image_urls
              ? layoutContext.image_urls[0]
              : defaultImage
          }
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="418" />
        <meta
          property="og:url"
          content={`https://www.tgscolorado.com${
            typeof window !== "object"
              ? "/" + props.children.props["*"]
              : layoutPath
          }`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${layoutContext ? layoutData.title : metaTitle}`}
        />
        <meta name="twitter:site" content="@TGScolorado" />
        <meta
          name="twitter:image"
          content={
            layoutContext && layoutContext.image_urls
              ? layoutContext.image_urls[0]
              : defaultImage
          }
        />
        <meta name="twitter:image:alt" content="The Green Solution" />
        <meta
          name="twitter:description"
          content={`${layoutContext ? layoutContext.description : metaDesc}`}
        />
      </Helmet>

      <Header
        location={props.children?.props.location}
        bannerData={bannerData}
        posthog={posthog}
      />

      <div style={{ position: "relative" }} className="margin_help">
        <SkipNavContent />
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, newProps)
        )}
        {/* { false ? <Loader /> : <>{props.children}</> } */}
      </div>

      <Footer posthog={posthog} />
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.displayName = "Layout";

export default Layout;

// @todo: move to common lib
const getStructuredData = (type, state, seo, defaultImage) => {
  const location = state.hydrate.locationData?.location;
  if (!location) {
    return null;
  }

  switch (type) {
    case "LocalBusiness":
      // @todo: do we want to put hours? seems redundant
      return {
        type: "application/ld+json",
        innerHTML: `{
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "url": "https://www.tgscolorado.com/locations/${
              location.location_id
            }",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${location.address.city}",
                "addressRegion": "${location.address.state}",
                "streetAddress": "${location.address.street_address_1} ${
          location.address.street_address_2
        }",
                "postalCode": "${location.address.zip_code}",
                "addressCountry": "US"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "${parseFloat(location.address.latitude)}",
                "longitude": "${parseFloat(location.address.longitude)}"
            },
            "image": [ "${defaultImage}" ],
            "name": "${location.location_brand?.[0]?.title || ""}",
            "telephone": "${location.store_information.telephone}"
          }`,
      };
    default:
      return null;
  }
};
