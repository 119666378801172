module.exports = {
  query: `allContentstackPartnerLocation {
    edges {
      node {
        page_meta {
          ...page_meta
        }
        vendor_integrations {
          ...vendor_integrations
        }
        product_brands {
          ...brands
        }
        updated_by
        updated_at
        uid
        title
        # tags
        sandbox_store
        store_information {
          ...store_information
        }
        store_hours {
          ...store_hours
        }
        location_id
        location_brand {
          ...location_brand
        }
        id
        address {
          ...store_address
        }
      }
    }
  }`,
  fragments: ["page_meta", "vendor_integrations", "brands", "store_information", "store_hours", "location_brand", "store_address"]
};