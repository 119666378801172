import * as React from "react";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { reachify } from "redux-first-history";
import { LocationProvider } from "@reach/router";
import posthog from "posthog-js";
import GhubPostHogProvider from "@col-care/common/providers/posthog";
// import { PersistGate } from 'redux-persist/integration/react'

import { getReduxStore, getBrowserHistory } from "./configureStore";
import App from "../components/App";

const POSTHOG_API_KEY = "phc_NTxdsBSpXP4sb3p58BR0RB6JH4ydQbY7l3D4Pib68Y5";
// const POSTHOG_API_KEY = "phc_bwA0EtQgj0Oe7prZHe2AgiuHYZIoUBqdM8VLdRuVhWy"; // test account

const reduxWrapper = (props, element) => {
  // this is memoized using both the 2nd and 3rd arguments

  const reduxStore = getReduxStore(
    "init",
    props.path,
    Object.assign({ posthog: posthog }, props)
  );

  const history = reachify(getBrowserHistory(reduxStore));

  if (process.env.GHUB_COGNITO_CONF) {
    const cognitoConfig = JSON.parse(process.env.GHUB_COGNITO_CONF);

    if (cognitoConfig) {
      const configObj = {};

      configObj["Auth"] = {
        mandatorySignIn: true,
        region: cognitoConfig.REGION,
        userPoolId: cognitoConfig.USER_POOL_ID,
        userPoolWebClientId: cognitoConfig.APP_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH",
      };

      if (typeof window === "object") {
        configObj["oauth"] = {
          domain: cognitoConfig.DOMAIN,
          redirectSignIn: window.location.origin + "/user",
          redirectSignOut: window.location.origin + "/user/logout",
          responseType: "token",
          scope: ["profile", "email", "openid"],
        };
      }

      Amplify.configure(configObj);
    }
  }

  // @todo: could set up persister to show loading indicator
  return (
    <GhubPostHogProvider apiKey={POSTHOG_API_KEY} posthog={posthog}>
      <Provider store={reduxStore.store}>
        <LocationProvider history={history}>
          {/* <PersistGate loading={null} persistor={reduxStore.persistor}> */}
          <App {...props} posthog={posthog}>
            {element}
          </App>
          {/* </PersistGate> */}
        </LocationProvider>
      </Provider>
    </GhubPostHogProvider>
  );
};

export default reduxWrapper;
