import { string, object } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let iframeSchema = object().shape({
  src: string().nullable(),
  height: string().nullable(),
  __digest: string().required(),
});

export const transformIframeData = (data) => {
  // console.log('transformIframe', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      src: null,
      height: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.src = data.src;
    hydratedModel.height = data.height;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      iframeSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};