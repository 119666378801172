import React from "react";

import ButtonElement from "@col-care/common/elements/button/Button";

const Button = (props) => {
  return <ButtonElement {...props} />;
};

Button.displayName = "Button";

export default Button;
