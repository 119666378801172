import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let mapSchema = object().shape({
  heading: string().nullable(),
  subheading: string().nullable(),
  enabled: bool().nullable(),
  disable_tooltips: bool().nullable(),
  disable_listing: bool().nullable(),
  inject_data: string().nullable(),
  display_on_desktop_only_locations_map: string().nullable(),
  __digest: string().required(),
});

export const transformMapData = (data) => {
  // console.log('transformMap', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      subheading: null,
      enabled: null,
      disable_tooltips: null,
      disable_listing: null,
      inject_data: null,
      display_on_desktop_only_locations_map: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.heading;
    hydratedModel.subheading = data.subheading;
    hydratedModel.enabled = data.enabled;
    hydratedModel.disable_tooltips = data.disable_tooltips;
    hydratedModel.disable_listing = data.disable_listing;
    // @todo: weak but too busy for abstraction fun time
    hydratedModel.inject_data = 'locations';
    hydratedModel.display_on_desktop_only_locations_map= data.display_on_desktop_only_locations_map;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      mapSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};