// Notifications middleware. Please only use for firing notifications!!
// Create a new middleware for other actions if an appropriate one doesn't exist.

import { toast } from "react-hot-toast";

import {
  AuthActions,
  AmplifyAuthStatus,
} from "@col-care/common/providers/cognito/constants";
import SessionActions from "../../session/actions";
import { capturePosthogEvent } from "@col-care/common/utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";

const toastOpts = {
  success: {
    style: {
      background: "green",
    },
  },
  error: {
    style: {
      background: "red",
    },
  },
};

export const notificationsMiddleware =
  (props) => (store) => (next) => (action) => {
    const posthog = props.posthog;
    const item = action.payload;
    const state = store.getState();

    const cartData = state.session?.cartData;

    switch (action.type) {
      case AuthActions.LOGIN_SUCCESS:
        toast.success("You have been signed in.", toastOpts);
        break;
      case AuthActions.SET_AUTH_ERROR:
        // @todo: it could be helpful to be specific with error messages
        toast.error(action.payload.message, toastOpts);
        break;
      case AuthActions.SIGNUP_SUCCESS:
        if (action.payload.event === AmplifyAuthStatus.signUp) {
          toast.success(action.payload.message, toastOpts);
        }
        break;
      case AuthActions.CONFIRM_SIGNUP_SUCCESS:
        if (action.payload.event === AmplifyAuthStatus.confirmSignUp) {
          toast.success(action.payload.message, toastOpts);
        }
        break;
      case AuthActions.FORGOT_PASSWORD_REQUEST:
        toast.success(
          "Please check your email for further instructions.",
          toastOpts
        );
        break;
      case AuthActions.RESEND_VERIFICATION_REQUEST:
        toast.success("Verification email re-sent.", toastOpts);
        break;
      case AuthActions.FORGOT_PASSWORD_SUCCESS:
        toast.success("Password successfully updated.", toastOpts);
        break;
      case AuthActions.UPDATE_ATTRIBUTES_SUCCESS:
        toast.success("Profile updated!", toastOpts);
        break;

      case AuthActions.LOGOUT_SUCCESS:
        // i declare this not a hack
        setTimeout(() => {
          toast.success("Logged out successfully", toastOpts);
        }, 1250);
        break;

      case SessionActions.ADD_TO_CART:
        if (action?.payload?.specials?.length) {
          if (action?.payload?.specialType === "bundle") {
            toast("Mix and Match Bundle Completed!", toastOpts);
          } else {
            toast("Item(s) added to cart!", toastOpts);
          }
        } else {
          toast("Item(s) added to cart!", toastOpts);
        }
        capturePosthogEvent({
          eventName: posthogEvents.ITEM_ADDED_TO_CART,
          title:
            action?.payload?.specialType === "bundle"
              ? "Mix and Match Bundle Completed!: "
              : "",
          databag: {
            item: {
              name: item?.name,
              brand: item?.brand,
            },
            cartData,
          },
          posthog,
        });
        break;

      case SessionActions.DECREMENT_QUANTITY:
        toast("Item quantity decreased");
        capturePosthogEvent({
          eventName: posthogEvents.ITEM_QUANTITY_DECREASED,
          databag: {
            item: {
              name: item?.name,
              brand: item?.brand,
            },
            cartData,
          },
          posthog,
        });
        break;

      case SessionActions.INCREMENT_QUANTITY:
        toast("Item quantity increased");
        capturePosthogEvent({
          eventName: posthogEvents.ITEM_QUANTITY_INCREASED,
          databag: {
            item: {
              name: item?.name,
              brand: item?.brand,
            },
            cartData,
          },
          posthog,
        });
        break;

      case SessionActions.REMOVE_ITEM:
        toast("Item removed from cart!");
        capturePosthogEvent({
          eventName: posthogEvents.ITEM_REMOVED_FROM_CART,
          databag: {
            item: {
              name: item?.name,
              brand: item?.brand,
            },
            cartData,
          },
          posthog,
        });
        break;

      // case SessionActions.SET_CART_DATA:
      //   toast("Location updated");
      //   break;

      default:
        break;
    }

    return next(action);
  };
