import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let latestNewsSchema = object().shape({
  heading: string().nullable(),
  enabled: bool().nullable(),
  hide_while_paginating: bool().nullable(),
  items: object().shape({
    title: string().nullable(),
    url: string().nullable(),
  }).nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformLatestNewsData = (data) => {
  // console.log("transformLatestNewsData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      enabled: null,
      items: null,
      hide_while_paginating: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.latest_news_heading;
    hydratedModel.items = data.latest_news_items;
    hydratedModel.enabled = data.latest_news_enabled;
    hydratedModel.hide_while_paginating = data.latest_news_hide_while_paginating;

    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      latestNewsSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
