import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let heroSchema = object().shape({
  heading: string().nullable(),
  alignment: string().nullable(),
  subheading: string().nullable(),
  enabled: bool().nullable(),
  variant: string().nullable(),
  image_path: string().nullable(),
  image_title: string().nullable(),
  image_description: string().nullable(),
  video: object().nullable(),
  video_poster: object().nullable(),
  layout_styles: string().nullable(),
  color_styles: string().nullable(),
  cta_1_href: string().nullable(),
  cta_1_title: string().nullable(),
  cta_2_href: string().nullable(),
  cta_2_title: string().nullable(),
  height: string().nullable(),
  global_id: string().nullable(),
  display_on_mobile_only_hero_banner: string().nullable(),
  set_aria_role_to_main: bool().nullable(),
  mobile_background_image_hero_banner: object().shape({
    url: string().nullable(),
    title: string().nullable(),
    description: string().nullable(),
  }),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformHeroData = (data) => {
  // console.log('transformHero', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      subheading: null,
      alignment: null,
      enabled: null,
      variant: null,
      image_path: null,
      image_title: null,
      image_description: null,
      video: null,
      video_poster: null,
      layout_styles: null,
      color_styles: null,
      cta_1_href: null,
      cta_1_title: null,
      cta_2_href: null,
      cta_2_title: null,
      height: null,
      global_id: null,
      display_on_mobile_only_hero_banner: null,
      set_aria_role_to_main: null,
      mobile_background_image_hero_banner: {
        url: null,
        title: null,
        description: null,
      },
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.hero_banner_heading;
    hydratedModel.subheading = data.hero_banner_subheading;
    hydratedModel.enabled = data.hero_banner_enabled;
    hydratedModel.alignment = data.hero_banner_alignment;
    hydratedModel.variant = data.hero_banner_variant;
    hydratedModel.image_path =
      data.hero_banner_image && data.hero_banner_image.url
        ? data.hero_banner_image.url
        : null;
    hydratedModel.image_title =
      data.hero_banner_image && data.hero_banner_image.title
        ? data.hero_banner_image.title
        : null;
    hydratedModel.image_description =
      data.hero_banner_image && data.hero_banner_image.description
        ? data.hero_banner_image.description
        : null;
    hydratedModel.video = data.hero_banner_video_group.hero_banner_video;
    hydratedModel.video_poster = data.hero_banner_video_group
      ? data.hero_banner_video_group.hero_banner_video_poster
      : null;
    hydratedModel.layout_styles = data.hero_banner_layout_styles;
    hydratedModel.color_styles = data.hero_banner_color_styles;
    hydratedModel.height = data.hero_banner_height;

    // this stinks but w/e
    hydratedModel.cta_1_href =
      data.hero_banner_calls_to_action &&
      data.hero_banner_calls_to_action.hero_banner_cta_1 &&
      data.hero_banner_calls_to_action.hero_banner_cta_1.href;
    hydratedModel.cta_1_title =
      data.hero_banner_calls_to_action &&
      data.hero_banner_calls_to_action.hero_banner_cta_1 &&
      data.hero_banner_calls_to_action.hero_banner_cta_1.title;
    hydratedModel.cta_2_href =
      data.hero_banner_calls_to_action &&
      data.hero_banner_calls_to_action.hero_banner_cta_2 &&
      data.hero_banner_calls_to_action.hero_banner_cta_2.href;
    hydratedModel.cta_2_title =
      data.hero_banner_calls_to_action &&
      data.hero_banner_calls_to_action.hero_banner_cta_2 &&
      data.hero_banner_calls_to_action.hero_banner_cta_2.title;
    hydratedModel.set_aria_role_to_main = data.set_aria_role_to_main;
    hydratedModel.display_on_mobile_only_hero_banner =
      data.display_on_mobile_only_hero_banner;

    hydratedModel.mobile_background_image_hero_banner = {
      url: data.mobile_background_image_hero_banner
        ? data.mobile_background_image_hero_banner.url
        : null,
      title: data.mobile_background_image_hero_banner
        ? data.mobile_background_image_hero_banner.title
        : null,
      description: data.mobile_background_image_hero_banner
        ? data.mobile_background_image_hero_banner.description
        : null,
    };

    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      heroSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
