import React from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import FacebookIcon from "@col-care/common/elements/icons/FacebookIcon";
import InstagramIcon from "@col-care/common/elements/icons/InstagramIcon";
import TwitterIcon from "@col-care/common/elements/icons/TwitterIcon";
import LinkedinIcon from "@col-care/common/elements/icons/LinkedinIcon";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";
import footerNav from "./footer-navigation.json";
import LazyLoadingImage from "@col-care/common/blocks/lazy-loading-image";
import * as footerStyles from "./footer.module.css";

const Footer = ({ posthog, ...rest }) => {
  const state = useSelector((state) => state);
  // const socialMedia = state.hydrate.siteConfigData?.socialMedia;
  const categoryNav = [
    {
      title: "Flower",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=flower`
        : "/locations",
      external: false,
    },
    {
      title: "Extract",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=extract`
        : "/locations",
      external: false,
    },
    {
      title: "Edible",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=edible`
        : "/locations",
      external: false,
    },
    {
      title: "Tincture",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=tincture`
        : "/locations",
      external: false,
    },
    {
      title: "Pre-Roll",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=pre-roll`
        : "/locations",
      external: false,
    },
    {
      title: "Topical",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=topical`
        : "/locations",

      external: false,
    },
    {
      title: "Vape",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=vape`
        : "/locations",
      external: false,
    },
    {
      title: "Gear",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=gear`
        : "/locations",
      external: false,
    },
    {
      title: "Grow",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=grow`
        : "/locations",
      external: false,
    },
    {
      title: "Merch",
      link: state.session.cartData.store?.id
        ? `/shop/${state.session.cartData.store.id}?category%5B0%5D=merch`
        : "/locations",
      external: false,
    },
  ];

  const onClickHandler = (item) => {
    capturePosthogLink({
      eventName: posthogEvents.FOOTER_NAVIGATION,
      title: item.title,
      to: item.link,
      posthog,
    });
  };

  return (
    <footer>
      <div className={footerStyles.footerContainer}>
        <div className={footerStyles.footerSection}>
          {/* <img
            className={footerStyles.logoImage}
            src="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltb4d0f2d8e1ff55ab/636beb10cbd6b84ecb48509f/tgs_logo_dark.png"
            alt="TGS"
          /> */}
          <LazyLoadingImage
            className={footerStyles.logoImage}
            src="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltb4d0f2d8e1ff55ab/636beb10cbd6b84ecb48509f/tgs_logo_dark.png"
            alt="TGS"
          />

          <span>support@TGSColorado.com</span>
          <span>(303) 990-9723</span>
        </div>
        <div className={footerStyles.footerSection}>
          <h4>Products</h4>
          <div className={footerStyles.flexSection}>
            <div className={footerStyles.productSection}>
              <ul>
                {categoryNav?.slice(0, 4)?.map((item) => {
                  return (
                    <li key={item.title}>
                      {!item?.external && (
                        <Link
                          to={item.link}
                          onClick={() => {
                            capturePosthogLink({
                              eventName: posthogEvents.FOOTER_NAVIGATION,
                              title: item.title,
                              to: item.link,
                              posthog,
                            });
                          }}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={footerStyles.productSection}>
              <ul>
                {categoryNav?.slice(4, 7)?.map((item) => {
                  return (
                    <li key={item.title}>
                      {!item?.external && (
                        <Link
                          to={item.link}
                          onClick={() => {
                            capturePosthogLink({
                              eventName: posthogEvents.FOOTER_NAVIGATION,
                              title: item.title,
                              to: item.link,
                              posthog,
                            });
                          }}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={footerStyles.productSection}>
              <ul>
                {categoryNav?.slice(7)?.map((item) => {
                  return (
                    <li key={item.title}>
                      {!item?.external && (
                        <Link
                          to={item.link}
                          onClick={() => {
                            capturePosthogLink({
                              eventName: posthogEvents.FOOTER_NAVIGATION,
                              title: item.title,
                              to: item.link,
                              posthog,
                            });
                          }}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={footerStyles.footerSection}>
          <div className={footerStyles.flexSection}>
            <div className={footerStyles.siteSection}>
              <ul>
                <li>
                  <Link
                    to={
                      state.session.cartData.store?.id
                        ? `/shop/${state.session.cartData.store.id}`
                        : "/locations"
                    }
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.FOOTER_NAVIGATION,
                        title: "Shop",
                        to: state.session.cartData.store?.id
                          ? `/shop/${state.session.cartData.store.id}`
                          : "/locations",
                        posthog,
                      });
                    }}
                  >
                    Shop
                  </Link>
                </li>
                <li>
                  <Link
                    to="/deals"
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.FOOTER_NAVIGATION,
                        title: "Deals",
                        to: "/deals",
                        posthog,
                      });
                    }}
                  >
                    Deals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.FOOTER_NAVIGATION,
                        title: "About Us",
                        to: "/about",
                        posthog,
                      });
                    }}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/locations"
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.FOOTER_NAVIGATION,
                        title: "Dispensaries",
                        to: "/locations",
                        posthog,
                      });
                    }}
                  >
                    Dispensaries
                  </Link>
                </li>
                <li>
                  <a
                    href="https://cannabistcompany.com/stash-cash-app"
                    target="_blank"
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.FOOTER_NAVIGATION,
                        title: "Loyalty",
                        to: "https://cannabistcompany.com/stash-cash-app",
                        posthog,
                      });
                    }}
                  >
                    Loyalty
                  </a>
                </li>
              </ul>
            </div>
            <div className={footerStyles.siteSection}>
              <ul>
                {footerNav?.sidebarMenu?.map((item) => {
                  return (
                    <li key={item.title}>
                      {item.external ? (
                        <a
                          href={item.link}
                          target={item.external ? "_blank" : "_self"}
                          onClick={() => onClickHandler(item)}
                        >
                          {item.title}
                        </a>
                      ) : (
                        <Link
                          to={item.link}
                          onClick={() => onClickHandler(item)}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  );
                })}

                <li>
                  <div className={footerStyles.social_area}>
                    <div className={footerStyles.social_area_icons}>
                      {footerNav?.socialNav?.map((item) => (
                        <a
                          key={item.title}
                          href={item.link}
                          target={item.external ? "_blank" : "_self"}
                          rel="noreferrer"
                          aria-label={item.title}
                          onClick={() =>
                            capturePosthogLink({
                              eventName: posthogEvents.FOOTER_NAVIGATION,
                              title: "Social navigation" + item.title,
                              to: item.link,
                              posthog,
                            })
                          }
                        >
                          {item.title === "Instagram" && (
                            <InstagramIcon
                              color={item.iconColor}
                              className={footerStyles.social_icons}
                            />
                          )}
                          {item.title === "Facebook" && (
                            <FacebookIcon
                              color={item.iconColor}
                              className={footerStyles.social_icons}
                            />
                          )}
                          {item.title === "Twitter" && (
                            <TwitterIcon
                              color={item.iconColor}
                              className={footerStyles.social_icons}
                            />
                          )}
                          {item.title === "LinkedIn" && (
                            <LinkedinIcon
                              color={item.iconColor}
                              className={footerStyles.social_icons}
                            />
                          )}
                        </a>
                      ))}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
