import React from "react";
// import queryString from "query-string";

// import Button from "../../button/Button";
import CloseIconBlack from "@col-care/common/elements/icons/CloseIconBlack";
import * as modalStyles from "../modal.module.css";

const ProductSpecials = (props) => {
  // const buttonOpts = props.buttonOpts || ["tgssmall", "tgsButton"];

  const currentSpecial = props.currentSpecial;

  const children = (
    <>
      <div className={modalStyles.ProductSpecialsInner}>
        <div className={modalStyles.ProductSpecialsWrapper}>
          <h2 className={modalStyles.title} id="modal-title">
            {currentSpecial?.title}
          </h2>

          <p className={modalStyles.border_help}>
            <span className={modalStyles.bold}>Description: </span>
            {currentSpecial?.description ? (
              <span>{currentSpecial?.description}</span>
            ) : (
              <span>This special has no description.</span>
            )}
          </p>
          <p className={modalStyles.border_help}>
            <span className={modalStyles.bold}>Special ID: </span>
            {currentSpecial?.id}
          </p>
          <p className={modalStyles.border_help}>
            <span className={modalStyles.bold}>Special type: </span>
            {currentSpecial?.special_type}
          </p>
          <p className={modalStyles.border_help}>
            <span className={modalStyles.bold}>Discount type: </span>
            {currentSpecial?.discount_type}
          </p>
          {/*} <p>{`Discount dollar amount: ${currentSpecial.discount_dollar_amount}`}</p>*/}
          <p className={modalStyles.border_help}>
            <span className={modalStyles.bold}>Discount percent: </span>
            {currentSpecial?.discount_percent}%
          </p>
          <p>
            <span className={modalStyles.bold}>Discount target price: </span>
            {currentSpecial?.discount_target_price}
          </p>
        </div>
      </div>
      <button
        className={modalStyles.dismiss_button}
        aria-label="Dismiss"
        key={`cart-dismiss-modal`}
        id={`cart-dismiss-modal-button`}
        type="button"
        onClick={(e) => {
          props.dismissModal();
          props.setCurrentSpecial(null);
        }}
      >
        <CloseIconBlack color="#375542" />
      </button>
    </>
  );

  return children;
};

ProductSpecials.displayName = "ProductSpecials";

export default ProductSpecials;
