import { bindActionCreators } from "redux";
import { memoize as _memoize } from "lodash";

import HydrateActions from "@col-care/common/redux/hydrate/actions";
import SessionActions from "@col-care/common/redux/session/actions"
import UiActions from "@col-care/common/redux/ui/actions";
import AuthActions from "@col-care/common/providers/cognito/actions";
import JaneEventsActions from "@col-care/common/providers/iheartjane/jane-embed-v2/actions"

export const createBoundActions = function (dispatch) {
  return {
    hydrate: bindActionCreators(HydrateActions, dispatch),
    session: bindActionCreators(SessionActions, dispatch),
    auth: bindActionCreators(AuthActions, dispatch),
    ui: bindActionCreators(UiActions, dispatch),
    jane: bindActionCreators(JaneEventsActions, dispatch),
  };
};

export const getBoundActions =
  typeof window !== "undefined"
    ? _memoize(createBoundActions)
    : createBoundActions;
