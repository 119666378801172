// extracted by mini-css-extract-plugin
export var almostClose = "Sidebar-module--almostClose--42ee0";
export var almostOpen = "Sidebar-module--almostOpen--c4bb6";
export var backButton = "Sidebar-module--backButton--7e749";
export var close = "Sidebar-module--close--f820f";
export var close_me = "Sidebar-module--close_me--e5644";
export var cross = "Sidebar-module--cross--a3cf4";
export var crossedLine = "Sidebar-module--crossedLine--e83ea";
export var header = "Sidebar-module--header--b097f";
export var icon_container = "Sidebar-module--icon_container--f3d01";
export var line = "Sidebar-module--line--e3bdb";
export var locationIcon = "Sidebar-module--locationIcon--e552c";
export var logoIcon = "Sidebar-module--logoIcon--985f4";
export var member_dropdown = "Sidebar-module--member_dropdown--c575f";
export var member_dropdown_wrapper = "Sidebar-module--member_dropdown_wrapper--41227";
export var member_location = "Sidebar-module--member_location--65aa4";
export var mobileSidebar = "Sidebar-module--mobile-sidebar--e0b42";
export var navItem = "Sidebar-module--navItem--e09d2";
export var navItemWrapper = "Sidebar-module--navItemWrapper--2fffc";
export var navItems = "Sidebar-module--navItems--13eec";
export var open = "Sidebar-module--open--32fe8";
export var right_arrow = "Sidebar-module--right_arrow--ed13d";
export var sidebar = "Sidebar-module--sidebar--47eb5";
export var sidebar_footer = "Sidebar-module--sidebar_footer--102dd";
export var sidebar_li = "Sidebar-module--sidebar_li--c3cf1";
export var subNavItemWrapper = "Sidebar-module--subNavItemWrapper--f7378";
export var subitemsArrow = "Sidebar-module--subitemsArrow--b6e20";
export var subnavItems = "Sidebar-module--subnavItems--19374";
export var text_info = "Sidebar-module--text_info--3ae99";
export var text_location = "Sidebar-module--text_location--6dc42";