import React, { useState, useEffect, useMemo } from "react";
import { isEqual as _isEqual } from "lodash";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import ConsumerQueue from "consumer-queue";
import queryString from "query-string";
import { useQuery, useSubscription } from "@redux-requests/react";
import { Toaster } from "react-hot-toast";
import { usePostHog } from "posthog-js/react";

import {
  fetchPage,
  onPageFetched,
} from "@col-care/common/providers/contentstack/content-types/page/actions";
import {
  FETCH_PAGE,
  ON_PAGE_FETCHED,
} from "@col-care/common/providers/contentstack/content-types/page/constants";
import { determineAutoLoad } from "@col-care/common/utilities/index";
import { useDataDogRum } from "../effects/useDataDogRum";
import { useHub } from "@col-care/common/providers/cognito/useHub";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";
import {
  transformLayoutData,
  transformSiteConfigData,
  transformBuildInfoData,
  transformLocationData,
  transformDatabagData,
} from "@col-care/common/providers/contentstack/modular-blocks";

import Layout from "./Layout";
// import Notifications from "./notifications/Notifications"
import Modal from "./modal/Modal";
import AppScripts from "./AppScripts";

const App = (props) => {
  // console.log("App.js props", props);
  const store = getReduxStore("init", props.location.pathname, props).store;
  const actions = getBoundActions(store.dispatch);
  const state = store.getState();

  const [isAppMounted, setIsAppMounted] = useState(false);
  const [isHydrateMounted, setIsHydrateMounted] = useState(false);
  const [hydrateData, setHydrateData] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const [modalQueue, setModalQueue] = useState(new ConsumerQueue());
  const [activeModal, setActiveModal] = useState(null);
  const [hideActiveModal, setHideActiveModal] = useState(false);
  const [userShownAgeGate, setUserShownAgeGate] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState(null);

  const [disableClientFetch, setDisableClientFetch] = useState(false);

  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};

  const allowedPages =
    props.pageResources?.json?.pageContext?.site_config?.features?.dynamic_pages
      ?.page || null;

  useQuery({
    type: FETCH_PAGE,
    action: fetchPage,
    autoLoad: false,
    autoLoad: determineAutoLoad(
      cookies,
      props.location,
      disableClientFetch,
      allowedPages
    ),
    variables: [props.location.pathname, "site_page"],
  });

  useSubscription({
    type: ON_PAGE_FETCHED,
    action: onPageFetched,
  });

  useEffect(() => {
    if (!isAppMounted) {
      setIsAppMounted(true);

      console.log("Mounting App.js", props.location.pathname);

      window._uxa = window._uxa || [];
      window._uxa.push([
        "setPath",
        window.location.pathname + window.location.hash.replace("#", "?__"),
      ]);
      console.log(
        "setPath",
        window.location.pathname + window.location.hash.replace("#", "?__")
      );

      // window.addEventListener("storage", function (e) {
      //   if (e.storageArea == localStorage) {
      //     window.location.href = window.location.protocol + '//' + window.location.host
      //   }
      // })

      actions.auth.doCurrentAuthenticatedUser();

      const parsed = queryString.parse(props.location.search);
      if (parsed.disableClientFetch) {
        if (parsed.disableClientFetch === "true") {
          setDisableClientFetch(true);
          cookies.set("dcf", true, { path: "/" });
        } else if (parsed.disableClientFetch === "false") {
          setDisableClientFetch(false);
          cookies.set("dcf", false, { path: "/" });
        } else {
          console.warn(`You passed an invalid value for "disableClientFetch"`);
        }
      }
    }
  }, [isAppMounted]);

  // @todo: figure out how to get modalQueue to trigger this reliably
  useEffect(() => {
    if (hydrateData && !activeModal) {
      const modal = modalQueue.tryPop();
      if (modal) {
        setActiveModal(modal);
      }
    }
  }, [activeModal, modalQueue, hydrateData]);

  const locations = state.hydrate.databagData?.locations;
  const storeId = state.session.cartData.store.janeId;

  const locationsByStoreId = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const currentStore = locationsByStoreId[storeId];
  let hostname;
  if (typeof window === "object") {
    hostname =
      window.location.hostname === "localhost"
        ? `http://localhost:${window.location.port}`
        : `${window.location.origin}`;
  } else {
    hostname = "https://www.tgscolorado.shop";
  }

  const apiPath = `/page-data/___api/store-specials`;

  useEffect(() => {
    const path = `${hostname}${apiPath}/${currentStore?.content?.id}/page-data.json`;
    const storeId = currentStore?.content?.id;

    if (
      storeId &&
      currentStore?.content?.id &&
      storeId !== state.session?.specialsData?.storeId
    ) {
      fetch(path)
        .then((response) => {
          response.json().then((data) => {
            const apiData = data.result.pageContext.content.apiData;
            if (
              apiData?.specials &&
              Object.keys(apiData?.specials).length > 0
            ) {
              setApiData(apiData);

              const accumulator = {};
              if (apiData?.specials?.med?.data?.specials) {
                accumulator["med"] = apiData?.specials?.med?.data?.specials;
              }
              if (apiData?.specials?.rec) {
                accumulator["rec"] = apiData?.specials?.rec?.data?.specials;
              }
              if (Object.keys(accumulator).length > 0) {
                actions.session.doSetSpecialsData(accumulator);
              }
            } else {
              console.log("no specials API data found");
              setApiData(null);
            }
          });
        })
        .catch((err) => {
          setApiError(err);
        });
    }
  }, [storeId, locations]);

  useEffect(() => {
    if (props.location.pathname !== currentPath) {
      setCurrentPath(props.location.pathname);
      setHydrateData(props.pageResources.json.pageContext);
      setIsHydrateMounted(true);

      window._uxa.push([
        "trackPageview",
        window.location.pathname + window.location.hash.replace("#", "?__"),
      ]);
      console.log(
        "trackPageview",
        window.location.pathname + window.location.hash.replace("#", "?__")
      );

      setHideActiveModal(false);

      return () => {
        setHydrateData(null);
        setIsHydrateMounted(false);
      };
    }
  }, [currentPath, props.location.pathname]);

  useEffect(() => {
    if (isHydrateMounted) {
      if (hydrateData) {
        actions.hydrate.doSetLayoutData(
          transformLayoutData(hydrateData.content)
        );

        if (
          !_isEqual(
            state.hydrate.siteConfigData,
            transformSiteConfigData(hydrateData.site_config)
          )
        ) {
          actions.hydrate.doSetSiteConfigData(
            transformSiteConfigData(hydrateData.site_config)
          );
        }

        if (
          !_isEqual(
            state.hydrate.buildInfoData,
            transformBuildInfoData(hydrateData.build_info)
          )
        ) {
          actions.hydrate.doSetBuildInfoData(
            transformBuildInfoData(hydrateData.build_info)
          );
        }

        if (
          !_isEqual(
            state.hydrate.databagData,
            transformDatabagData(hydrateData.databag)
          )
        ) {
          // @todo: cheap hack to fix empty objs that happen on 404
          // it merges the store obj fine but just annoying
          if (hydrateData.databag) {
            actions.hydrate.doSetDatabagData(
              transformDatabagData(hydrateData.databag)
            );
          }
        }

        if (
          hydrateData.__type === "allContentstackPartnerLocation" &&
          !_isEqual(
            state.hydrate.locationData,
            transformLocationData(hydrateData.content)
          )
        ) {
          actions.hydrate.doSetLocationData(
            transformLocationData(hydrateData.content)
          );
        }
        const ageGateData =
          state.hydrate.siteConfigData?.blocks &&
          state.hydrate.siteConfigData?.blocks["interstitial--age-gate"]
            ? state.hydrate.siteConfigData?.blocks["interstitial--age-gate"]
                .component_data
            : null;

        if (ageGateData) {
          const ageGateModal = (
            <Modal
              buttonOpts={["tgssmall", "tgsTransparent"]}
              modalTitle={ageGateData.title}
              modalBodyText={ageGateData.body}
              variant={ageGateData.variant}
              confirmButtonText="YES, I AM"
              confirmButtonCallback={(v) => {
                const thisDate = new Date();
                thisDate.setDate(thisDate.getDate() + 1);
                cookies.set("upag", v, { path: "/", expires: thisDate });
              }}
              dismissModal={() => setActiveModal(modalQueue.tryPop())}
              extraClasses="modal-dialog-age-gate"
              shouldCloseOnOverlayClick={false}
              hide={hideActiveModal}
            />
          );

          let userHasPassedAgeGate =
            (cookies.cookies.upag && cookies.cookies.upag) >
              Date.now() - 60 * 60 * 24 * 1000 || false;
          if (!userHasPassedAgeGate) {
            if (hideActiveModal) {
              setActiveModal(null);
            } else {
              setUserShownAgeGate(true);
              const nextModalQueue = modalQueue;
              nextModalQueue.push(ageGateModal);
              setModalQueue(nextModalQueue);
              // @todo: useEffect not detecting change to ConsumerQueue object
              const modal = modalQueue.tryPop();
              setActiveModal(modal);
            }
          }
        }
      }
    }
  }, [
    isHydrateMounted,
    hydrateData,
    actions.hydrate,
    modalQueue,
    userShownAgeGate,
    hideActiveModal,
  ]);

  useDataDogRum(isAppMounted);
  useHub();

  return (
    <>
      <Toaster />
      <Layout>{props.children}</Layout>
      <AppScripts />
      {activeModal}
    </>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

App.displayName = "App";

export default App;
