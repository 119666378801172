import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { map as _map, reduce as _reduce } from "lodash";
import { toast } from "react-hot-toast/headless";

import Button from "../../button/Button";
// import { getStoreType, getFormattedStoreHours } from "@col-care/common/common";
import CloseIconBlack from "@col-care/common/elements/icons/CloseIconBlack";
import TrashIcon from "@col-care/common/elements/icons/TrashIcon";
import GreenCheck from "@col-care/common/elements/icons/GreenCheck";
import HitImage from "@col-care/common/blocks/product-category-hit-image";
import { posthogEvents } from "@col-care/common/utilities/constants";
import {
  capturePosthogLink,
  capturePosthogEvent,
} from "@col-care/common/utilities/index";
import * as modalStyles from "../modal.module.css";

const Cart = (props) => {
  const state = useSelector((state) => state);
  const cartData = state.session.cartData;
  const location = props.currentStore;
  const cartActions = props.actions;
  const user = props.user;
  const posthog = props.posthog;

  // let thisTimeout;

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(thisTimeout);
  //   };
  // }, []);

  if (!location) {
    return (
      <div className={modalStyles.CartInner}>
        <div className={modalStyles.CartTextWrapper}>
          <h2 id="modal-title">{`Cart`}</h2>

          <div>
            <h3>You're Shopping At:</h3>
            <h4>Nowhere!</h4>
          </div>
        </div>
      </div>
    );
  }

  // const formattedStoreHours =
  //   location.content && location.content.hours
  //     ? getFormattedStoreHours(location.content.hours)
  //     : null;

  // const buttonOpts = props.buttonOpts || ["tgssmall", "tgsButton"];
  const calculateSubtotal = () => {
    let subtotal = 0;
    cartData.products.forEach((item) => {
      let price = 0;
      if (item.itemSpecial && item.itemSpecial.discount_price) {
        price = item.itemSpecial.discount_price;
      } else if (item.itemSpecial && item.itemSpecial.price) {
        price = item.itemSpecial.price;
      } else {
        price = item.price; // Fall back to item price
      }
      subtotal += price * item.count;
    });
    return subtotal.toFixed(2);
  };
  const renderOtherItems = () => {
    if (!cartData || !cartData.products || cartData.products.length === 0) {
      return null; // No items in the cart
    }

    return (
      <div className={modalStyles.other__items}>
        <h5>YOUR CART:</h5>
        <table className={modalStyles.qty_table}>
          <thead>
            <tr>
              <th>QTY</th>
              <th>ITEM</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {cartData.products.map((item, index) => (
              <tr key={index}>
                <td>{item.count}</td>
                <td>{item.name}</td>
                <td>
                  {item.itemSpecial && item.itemSpecial.discount_price ? (
                    <div className={modalStyles.price__discount_table}>
                      <div className={modalStyles.price__discount}>
                        $
                        {parseFloat(
                          item.itemSpecial?.discount_price * item.count
                        ).toFixed(2)}
                      </div>
                      <div className={modalStyles.price__strikethru}>
                        ${parseFloat(item.price * item.count).toFixed(0)}
                      </div>
                    </div>
                  ) : (
                    <span>
                      ${parseFloat(item.price * item.count).toFixed(2)}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div id={modalStyles.subTotal}>
          <div>SUBTOTAL</div>
          <div>${calculateSubtotal()}</div>
        </div>
      </div>
    );
  };

  const children = (
    <div className={modalStyles.CartInner}>
      <div className={modalStyles.CartTextWrapper}>
        <div className={modalStyles.CartHeader}>
          {cartData.products.length > 0 && (
            <div>
              <GreenCheck />
              <span>Added to Cart!</span>
            </div>
          )}
          <div className={modalStyles.cart__dismiss}>
            <div
              className={modalStyles.CartDismiss}
              aria-label="Dismiss"
              key={`cart-dismiss-modal`}
              onClick={(e) => {
                if (props?.dismissModal) props?.dismissModal();

                // setTimeout(() => {
                //   const scrollTo = document.getElementById("app");
                //   window.scroll({ top: scrollTo, behavior: "smooth" });
                // }, 1000);
              }}
            >
              <CloseIconBlack color="#375542" />
            </div>
          </div>
        </div>
        <div className={modalStyles.cart__container}>
          <div className={modalStyles.cart__items_section}>
            <div>
              <ul className={modalStyles.cart__items}>
                <CartItems
                  actions={cartActions}
                  cartData={cartData}
                  {...props}
                />
              </ul>
            </div>
            {renderOtherItems()}
          </div>
        </div>
        {cartData.products.length > 0 ? (
          <div id={modalStyles.total}>
            {/*<div className={modalStyles.subTotal}>
                  <h3>Subtotal</h3>
                  <h4>
                    $
                    {(() =>
                      _reduce(
                        cartData.products,
                        (a, v) => {
                          a = a + v.price * v.count;
                          return a;
                        },
                        0.0
                      ).toFixed(2))()}
                  </h4>
                </div>
                      */}
            <div className={modalStyles.cart__btns}>
              {state.hydrate.siteConfigData.features.authentication.status ===
              "disabled" ? (
                <>
                  <Button
                    id="place-order-button"
                    theme="checkOutButtonTGS"
                    label={`Review And Place Order`}
                    disabled={cartData.products.length === 0}
                    style={{
                      cursor:
                        cartData.products.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      const link = "/checkout";
                      props.dismissModal();
                      navigate(link);
                      capturePosthogEvent({
                        eventName: posthogEvents.ADD_TO_CART_MODAL_CLOSED,
                        posthog,
                      });
                      capturePosthogLink({
                        eventName: posthogEvents.NAVIGATION,
                        title: "Review And Place Order",
                        to: link,
                        databag: {
                          cartData,
                        },
                        posthog,
                      });
                    }}
                  />

                  <Button
                    theme="tgsTransparentGreenCart"
                    label={`Keep Shopping`}
                    id="keep-shopping-button"
                    disabled={cartData.products.length === 0}
                    style={{
                      cursor:
                        cartData.products.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      props.dismissModal();
                      capturePosthogEvent({
                        eventName: posthogEvents.ADD_TO_CART_MODAL_CLOSED,
                        title: "Keep Shopping button clicked",
                        posthog,
                      });

                      // setTimeout(() => {
                      //   const scrollTo = document.getElementById("app");
                      //   window.scroll({ top: scrollTo, behavior: "smooth" });
                      // }, 100);
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    theme="checkOutButtonTGS"
                    label={
                      !user
                        ? `Sign In / Sign Up To Order`
                        : `Review And Place Order`
                    }
                    disabled={cartData.products.length === 0}
                    style={{
                      cursor:
                        cartData.products.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    id={
                      !user ? "sign-in-button" : "review-and-place-order-button"
                    }
                    onClick={(e) => {
                      props.dismissModal();

                      capturePosthogEvent({
                        eventName: posthogEvents.ADD_TO_CART_MODAL_CLOSED,
                        posthog,
                      });
                      capturePosthogLink({
                        eventName: posthogEvents.NAVIGATION,
                        title: !user
                          ? `Sign In / Sign Up To Order`
                          : `Review And Place Order`,
                        to: navigate(user ? "/user" : "/checkout"),
                        databag: {
                          cartData,
                        },
                        posthog,
                      });

                      if (user) {
                        navigate("/checkout");
                      } else {
                        navigate("/user");
                      }
                    }}
                  />

                  <Button
                    theme="tgsTransparentGreenCart"
                    label={!user ? `Continue As Guest` : `Keep Shopping`}
                    disabled={cartData.products.length === 0}
                    id={
                      !user
                        ? "continue-as-guest-button"
                        : "keep-shopping-button"
                    }
                    style={{
                      cursor:
                        cartData.products.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={(e) => {
                      props.dismissModal();

                      capturePosthogEvent({
                        eventName: posthogEvents.ADD_TO_CART_MODAL_CLOSED,
                        title: !user ? `Continue As Guest` : `Keep Shopping`,
                        posthog,
                      });

                      if (!user) {
                        navigate("/checkout");
                        capturePosthogLink({
                          eventName: posthogEvents.NAVIGATION,
                          title: `Keep Shopping`,
                          to: "/checkout",
                          databag: {
                            cartData,
                          },
                          posthog,
                        });
                      } else {
                        // const scrollTo =
                        //   document.getElementById("item-container");
                        // window.scroll({ top: scrollTo, behavior: "smooth" });
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
        ) : null}

        <div id={modalStyles.ymal__section}>{props.children}</div>
      </div>
    </div>
  );

  return children;
};

Cart.displayName = "Cart";

export default Cart;

const CartItems = (props) => {
  const actions = props.actions;
  const posthog = props.posthog;
  const cartData = props.cartData;

  let lastItem;

  const itemInCart = props.cartData.products.map((item) => {
    lastItem = item;
    if (item.timestamp && item.timestamp <= lastItem.timestamp) {
      lastItem = item;
    }
  });

  if (props.cartData.products.length === 0) {
    return (
      <div className={modalStyles.empty_header_container}>
        <h3 id={modalStyles.empty_header}>Your cart is empty!</h3>
        <p>View the menu to add items to your cart!</p>
        <Button
          theme="checkOutButtonTGS"
          label={`PRE-ORDER NOW`}
          id="pre-order-now-button"
          onClick={(e) => {
            props.dismissModal();
            navigate("/shop/" + props.currentStore?.content?.id);
            capturePosthogEvent({
              eventName: posthogEvents.ADD_TO_CART_MODAL_CLOSED,
              title: "PRE-ORDER NOW",
              posthog,
            });
            capturePosthogLink({
              eventName: posthogEvents.NAVIGATION,
              title: "PRE-ORDER NOW",
              to: navigate("/shop/" + props.currentStore?.content?.id),
              databag: {
                cartData,
              },
              posthog,
            });
          }}
        />
      </div>
    );
  }

  if (!lastItem) {
    return null;
  }

  return (
    <ul className={modalStyles.cart__items}>
      <li>
        <div className={modalStyles.items_in_cart}>
          <div>
            {/* <img
              src={`${janeFallBackImage(lastItem)}`}
              alt={`${lastItem.name}`}
            /> */}
            <HitImage hit={lastItem} />
          </div>
          <div className={modalStyles.item__info}>
            <h4>{`${lastItem.name}`}</h4>
            <h5>{`${lastItem.brand}`}</h5>
            {lastItem.itemSpecial && lastItem.itemSpecial.discount_price ? (
              <div className={modalStyles.discount__section}>
                <h6 className={modalStyles.price__discount}>{`$${parseFloat(
                  lastItem.itemSpecial?.discount_price
                ).toFixed(2)}`}</h6>
                <div className={modalStyles.discount__old}>{`$${parseFloat(
                  lastItem.itemSpecial?.price
                ).toFixed(0)}`}</div>
              </div>
            ) : (
              <h6>{`$${parseFloat(lastItem.price).toFixed(2)}`}</h6>
            )}
            <div className={modalStyles.items_in_cart_add}>
              <button
                className={modalStyles.add_to_cart_button}
                id="decrease-quantity-button"
                style={{
                  cursor: lastItem.count === 1 ? "not-allowed" : "pointer",
                  opacity: lastItem.count === 1 ? "0.5" : "1",
                }}
                onClick={() => {
                  if (lastItem.count > 1) {
                    actions.session.doDecrementQuantity(lastItem);
                    toast.dismiss();
                  }
                }}
              >
                -
              </button>
              <div className={modalStyles.count}>{lastItem.count}</div>
              <button
                className={modalStyles.add_to_cart_button}
                id="increase-quantity-button"
                onClick={() => {
                  const maxQtyForItem = lastItem.maxQty || null;

                  if (maxQtyForItem === undefined) {
                    actions.session.doIncrementQuantity(lastItem);
                    toast.dismiss();
                  } else if (maxQtyForItem <= lastItem.count) {
                    toast.dismiss();
                    toast.error("Quantity limit reached");
                  } else {
                    actions.session.doIncrementQuantity(lastItem);
                    toast.dismiss();
                  }
                }}
              >
                +
              </button>
            </div>
            <div
              onClick={() => {
                actions.session.doRemoveItem(lastItem);
                toast.dismiss();
              }}
              className={modalStyles.trash__section}
            >
              <TrashIcon className={modalStyles.trash__icon} />
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};
