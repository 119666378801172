export const JaneEventsActions = {
    LOADING_EVENT: "loadingEvent",
    ANALYTICS_EVENT: "analyticsEvent",
    CART_PAGE_LOAD:"cartPageLoad",
    SCROLL_TO_TOP: "scrollToTop",
    SCROLL_TO_BUCKET_TOP: "SCROLL_TO_BUCKET_TOP",
    GET_JANE_DEVICE_ID: "getJaneDeviceId",
    RESIZE_IFRAME: "resizeIFrame",
    SAVE_SCROLL_POSITION: "saveScrollPosition",
    RESTORE_SCROLL_POSITION: "restoreScrollPosition",
    RESPOND_GET_JANE_DEVICE_ID: "respondGetJaneDeviceId",
    SET_MARGIN_TO_IFRAME_TOP: "setMarginToIframeTop",
    MOBILE_MODAL: "openMobileModal",
    CLOSE_MOBILE_MODAL: "closeMobileModal",
    OPEN_DESKTOP_FILTERS: "openDesktopFilters",
    SHRINK_NEXT_RESIZE: "shrinkNextResize",
    GET_IS_DEEP_LINK: "getIsDeepLink",
    INIT_EMBEDDED_OPTIONS: "initEmbeddedOptions",
    CART_ITEM_ADD: "cartItemAdd",
    CART_CREATE_OR_UPDATE:"cartCreateOrUpdate",
    EMBEDDED_APP_LOADED: "embeddedAppLoaded",
    HEADLESS_APP_LOADED: "headlessAppLoaded",
    CHECKOUT: "checkout"
}

export const JaneEmbedActions = {
    INITIAL_STATE: "INITIAL_STATE",
    MOUNTED: "MOUNTED",
    EMBED_CONFIG: "EMBED_CONFIG",
    CHECKOUT_COMPLETED: "CHECKOUT_COMPLETED",
    CHECKOUT_UPDATED_CART: "CHECKOUT_UPDATED_CART",
    EMBED_LOADED: "EMBED_LOADED",
    JANE_DEVICE_ID: "JANE_DEVICE_ID",
    DISTANCE_TO_TOP_OF_WINDOW: "DISTANCE_TO_TOP_OF_WINDOW",
    ORIGINAL_SCROLL_POSITION: "ORIGINAL_SCROLL_POSITION"
}

export const JaneEmbedConstants = {
    JANE_ORIGIN: "https://www.iheartjane.com",
    JANE_EMBED_ID: "jane-menu",
    JANE_CONTAINER_ID: "menu-div",
    JANE_RESIZED_MESSAGE_DELAY: 1000,
    EMBEDDED_SRC: "https://api.iheartjane.com/v1/headless/embed.js"
}