import { Auth } from "aws-amplify";

import { AuthActions } from "./constants.js";

const isDevMode = process.env.NODE_ENV === "development";

const log = (message, data = null) => {
  if (isDevMode) {
    console.log(message);
    if (data) console.log(data);
  }
};

const setAuthStatus = (userData) => ({
  type: AuthActions.SET_AUTH_STATUS,
  payload: userData,
});

const loginSuccess = (userData) => ({
  type: AuthActions.LOGIN_SUCCESS,
  payload: userData,
});

const logoutSuccess = () => ({
  type: AuthActions.LOGOUT_SUCCESS,
});

const signUpSuccess = (userData) => ({
  type: AuthActions.SIGNUP_SUCCESS,
  payload: userData,
});

const confirmSignUpSuccess = (userData) => ({
  type: AuthActions.CONFIRM_SIGNUP_SUCCESS,
  payload: userData,
});

const profileEditSuccess = (userData) => ({
  type: AuthActions.UPDATE_ATTRIBUTES_SUCCESS,
  payload: userData,
});

const forgotPasswordSuccess = (userData) => ({
  type: AuthActions.FORGOT_PASSWORD_SUCCESS,
});

const setCurrentUser = (userData) => ({
  type: AuthActions.SET_CURRENT_USER,
  payload: userData,
});

const forgotPasswordRequest = (userData) => ({
  type: AuthActions.FORGOT_PASSWORD_REQUEST,
  payload: userData,
});

const setAuthError = (error) => ({
  type: AuthActions.SET_AUTH_ERROR,
  payload: error,
});

const resetAuthStatus = () => ({
  type: AuthActions.RESET_AUTH_STATUS,
});

const getUserAttributes = (value) => {
  const attrs = {
    email: value.email,
  };

  if (value.telephone) {
    attrs.phone_number = value.telephone;
  }

  if (value.birthdate) {
    attrs.birthdate = value.birthdate.toISOString
      ? value.birthdate.toISOString().split("T")[0]
      : value.birthdate;
  }

  if (value.firstname) {
    attrs.given_name = value.firstname;
  }

  if (value.lastname) {
    attrs.family_name = value.lastname;
  }

  return attrs;
};

const doLogin = (value) => async (dispatch, getState) => {
  try {
    const username = value.email;
    const password = value.password;

    await Auth.signIn(username, password);
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error signing in:", error);
  }
};

const doLogout = () => async (dispatch) => {
  try {
    await Auth.signOut();
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error signing out:", error);
  }
};

const doSignUp = (value) => async (dispatch) => {
  try {
    const { email, password } = value;

    await Auth.signUp({
      username: email,
      password,
      attributes: getUserAttributes(value),
      autoSignIn: { enabled: true },
    });
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error creating account:", error);
  }
};

const doConfirmSignUp = (value) => async (dispatch) => {
  try {
    const { email, code } = value;
    const status = await Auth.confirmSignUp(email, code);
    // dispatch(confirmSignUpSuccess(status)); // Dispatch success action
    log("Confirm sign up success", status);
  } catch (error) {
    dispatch(setAuthError(error));
    log("Error confirming sign up:", error);
  }
};

const doProfileEdit = (value, user) => async (dispatch) => {
  try {
    const updatedAttributes = getUserAttributes(value);
    const payload = await Auth.updateUserAttributes(user, updatedAttributes);
    log("User update", payload);
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error editing account:", error);
  }
};

export const doCurrentAuthenticatedUser = () => async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    dispatch({ type: AuthActions.AUTH_USER_FOUND, payload: user });
  } catch (error) {
    if (error === "The user is not authenticated") {
      dispatch({ type: AuthActions.AUTH_NO_USER });
    } else {
      dispatch({ type: AuthActions.AUTH_ERROR, payload: error });
    }
  }
};

const doForgotPassword = (value) => async (dispatch) => {
  try {
    const username = value.email;
    const status = await Auth.forgotPassword(username);
    dispatch(forgotPasswordRequest({ email: username }));
    log("Forgot password status", status);
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error resetting password:", error);
  }
};

const doResetPassword = (value) => async (dispatch) => {
  try {
    const { email, code, password } = value;
    const status = await Auth.forgotPasswordSubmit(email, code, password);
    log("Forgot password submit status", status);
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error setting new password:", error);
  }
};

const doRememberDevice = () => async (dispatch) => {
  try {
    await Auth.rememberDevice();
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error remembering device:", error);
  }
};

const doForgetDevice = () => async (dispatch) => {
  try {
    await Auth.forgetDevice();
  } catch (error) {
    dispatch(doSetAuthError(error));
    log("Error forgetting device:", error);
  }
};

const doResendVerification = (value) => async (dispatch) => {
  try {
    const { email } = value;
    await Auth.resendSignUp(email);
    dispatch({
      type: AuthActions.RESEND_VERIFICATION_REQUEST,
      payload: { email },
    });
    // Success handling will be done via Hub listener, no need to dispatch success here
  } catch (error) {
    dispatch(doSetAuthError(error));
  }
};

const doSetAuthError = (error) => (dispatch) => {
  dispatch(setAuthError(error));
};

const doResetAuthStatus = () => (dispatch) => {
  dispatch(resetAuthStatus());
};

export default {
  setAuthStatus,
  loginSuccess,
  logoutSuccess,
  signUpSuccess,
  confirmSignUpSuccess,
  profileEditSuccess,
  forgotPasswordSuccess,
  setCurrentUser,
  forgotPasswordRequest,
  setAuthError,
  resetAuthStatus,
  doLogin,
  doLogout,
  doSignUp,
  doConfirmSignUp,
  doCurrentAuthenticatedUser,
  doForgotPassword,
  doResetPassword,
  doProfileEdit,
  doRememberDevice,
  doForgetDevice,
  doResendVerification,
  doSetAuthError,
  doResetAuthStatus,
};
