import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let quoteSchema = object().shape({
  heading: string().nullable(),
  statement: string().nullable(),
  enabled: bool().nullable(),
  cta_title: string().nullable(),
  quote_background_color: string().nullable(),
  cta_href: string().nullable(),
  icon_url: string().nullable(),
  icon_title: string().nullable(),
  icon_description: string().nullable(),
  styles: string().nullable(),
  __digest: string().required(),
});

export const transformQuoteData = (data) => {
  // console.log('transformQuote', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      statement: null,
      enabled: null,
      cta_title: null,
      cta_href: null,
      icon_url: null,
      icon_title: null,
      styles: null,
      quote_background_color: null,
      icon_description: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.heading = data.quote_heading;
    hydratedModel.styles = data.styles;
    hydratedModel.statement = data.quote_statement;
    hydratedModel.enabled = data.quote_enabled;
    hydratedModel.quote_background_color = data.quote_background_color;
    hydratedModel.cta_title = data.quote_cta ? data.quote_cta.title : null;
    hydratedModel.cta_href = data.quote_cta ? data.quote_cta.href : null;
    hydratedModel.icon_url = data.quote_icon ? data.quote_icon.url : null;
    hydratedModel.icon_title = data.quote_icon ? data.quote_icon.title : null;
    hydratedModel.icon_description = data.quote_icon
      ? data.quote_icon.description
      : null;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      quoteSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
