import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

import { transformCardData } from "./card";

export let cardsSchema = object().shape({
  heading: string().nullable(),
  enabled: bool().nullable(),
  cta_title: string().nullable(),
  cta_href: string().nullable(),
  style_options_background: string().nullable(),
  style_options_style: string().nullable(),
  heading_icon: string().nullable(),
  cards: array().nullable(),
  __digest: string().required(),
});

export const transformCardsData = (data) => {
  // console.log("transformCards", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      enabled: null,
      style_options_background: null,
      style_options_style: null,
      heading_icon: null,
      cards: null,
      cta_title: null,
      cta_href: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.style_options_style = data.cards_style_options.cards_style;
    hydratedModel.heading = data.cards_heading;
    hydratedModel.heading_icon = data.cards_heading_icon?.url;
    if (data.cards_cta) {
      hydratedModel.cta_title = data.cards_cta.title;
      hydratedModel.cta_href = data.cards_cta.href;
    }
    hydratedModel.cards = data.cards_cards.reduce((acc, block) => {
      acc.push(transformCardData(block));
      return acc;
    }, []);
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      cardsSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
