import actions from "./actions.js";
import { reject as _reject } from "lodash";

export const sessionInitialState = {
  cartData: {
    store: {
      id: null,
      janeId: null,
      name: null,
    },
    products: [],
    specials: [],
    productsCount: null,
    timestamp: null,
  },
  // @todo: this needs to not be in the session but it's okay for now
  specialsData: {
    storeId: null,
    data: {},
    timestamp: null,
  },
};

export const cartItemSchema = {
  productId: null,
  priceId: null,
  count: null,
  price: null,
  type: null,
  brand: null,
  name: null,
  photo: null,
  maxQty: {},
  itemSpecial: null,
  specialTypes: null,
  specialIds: null,
  bundleSpecialIds: null,
  brandSpecialIds: null,
  bulkSpecialIds: null,
  pathname: null,
  timestamp: null,
};

export const sessionReducer = (state = sessionInitialState, action) => {
  let item,
    productsCount = 0,
    newPayload,
    newState;
  switch (action.type) {
    case actions.SET_SPECIALS_DATA:
      newState = state;
      if (state.specialsData && state.cartData.store.id) {
        newState.specialsData.storeId = state.cartData.store.id;
        newState.specialsData.data = action.payload;
      }
      return newState;

    case actions.SET_CART_DATA:
      newPayload = { cartData: { ...action.payload } };
      newPayload.cartData.products = newPayload.cartData.products.filter(
        (x) => x != null
      );
      newPayload.cartData.products.map((v, k) => {
        productsCount = productsCount + v.count;
      }, productsCount);
      newPayload.cartData.productsCount = productsCount;
      return newPayload;

    case actions.ADD_TO_CART:
      if (!action.payload) {
        return state;
      }
      let key;
      const itemInCart = state.cartData.products.find((item, index) => {
        key = index;
        return item && item.productId === action.payload.productId;
      });
      if (itemInCart) {
        itemInCart.count = itemInCart.count + action.payload.count;
        itemInCart.timestamp = Date.now();
        state.cartData.products[key] = itemInCart;
      } else {
        state.cartData.products.push(action.payload);
      }
      state.cartData.products.map((v, k) => {
        productsCount = productsCount + v.count;
      }, productsCount);
      state.cartData.productsCount = productsCount;
      return { cartData: state.cartData, specialsData: state.specialsData };

    case actions.INCREMENT_QUANTITY:
      item = state.cartData.products.find(
        (item) => item.productId === action.payload.productId
      );
      item.count++;
      item.timestamp = Date.now();
      state.cartData.products.map((v, k) => {
        productsCount = productsCount + v.count;
      }, productsCount);
      state.cartData.productsCount = productsCount;
      return { cartData: state.cartData, specialsData: state.specialsData };

    case actions.DECREMENT_QUANTITY:
      item = state.cartData.products.find(
        (item) => item.productId === action.payload.productId
      );
      if (item.count === 1) {
        item.count = 1;
      } else {
        item.count--;
      }
      item.timestamp = Date.now();
      state.cartData.products.map((v, k) => {
        productsCount = productsCount + v.count;
      }, productsCount);
      state.cartData.productsCount = productsCount;
      return { cartData: state.cartData, specialsData: state.specialsData };

    case actions.REMOVE_ITEM:
      state.cartData.products = _reject(
        state.cartData.products,
        (item) => item.productId === action.payload.productId
      );
      state.cartData.products.map((v, k) => {
        productsCount = productsCount + v.count;
      }, productsCount);
      state.cartData.productsCount = productsCount;
      return { cartData: state.cartData, specialsData: state.specialsData };

    default:
      return state;
  }
};
