import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let statementSchema = object().shape({
  text: string().nullable(),
  icon: string().nullable(),
  enabled: bool().nullable(),
  __digest: string().required(),
});

export const transformStatementData = (data) => {
  // console.log('transformStatement', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      text: null,
      icon: null,
      enabled: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.text = data.text_statement;
    hydratedModel.enabled = data.statement_enabled;
    hydratedModel.icon = data.logo_statement ? data.logo_statement.url : null;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      statementSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
