import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { reduce as _reduce, isNaN as _isNaN } from "lodash";
import add from "date-fns/add";
import { format } from "date-fns";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";

export const GhubLink = ({
  className,
  label,
  destination,
  attributes,
  children,
  posthogEvent = {
    posthog: null,
  },
}) => {
  const onClickHandler = () => {
    if (attributes?.onClick) {
      attributes?.onClick();
    }
    capturePosthogLink({
      to: destination,
      title: label,
      eventName:
        destination.indexOf("/") === 0
          ? posthogEvents.NAVIGATION
          : posthogEvents.EXTERNAL_NAVIGATION,
      databag: null,
      ...posthogEvent,
    });
  };

  if (destination.indexOf("/") === 0) {
    return (
      <Link
        className={className}
        to={destination}
        aria-label={label}
        onClick={onClickHandler}
      >
        {children ? children : label}
      </Link>
    );
  } else {
    return (
      <OutboundLink
        className={className}
        href={destination}
        aria-label={label}
        onClick={onClickHandler}
        onMouseEnter={attributes && attributes.onMouseEnter}
        onKeyDown={attributes && attributes.onKeyDown}
        target="_blank"
        rel="noreferrer"
      >
        {children ? children : label}
      </OutboundLink>
    );
  }
};

export const getHtmlParseOptions = () => {
  return {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === "a") {
        if (domNode.attribs.href && domNode.attribs.href.indexOf("#") > -1) {
          return (
            <AnchorLink
              to={domNode.attribs.href}
              title={domNode.children[0]?.data || ""}
            ></AnchorLink>
          );
        } else if (domNode.attribs.href) {
          if (domNode.attribs.href.indexOf("/") === 0) {
            return (
              <Link to={domNode.attribs.href} target={domNode.attribs.target}>
                {domNode.children[0]?.data || ""}
              </Link>
            );
          } else {
            return (
              <OutboundLink
                href={domNode.attribs.href}
                target={domNode.attribs.target}
              >
                {domNode.children[0]?.data || ""}
              </OutboundLink>
            );
          }
        }
      }
      return domNode;
    },
  };
};

// @todo: these need to be moved to helper file
// this file is for markup generating functions
// i could perhaps make that more clear

export const getStoreType = (location) => {
  switch (true) {
    case Boolean(
      location.content.vendor_integrations.jane_rec_id &&
        location.content.vendor_integrations.jane_med_id
    ):
      return "Medical and Recreational";
    case Boolean(location.content.vendor_integrations.jane_rec_id):
      return "Recreational Only";
    case Boolean(location.content.vendor_integrations.jane_rec_id):
      return "Medical Only";
    default:
      return "";
  }
};

// @todo: need the schemas tho
export const getFormattedStoreHours = (storeHours) => {
  const newDate = () => {
    return new Date(1969, 3, 20);
  };

  return _reduce(
    storeHours,
    (a, v, k) => {
      const timeInt = parseInt(v);
      if (_isNaN(timeInt)) {
        return a;
      }
      const segments = (timeInt / 100).toString().split(".");
      const hours = segments[0];
      const minutes = segments[1];

      const result = add(newDate(), {
        hours: hours,
        minutes: minutes,
      });

      try {
        a[k.replace("store_hours_", "")] = format(result, "h:mmaaa");
      } catch (err) {
        console.log(err);
      }

      return a;
    },
    {}
  );
};

export const displayFormattedStoreHours = (formattedStoreHours) => {};
