import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let testimonialBlockSchema = object().shape({
    name: string().nullable(),
    text: string().nullable(),
  __digest: string().required(),
});

export const transformTestimonialBlockData = (data) => {
  // console.log("transformTestimonail", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
     name: null,
      text: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.text = data.testimonial_message;
    hydratedModel.name = data.testimonial_name;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      testimonialBlockSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

