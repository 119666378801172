import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let fullWidthMediaSchema = object().shape({
  title: string().nullable(),
  enabled: string().nullable(),
  image_url: string().nullable(),
  image_title: string().nullable(),
  image_description: string().nullable(),
  video_url: string().nullable(),
  video_title: string().nullable(),
  video_description: string().nullable(),
  video_poster_url: string().nullable(),
  video_poster_title: string().nullable(),
  video_poster_description: string().nullable(),
  __digest: string().required(),
});

export const transformFullWidthMediaData = (data) => {
  // console.log('transformFullWidthMedia', data)
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      title: null,
      enabled: null,
      image_url: null,
      image_title: null,
      image_description: null,
      video_url: null,
      video_title: null,
      video_description: null,
      video_poster_url: null,
      video_poster_title: null,
      video_poster_description: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.title = data.full_width_media_title;
    hydratedModel.enabled = data.full_width_media_enabled;
    hydratedModel.image_url = data.full_width_media_image
      ? data.full_width_media_image.url
      : null;
    hydratedModel.image_title = data.full_width_media_image
      ? data.full_width_media_image.title
      : null;
    hydratedModel.image_description = data.full_width_media_image
      ? data.full_width_media_image.description
      : null;
    hydratedModel.video_url = data.full_width_media_video
      ? data.full_width_media_video.url
      : null;
    hydratedModel.video_title = data.full_width_media_video
      ? data.full_width_media_video.title
      : null;
    hydratedModel.video_description = data.full_width_media_video
      ? data.full_width_media_video.description
      : null;
    hydratedModel.video_poster_url = data.full_width_media_video_poster
      ? data.full_width_media_video_poster.url
      : null;
    hydratedModel.video_poster_title = data.full_width_media_video_poster
      ? data.full_width_media_video_poster.title
      : null;
    hydratedModel.video_poster_description = data.full_width_media_video_poster
      ? data.full_width_media_video_poster.description
      : null;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      fullWidthMediaSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

// page_full_width_media {
//   full_width_media_enabled
//   full_width_media_title
//   full_width_media_image {
//     url
//     title
//     description
//   }
//   full_width_media_video_poster {
//     url
//     title
//     description
//   }
//   full_width_media_video {
//     url
//     title
//     description
//   }
// }
