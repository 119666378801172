import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

import { transformSplitScreenLinksBlockData } from "./block";

export let splitScreenLinksSchema = object().shape({
  heading: string().nullable(),
  enabled: bool().nullable(),
  blocks: array().nullable(),
  color_options: string().nullable(),
  alignment: string().nullable(),
  __digest: string().required(),
});

export const transformSplitScreenLinksData = (data, legacy = true) => {
  // console.log("transformSplitScreenLinks", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      heading: null,
      enabled: null,
      color_options: null,
      blocks: null,
      alignment: null,
      __digest: null,
    });

    // map the field names here
    if (legacy) {
      hydratedModel.heading = data.split_screen_links_heading;
      hydratedModel.enabled = data.split_screen_links_enabled;
      hydratedModel.color_options =
        data.split_screen_links_styles.split_screen_links_color_options;
      hydratedModel.alignment =
        data.split_screen_links_styles.split_screen_links_alignment;
      hydratedModel.blocks = data.split_screen_links_blocks.reduce(
        (acc, block) => {
          acc.push(transformSplitScreenLinksBlockData(block, legacy));
          return acc;
        },
        []
      );
    }
    else {
      hydratedModel.heading = data.super_split_screen_split_screen_links_heading;
      hydratedModel.enabled = data.super_split_screen_split_screen_links_enabled;
      hydratedModel.color_options =
        data.super_split_screen_split_screen_links_styles.super_split_screen_split_screen_links_color_options;
      hydratedModel.alignment =
        data.super_split_screen_split_screen_links_styles.super_split_screen_split_screen_links_alignment;
      hydratedModel.blocks = data.super_split_screen_split_screen_links_blocks.reduce(
        (acc, block) => {
          acc.push(transformSplitScreenLinksBlockData(block, legacy));
          return acc;
        },
        []
      );
    }
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      splitScreenLinksSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
