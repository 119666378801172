exports.components = {
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */),
  "component---src-templates-checkout-success-js": () => import("./../../../src/templates/checkout--success.js" /* webpackChunkName: "component---src-templates-checkout-success-js" */),
  "component---src-templates-checkout-v-2-js": () => import("./../../../src/templates/checkout-v2.js" /* webpackChunkName: "component---src-templates-checkout-v-2-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-shop-category-name-js": () => import("./../../../src/templates/shop--[category]--[name].js" /* webpackChunkName: "component---src-templates-shop-category-name-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-shop-store-js": () => import("./../../../src/templates/shop--[store].js" /* webpackChunkName: "component---src-templates-shop-store-js" */),
  "component---src-templates-shop-store-specials-js": () => import("./../../../src/templates/shop--[store]--specials.js" /* webpackChunkName: "component---src-templates-shop-store-specials-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-views-default-js": () => import("./../../../src/views/default.js" /* webpackChunkName: "component---src-views-default-js" */)
}

