import {
    JaneEventsActions,
    JaneEmbedActions,
} from "./constants.js";

export const janeInitialState = {

    //jane cart realted state
    mounted: false,
    embedConfig: null,
    janeCheckoutCompleted: null,
    janeCheckoutUpdatedCart: null,
    initPayload: null,
    cartItemAdded: null,

    janeDeviceId: null,
    janeDistanceToTopOfWindow: 0,
    janeEmbedLoaded: 0,
    janePreventAutoScrolling: false,
    janePreventResizing: false,
    janePreventContainerResizing: false,
    janeOriginalScrollPosition: 0,
    cartPageLoadData: null,
};


export const janeReducer = (state = janeInitialState, action) => {
    switch (action.type) {
        case JaneEmbedActions.INITIAL_STATE:
            return {
                ...janeInitialState,
                cartPageLoadData: state.cartPageLoadData
            };
        case JaneEmbedActions.MOUNTED:
            return {
                ...state,
                mounted: true
            }
        case JaneEmbedActions.EMBED_CONFIG:
            return {
                ...state,
                embedConfig: action.payload
            }
        case JaneEmbedActions.CHECKOUT_COMPLETED:
            return {
                ...state,
                janeCheckoutCompleted: action.payload
            }
        case JaneEmbedActions.CHECKOUT_UPDATED_CART:
            return {
                ...state,
                janeCheckoutUpdatedCart: action.payload
            }
        case JaneEventsActions.INIT_EMBEDDED_OPTIONS:
            return {
                ...state,
                initPayload: action.payload
            }
        case JaneEmbedActions.EMBED_LOADED:
            return {
                ...state,
                janeEmbedLoaded: action.payload
            }
        case JaneEmbedActions.JANE_DEVICE_ID:
            return {
                ...state,
                janeDeviceId: action.payload
            }
        case JaneEmbedActions.DISTANCE_TO_TOP_OF_WINDOW:
            return {
                ...state,
                janeDistanceToTopOfWindow: action.payload
            }
        case JaneEmbedActions.ORIGINAL_SCROLL_POSITION:
            return {
                ...state,
                janeOriginalScrollPosition: action.payload
            }
        case JaneEventsActions.CART_ITEM_ADD:
            return {
                ...state,
                cartItemAdded: action.payload
            }
        case JaneEventsActions.CART_PAGE_LOAD:
            return {
                ...state,
                cartPageLoadData: action.payload
            }
        default:
            return state;
    }
};