export const SET_SPINNER_ACTIVE = "@@col-care/ui/SET_SPINNER_ACTIVE";
export const SET_PASSWORD_RESET_IS_TRANSITIONING =
  "@@col-care/ui/SET_PASSWORD_RESET_IS_TRANSITIONING";
export const SET_PASSWORD_RESET_IS_FIRST_PART =
  "@@col-care/ui/SET_PASSWORD_RESET_IS_FIRST_PART";
export const SET_CONTROL_SENT = "@@col-care/ui/SET_CONTROL_SENT";

export const doSetSpinnerActive = (value) => (dispatch) => {
  dispatch(setSpinnerActive(value));
};
export const doSetPasswordResetIsTransitioning = (value) => (dispatch) => {
  dispatch(setPasswordResetIsTransitioning(value));
};
export const doSetPasswordResetIsFirstPart = (value) => (dispatch) => {
  dispatch(setPasswordResetIsFirstPart(value));
};
export const doSetControlSent = (value) => (dispatch) => {
  dispatch(setControlSent(value));
};

export const setSpinnerActive = (value) => ({
  type: SET_SPINNER_ACTIVE,
  payload: value,
});

export const setPasswordResetIsTransitioning = (value) => ({
  type: SET_PASSWORD_RESET_IS_TRANSITIONING,
  payload: value,
});

export const setPasswordResetIsFirstPart = (value) => ({
  type: SET_PASSWORD_RESET_IS_FIRST_PART,
  payload: value,
});

export const setControlSent = (value) => ({
  type: SET_CONTROL_SENT,
  payload: value,
});

export default {
  SET_SPINNER_ACTIVE: SET_SPINNER_ACTIVE,
  SET_PASSWORD_RESET_IS_TRANSITIONING: SET_PASSWORD_RESET_IS_TRANSITIONING,
  SET_PASSWORD_RESET_IS_FIRST_PART: SET_PASSWORD_RESET_IS_FIRST_PART,
  SET_CONTROL_SENT: SET_CONTROL_SENT,
  doSetSpinnerActive: doSetSpinnerActive,
  doSetPasswordResetIsTransitioning: doSetPasswordResetIsTransitioning,
  doSetPasswordResetIsFirstPart: doSetPasswordResetIsFirstPart,
  doSetControlSent: doSetControlSent,
  setSpinnerActive: setSpinnerActive,
  setPasswordResetIsTransitioning: setPasswordResetIsTransitioning,
  setPasswordResetIsFirstPart: setPasswordResetIsFirstPart,
  setControlSent: setControlSent,
};
