import React from "react";
import { PostHogProvider } from "posthog-js/react";

const POSTHOG_API_HOST = "https://app.posthog.com";

const GhubPostHogProvider = ({
  children,
  apiKey,
  posthog,
  autocapture = true,
  posthogOptions,
}) => {
  if (typeof window === "object") {
    posthog.init(apiKey, {
      api_host: POSTHOG_API_HOST,
      persistence: "cookie", // would use localStorage too but it may cause issues in future with subdomains
      property_blacklist: [], // @todo: on cognito re-enablification this should be updated
      secure_cookie: true, // probably redundant but just in case
      autocapture: autocapture,
    });
  }

  // @todo: could set up persister to show loading indicator
  return (
    <PostHogProvider client={posthog} options={posthogOptions}>
      {children}
    </PostHogProvider>
  );
};

export default GhubPostHogProvider;
