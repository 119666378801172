import { string, object, bool } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let campaignBannerSchema = object().shape({
  type: string().nullable(),
  heading: string().nullable(),
  test_variant: string().nullable(),
  calls_to_action: object().nullable(),
  body: string().nullable(),
  campaign_id: string().nullable(),
  image: object().nullable(),
  section_heading: string().nullable(),
  __digest: string().required(),
});

export const transformCampaignBannerData = (data) => {
  // console.log("transformCampaignBanner", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      type: null,
      heading: null,
      test_variant: null,
      calls_to_action: null,
      campaign_id: null,
      image: null,
      section_heading: null,
      body: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.test_variant = data.test_variant;
    hydratedModel.heading = data.heading;
    hydratedModel.test_variant = data.test_variant;
    hydratedModel.calls_to_action = data.calls_to_action;
    hydratedModel.image = data.image;
    hydratedModel.body = data.body;
    hydratedModel.campaign_id = data.campaign_id;
    hydratedModel.section_heading = data.section_heading;
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      campaignBannerSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
