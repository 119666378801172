const data = [
  {
    value: "Shop",
    href: "/shop",
    items: [],
  },
  {
    value: "Deals",
    href: "/deals",
    items: [],
  },
  {
    value: "Dispensaries",
    href: "/locations",
    items: [],
  },
  {
    value: "Products",
    href: "/shop",
    items: [
      {
        value: "Flower",
        href: "flower",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blta836bbd3037a42a3/63d2d52e9d7bcb542235064f/flower.jpg",
      },
      {
        value: "Extract",
        href: "extract",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt05117c09285a8f4e/63d2d52eb369f5745550ba05/extract.jpg",
      },
      {
        value: "Edible",
        href: "edible",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt637dde6f0b87bdcf/63d2d510c718e411f9b36123/edible.jpg ",
      },
      {
        value: "Tincture",
        href: "tincture",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt08631dbfba720976/63d2d53ee408254c88fbf77e/tincture.jpg",
      },
      {
        value: "Pre-Roll",
        href: "pre-roll",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt6c02e49afa2230c9/63d2d52e5ba46f75ddba0307/pre-roll.jpg",
      },
      {
        value: "Topical",
        href: "topical",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltbb81a6e7980803ad/63d2d53ec507c027d63857f2/topical.jpg",
      },
      {
        value: "Vape",
        href: "vape",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt863db7823a19ebcc/63d2d53e2d94ad4c89edb7c8/vape.jpg",
      },
      {
        value: "Gear",
        href: "gear",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt8b8ce1262c2d3e39/63d2d52effb41a7454dbe5ff/gear.jpg",
      },
      {
        value: "Grow",
        href: "grow",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blted2809448fce7702/63d2d52e5b2c1e6188c55db3/grow.jpg",
      },
      {
        value: "Merch",
        href: "merch",
        thumbnail:
          "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt5caeb1f181f88993/63d2d52e5c5c9c52a32ec4df/merch.jpg",
      },
    ],
  },
];

export default data;
