import actions from "./actions.js";

export const uiInitialState = {
  spinnerActive: false,
  passwordResetIsTransitioning: false,
  passwordResetIsFirstPart: true, // oh man
  controlSent: false,
};

export const uiReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case actions.SET_SPINNER_ACTIVE:
      return {
        ...state,
        spinnerActive: action.payload,
      };

    case actions.SET_PASSWORD_RESET_IS_TRANSITIONING:
      return {
        ...state,
        passwordResetIsTransitioning: action.payload,
      };

    case actions.SET_PASSWORD_RESET_IS_FIRST_PART:
      return {
        ...state,
        passwordResetIsFirstPart: action.payload,
      };

    case actions.SET_CONTROL_SENT:
      return {
        ...state,
        controlSent: action.payload,
      };

    default:
      return state;
  }
};
