module.exports = `fragment super_split_screen on Contentstack_super_split_screen {
    super_split_screen_variants {
      super_split_screen_tgs {
        super_split_screen_split_screen_tgs_styles {
          super_split_screen_split_screen_tgs_styles_image_alignment
          super_split_screen_split_screen_tgs_styles_color_options
        }
        super_split_screen_split_screen_tgs_heading
        super_split_screen_split_screen_tgs_enabled
        super_split_screen_split_screen_tgs_blocks {
          super_split_screen_split_screen_tgs_block_heading
          super_split_screen_split_screen_tgs_block_text
          super_split_screen_split_screen_tgs_block_disclaimer
          super_split_screen_split_screen_tgs_block_image {
            url
            title
            # description
            filename
          }
          super_split_screen_split_screen_tgs_block_cta {
            href
            title
          }
        }
      }
      super_split_screen_standard {
        super_split_screen_split_screen_standard_heading
        super_split_screen_split_screen_standard_enabled
        super_split_screen_split_screen_standard_styles {
          super_split_screen_split_screen_standard_image_alignment
          super_split_screen_split_screen_standard_color_options
        }
        super_split_screen_split_screen_standard_blocks {
          super_split_screen_split_screen_standard_text
          super_split_screen_split_screen_standard_row_heading
          super_split_screen_split_screen_standard_image {
            url
            title
            filename
            # description
          }
          super_split_screen_split_screen_standard_cta {
            href
            title
          }
        }
      }
      super_split_screen_links {
        super_split_screen_split_screen_links_enabled
        super_split_screen_split_screen_links_heading
        super_split_screen_split_screen_links_styles {
          super_split_screen_split_screen_links_color_options
          super_split_screen_split_screen_links_alignment
        }
        super_split_screen_split_screen_links_blocks {
          super_split_screen_split_screen_links_row_heading
          super_split_screen_split_screen_text
          super_split_screen_split_screen_links_links {
            href
            title
          }
          super_split_screen_split_screen_links_image {
            url
            title
            filename
            # description
          }
        }
      }
      super_split_screen_icons {
        super_split_screen_split_screen_icons_enabled
        super_split_screen_split_screen_icons_heading
        super_split_screen_split_screen_icons_styles {
          color_options
          super_split_screen_split_screen_icons_image_alignment
        }
        super_split_screen_split_screen_icons_blocks {
          super_split_screen_split_screen_icons_text
          super_split_screen_split_screen_icons_row_heading
          super_split_screen_split_screen_icons_image {
            url
            title
            # description
            filename
          }
          super_split_screen_split_screen_icons_icon {
            url
            title
            filename
            # description
          }
          super_split_screen_split_screen_icons_cta {
            href
            title
          }
        }
      }
      super_split_screen_contained {
        super_split_screen_split_screen_contained_contained_heading
        super_split_screen_split_screen_contained_enabled
        super_split_screen_split_screen_contained_blocks {
          super_split_screen_split_screen_contained_text
          super_split_screen_split_screen_contained_sub_heading
          super_split_screen_split_screen_contained_heading
          super_split_screen_split_screen_contained_cta {
            title
            href
          }
          super_split_screen_split_screen_contained_image {
            url
            title
            filename
            # description
          }
          super_split_screen_split_screen_contained_links {
            href
            title
          }
          super_split_screen_split_screen_contained_logo {
            url
            title
            # description
            filename
          }
        }
        super_split_screen_split_screen_contained_styles {
          super_split_screen_split_screen_contained_image_alignment
        }
      }
      super_split_screen_compact {
        super_split_screen_compact_enabled
        super_split_screen_compact_styles {
          super_split_screen_image_alignment
        }
        super_split_screen_split_screen_compact_blocks {
          super_split_screen_compact_text
          super_split_screen_compact_image {
            title
            url
            filename
            # description
          }
        }
      }
    }
    variant
  }`;